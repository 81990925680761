'use client'

// This can't be a dynamic import because of refs.
import { useSignals } from '@preact/signals-react/runtime'
import { Logo } from '@susu/headless-commerce/components/Logo/Logo'
import { useCountry } from '@susu/headless-commerce/contexts/country/client'
import { useHeader } from '@susu/headless-commerce/contexts/header/client'
import { useLocale } from '@susu/headless-commerce/contexts/locale/client'
import { usePageType } from '@susu/headless-commerce/hooks/usePageType'
import type { HeaderLogoClickProperties } from '@susu/headless-commerce/libs/avo/avo'
import { URLData } from '@susu/headless-commerce/utils/tracking/segment'
import { trackEvent } from '@susu/headless-commerce/utils/tracking/tracking'
import { useCallback } from 'react'

import styles from '../Header.module.scss'

export default function ClientLogoButton() {
  useSignals()
  const pageType = usePageType()
  const country = useCountry()
  const locale = useLocale()
  const { headerTrackingValue, variation, segmentEventLabel } = useHeader()

  const handleClick = useCallback(async () => {
    const { url, locationId } = URLData()

    await trackEvent({
      ga: {
        eventCategory: 'Global_Interactions',
        eventAction: 'Logo_Header_Click',
        eventLabel: headerTrackingValue,
      },
      segment: {
        event: 'headerLogoClick',
        enrichProps: {
          locale,
          country,
        },
        properties: {
          pageType: pageType as HeaderLogoClickProperties['pageType'],
          eventLabel: segmentEventLabel,
          eventCategory: 'global_interactions',
          eventLocation: 'header',
          url,
          locationId,
        },
      },
    })
  }, [country, headerTrackingValue, locale, pageType, segmentEventLabel])

  return (
    <a
      href={`/${locale}`}
      className={styles.header__logo}
      onClick={handleClick}
      data-testid="link_header_logo"
    >
      <Logo variation={variation} />
    </a>
  )
}
