// Generated by Avo VERSION 162.9.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// fetch() polyfill
(function () {
  if (typeof window === 'undefined') {
    return;
  }
  var support = {
    searchParams: 'URLSearchParams' in self,
    iterable: 'Symbol' in self && 'iterator' in Symbol,
    blob:
      'FileReader' in self &&
      'Blob' in self &&
      (function () {
        try {
          new Blob();
          return true
        } catch (e) {
          return false
        }
      })(),
    formData: 'FormData' in self,
    arrayBuffer: 'ArrayBuffer' in self
  };

  function isDataView(obj: any) {
    return obj && DataView.prototype.isPrototypeOf(obj)
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]'
    ];

    var isArrayBufferView =
      ArrayBuffer.isView ||
      function (obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1
      };
  }

  function normalizeName(name: any) {
    if (typeof name !== 'string') {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
      throw new TypeError('Invalid character in header field name')
    }
    return name.toLowerCase()
  }

  function normalizeValue(value: any) {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items: any) {
    var iterator: any = {
      next: function () {
        var value = items.shift();
        return { done: value === undefined, value: value }
      }
    };

    if (support.iterable) {
      iterator[Symbol.iterator] = function () {
        return iterator
      };
    }

    return iterator
  }

  function Headers(headers: any) {
    // @ts-ignore
    (this as any).map = {};

    if (headers instanceof Headers) {
      (headers as any).forEach(function (value: any, name: any) {
        // @ts-ignore
        this.append(name, value);
        // @ts-ignore
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function (header: any) {
        // @ts-ignore
        this.append(header[0], header[1]);
        // @ts-ignore
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function (name: any) {
        // @ts-ignore
        this.append(name, headers[name]);
        // @ts-ignore
      }, this);
    }
  }

  Headers.prototype.append = function (name: any, value: any) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ', ' + value : value;
  };

  Headers.prototype['delete'] = function (name: any) {
    delete this.map[normalizeName(name)];
  };

  Headers.prototype.get = function (name: any) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null
  };

  Headers.prototype.has = function (name: any) {
    return this.map.hasOwnProperty(normalizeName(name))
  };

  Headers.prototype.set = function (name: any, value: any) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };

  Headers.prototype.forEach = function (callback: any, thisArg: any) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };

  Headers.prototype.keys = function () {
    var items: any = [];
    this.forEach(function (_value: any, name: any) {
      items.push(name);
    });
    return iteratorFor(items)
  };

  Headers.prototype.values = function () {
    var items: any = [];
    this.forEach(function (value: any) {
      items.push(value);
    });
    return iteratorFor(items)
  };

  Headers.prototype.entries = function () {
    var items: any = [];
    this.forEach(function (value: any, name: any) {
      items.push([name, value]);
    });
    return iteratorFor(items)
  };

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }

  function consumed(body: any) {
    if (body.bodyUsed) {
      return true;
    }
    body.bodyUsed = true;
    return false;
  }

  function fileReaderReady(reader: any) {
    return new Promise(function (resolve: any, reject: any) {
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function () {
        reject(reader.error);
      };
    })
  }

  function readBlobAsArrayBuffer(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise
  }

  function readBlobAsText(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise
  }

  function readArrayBufferAsText(buf: any) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]!);
    }
    return chars.join('')
  }

  function bufferClone(buf: any) {
    if (buf.slice) {
      return buf.slice(0)
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer
    }
  }

  function Body() {
    // @ts-ignore
    (this as any).bodyUsed = false;

    // @ts-ignore
    (this as any)._initBody = function (body: any) {
      this._bodyInit = body;
      if (!body) {
        this._bodyText = '';
      } else if (typeof body === 'string') {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8');
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
      }
    };

    if (support.blob) {
      // @ts-ignore
      (this as any).blob = function () {
        var rejected = consumed(this);
        if (rejected) {
          return Promise.reject(new TypeError('Already read'));
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob);
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]));
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob');
        } else {
          return Promise.resolve(new Blob([this._bodyText]));
        }
      };

      // @ts-ignore
      (this as any).arrayBuffer = function () {
        if (this._bodyArrayBuffer) {
          if (consumed(this)) {
            return Promise.reject(new TypeError('Already read'));
          } else {
            return Promise.resolve(this._bodyArrayBuffer);
          }
        } else {
          return this.blob().then(readBlobAsArrayBuffer);
        }
      };
    }

    // @ts-ignore
    (this as any).text = function () {
      var rejected = consumed(this);
      if (rejected) {
        return Promise.reject(new TypeError('Already read'));
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob);
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer));
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text');
      } else {
        return Promise.resolve(this._bodyText);
      }
    };

    if (support.formData) {
      // @ts-ignore
      (this as any).formData = function () {
        return this.text().then(decode)
      };
    }

    // @ts-ignore
    (this as any).json = function () {
      return this.text().then(JSON.parse)
    };

    // @ts-ignore
    return this
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

  function normalizeMethod(method: any) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method
  }

  function Request(input: any, options: any) {
    options = options || {};
    var body = options.body;

    if (input instanceof Request) {
      if ((input as any).bodyUsed) {
        throw new TypeError('Already read')
      }
      // @ts-ignore
      (this as any).url = (input as any).url;
      // @ts-ignore
      this.credentials = (input as any).credentials;
      if (!options.headers) {
        // @ts-ignore
        this.headers = new Headers((input as any).headers);
      }
      // @ts-ignore
      this.method = (input as any).method;
      // @ts-ignore
      this.mode = (input as any).mode;
      // @ts-ignore
      this.signal = (input as any).signal;
      if (!body && (input as any)._bodyInit != null) {
        body = (input as any)._bodyInit;
        (input as any).bodyUsed = true;
      }
    } else {
      // @ts-ignore
      this.url = String(input);
    }

    // @ts-ignore
    this.credentials = options.credentials || this.credentials || 'same-origin';
    // @ts-ignore
    if (options.headers || !this.headers) {
      // @ts-ignore
      this.headers = new Headers(options.headers);
    }
    // @ts-ignore
    this.method = normalizeMethod(options.method || this.method || 'GET');
    // @ts-ignore
    this.mode = options.mode || this.mode || null;
    // @ts-ignore
    this.signal = options.signal || this.signal;
    // @ts-ignore
    this.referrer = null;

    // @ts-ignore
    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests')
    }
    // @ts-ignore
    this._initBody(body);
  }

  Request.prototype.clone = function () {
    // @ts-ignore
    return new Request(this, { body: this._bodyInit })
  };

  function decode(body: any) {
    var form = new FormData();
    body
      .trim()
      .split('&')
      .forEach(function (bytes: any) {
        if (bytes) {
          var split = bytes.split('=');
          var name = split.shift().replace(/\+/g, ' ');
          var value = split.join('=').replace(/\+/g, ' ');
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
    return form
  }

  function parseHeaders(rawHeaders: any) {
    // @ts-ignore
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(function (line: any) {
      var parts = line.split(':');
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers
  }

  Body.call(Request.prototype);

  function Response(bodyInit: any, options: any) {
    if (!options) {
      options = {};
    }

    // @ts-ignore
    this.type = 'default';
    // @ts-ignore
    this.status = options.status === undefined ? 200 : options.status;
    // @ts-ignore
    this.ok = this.status >= 200 && this.status < 300;
    // @ts-ignore
    this.statusText = 'statusText' in options ? options.statusText : 'OK';
    // @ts-ignore
    this.headers = new Headers(options.headers);
    // @ts-ignore
    this.url = options.url || '';
    // @ts-ignore
    this._initBody(bodyInit);
  }

  Body.call(Response.prototype);

  Response.prototype.clone = function () {
    // @ts-ignore
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      // @ts-ignore
      headers: new Headers(this.headers),
      url: this.url
    })
  };

  Response.error = function () {
    // @ts-ignore
    var response = new Response(null, { status: 0, statusText: '' });
    response.type = 'error';
    return response
  };

  var redirectStatuses = [301, 302, 303, 307, 308];

  Response.redirect = function (url: any, status: any) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code')
    }

    // @ts-ignore
    return new Response(null, { status: status, headers: { location: url } })
  };

  (self as any).DOMException = (self as any).DOMException;
  try {
    new (self as any).DOMException();
  } catch (err) {
    (self as any).DOMException = function (message: any, name: any) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    (self as any).DOMException.prototype = Object.create(Error.prototype);
    (self as any).DOMException.prototype.constructor = (self as any).DOMException;
  }

  function fetch(input: any, init: any) {
    return new Promise(function (resolve, reject) {
      // @ts-ignore
      var request = new Request(input, init);

      if (request.signal && request.signal.aborted) {
        return reject(new (self as any).DOMException('Aborted', 'AbortError'))
      }

      var xhr = new XMLHttpRequest();

      function abortXhr() {
        xhr.abort();
      }

      xhr.onload = function () {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || '')
        };
        (options as any).url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
        var body = 'response' in xhr ? xhr.response : (xhr as any).responseText;
        // @ts-ignore
        resolve(new Response(body, options));
      };

      xhr.onerror = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.ontimeout = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.onabort = function () {
        reject(new (self as any).DOMException('Aborted', 'AbortError'));
      };

      xhr.open(request.method, request.url, true);

      if (request.credentials === 'include') {
        xhr.withCredentials = true;
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false;
      }

      if ('responseType' in xhr && support.blob) {
        xhr.responseType = 'blob';
      }

      request.headers.forEach(function (value: any, name: any) {
        xhr.setRequestHeader(name, value);
      });

      if (request.signal) {
        request.signal.addEventListener('abort', abortXhr);

        xhr.onreadystatechange = function () {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener('abort', abortXhr);
          }
        };
      }

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
    })
  }

  fetch.polyfill = true;

  if (!self.fetch) {
    (self as any).fetch = fetch;
    (self as any).Headers = Headers;
    (self as any).Request = Request;
    (self as any).Response = Response;
  }
})();

export enum AvoEnv {
  Prod = "prod",
  Staging = "staging",
  Dev = "dev",
}

export interface CustomDestination {
  make?(env: AvoEnv, apiKey: string): void;
  logEvent?: (eventName: string, eventProperties: Record<string, any>) => void;
  setUserProperties?: (userId: string, userProperties: Record<string, any>) => void;
  identify?: (userId: string) => void;
  unidentify?: () => void;
  logPage?: (pageName: string, eventProperties: Record<string, any>) => void;
  revenue?: (amount: number, eventProperties: Record<string, any>) => void;
  setGroupProperties?: (
    groupType: string,
    groupId: string,
    groupProperties: Record<string, any>,
  ) => void;
  addCurrentUserToGroup?: (
    groupType: string,
    groupId: string,
    groupProperties: Record<string, any>,
  ) => void;
  logEventWithGroups?: (
    eventName: string,
    eventProperties: any,
    groupTypesToGroupIds: Record<string, string>,
  ) => void;
}

// @ts-ignore
interface AvoAssertMessage {
  eventName?: string;
  tag?: string;
  propertyId?: string;
  message?: string;
  additionalProperties?: string[],
  shape?: any,
  shapeUserProps?: any,
  actualType?: string
}

let __AVO_ENV__: AvoEnv | null = null;
// @ts-ignore
let __AVO_NOOP__: boolean = false;
// @ts-ignore
let __AVO_LOGGER__: AvoLogger | null = null;
// @ts-ignore
let __STRICT__: boolean | null = null;
// @ts-ignore
let __REPORT_FAILURE_AS__: 'error' | 'warn' | 'log' | null = null;

// @ts-ignore
let __WEB_DEBUGGER__: boolean = true;
export const avoInspectorApiKey = "08x2H3eWZd8gAG8XGLnE";
// @ts-ignore
interface AvoInspector {}
let __INSPECTOR__: AvoInspector | null = null;


// polyfill Object.assign
// @ts-ignore
declare interface ObjectConstructor {
  assign: any;
}
// @ts-ignore
if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target: any, _varArgs: any) { // .length of function is 2
      if (target == null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      let to = Object(target);

      for (let index = 1; index < arguments.length; index++) {
        let nextSource = arguments[index];

        if (nextSource != null) { // Skip over if undefined or null
          for (let nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}

interface AvoLogger {
  logDebug(env: AvoEnv | null, message: string): boolean;
  logWarn(env: AvoEnv | null, message: string): boolean;
  logError(env: AvoEnv | null, error: string): boolean;
}

enum webDebuggerArea {
  BottomRight = "BottomRight",
  BottomLeft = "BottomLeft",
  TopRight = "TopRight",
  TopLeft = "TopLeft"
}

interface bottomRightParameters {
  bottom: number;
  right: number;
}

interface bottomLeftParameters {
  bottom: number;
  left: number;
}

interface topRightParameters {
  top: number;
  right: number;
}

interface topLeftParameters {
  top: number;
  left: number;
}

interface webDebuggerPosition {
  position: webDebuggerArea;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

interface webDebuggerOptions {
  position?: webDebuggerPosition;
}

interface WebDebuggerPositionSetter {
  BottomRight(p: bottomRightParameters): webDebuggerPosition;
  BottomLeft(p: bottomLeftParameters): webDebuggerPosition;
  TopRight(p: topRightParameters): webDebuggerPosition;
  TopLeft(p: topLeftParameters): webDebuggerPosition;
}

export const WebDebuggerPosition: WebDebuggerPositionSetter = {
  BottomRight: ({ bottom, right }) => ({
    position: webDebuggerArea.BottomRight,
    bottom,
    right,
  }),
  BottomLeft: ({ bottom, left }) => ({
    position: webDebuggerArea.BottomLeft,
    bottom,
    left,
  }),
  TopRight: ({ top, right }) => ({
    position: webDebuggerArea.TopRight,
    top,
    right,
  }),
  TopLeft: ({ top, left }) => ({
    position: webDebuggerArea.TopLeft,
    top,
    left,
  }),
}


let InternalAvoLogger: any = {
  logEventSent: function logEventSent(eventName: string, eventProperties: any, userProperties: any) {
    const message = "Event Sent:" + eventName + "Event Props:" + JSON.stringify(eventProperties) + "User Props:" + JSON.stringify(userProperties);

    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties);
  },

  log: function log(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] " + message);
  },

  warn: function warn(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.warn("[avo] " + message);
  },

  error: function error(message: string, error: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
      return
    }
    typeof console !== 'undefined' && console.error("[avo] " + message, error);
  }
};

function convertPropertiesArrayToMap(propertiesArray: [{id: string, name: string, value: string}]): {string: string | null | undefined} {
    let result: {string: string} = {}

    propertiesArray.forEach(value => {
      result[value.name] = value.value
    })

    return result
}

// @ts-ignore
let array_difference: any;
// @ts-ignore
let AvoAssert: any;
array_difference = function array_difference(a1: any[], a2: any[]) {
  let result: any[] = [];
  for (let i = 0; i < a1.length; i++) {
    if (a2.indexOf(a1[i]) === -1) {
      result.push(a1[i]);
    }
  }
  return result;
}

AvoAssert = {
  assertObject: function assertObject(propertyId: string, propName: string, obj: any) {
    if (typeof obj !== 'object') {
      let message = propName +
          ' should be of type object but you provided type ' +
          typeof obj +
          ' with value ' +
          JSON.stringify(obj);
      return [{tag: 'expectedObjectType', propertyId, message, actualType: typeof obj}];
    } else {
      return [];
    }
  },

  assertString: function assertString(propertyId: string, propName: string, str: string) {
    if (typeof str !== 'string') {
      let message = propName +
          ' should be of type string but you provided type ' +
          typeof str +
          ' with value ' +
          JSON.stringify(str);
      return [{tag: 'expectedStringType', propertyId, message, actualType: typeof str}];
    } else {
      return [];
    }
  },

  assertInt: function assertInt(propertyId: string, propName: string, int: number) {
    if (typeof int === 'number' && int !== Math.round(int)) {
      let message = propName +
          ' should be of type int but you provided type float with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: 'float'}];
    } else if (typeof int !== 'number') {
      let message = propName +
          ' should be of type int but you provided type ' +
          typeof int +
          ' with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: typeof int}];
    } else {
      return [];
    }
  },

  assertLong: function assertLong(propertyId: string, propName: string, long: number) {
    if (typeof long === 'number' && long !== Math.round(long)) {
      let message = propName +
          ' should be of type long but you provided type float with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: 'float'}];
    } else if (typeof long !== 'number') {
      let message = propName +
          ' should be of type long but you provided type ' +
          typeof long +
          ' with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: typeof long}];
    } else {
      return [];
    }
  },

  assertFloat: function assertFloat(propertyId: string, propName: string, float: number) {
    if (typeof float !== 'number') {
      let message = propName +
          ' should be of type float but you provided type ' +
          typeof float +
          ' with value ' +
          JSON.stringify(float);
      return [{tag: 'expectedFloatType', propertyId, message, actualType: typeof float}];
    } else {
      return [];
    }
  },

  assertBool: function assertBool(propertyId: string, propName: string, bool: boolean) {
    if (typeof bool !== 'boolean') {
      let message = propName +
          ' should be of type boolean but you provided type ' +
          typeof bool +
          ' with value ' +
          JSON.stringify(bool);
      return [{tag: 'expectedBoolType', propertyId, message, actualType: typeof bool}];
    } else {
      return [];
    }
  },

  assertMax: function assertMax(
    propertyId: string,
    propName: string,
    max: number,
    value: number
  ) {
    if (value > max) {
      let message = propName +
        ' has a maximum value of ' +
        max +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMax', propertyId, message}];
    } else {
      return [];
    }
  },

  assertMin: function assertMin(
    propertyId: string,
    propName: string,
    min: number,
    value: number
  ) {
    if (value < min) {
      let message = propName +
        ' has a minimum value of ' +
        min +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMin', propertyId, message}];
    } else {
      return [];
    }
  },

  assertList: function assertList(propertyId: string, propName: string, value: any) {
    if (!Array.isArray(value)) {
      let message = propName + ' should be of type list but you provided type ' + typeof value;
      return [{tag: 'expectedList', propertyId, message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalUserProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional user properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },
};

let _avo_invoke: any;
let _avo_invoke_meta: any;
let _avo_sampling_rate = 1.0;
_avo_invoke = function _avo_invoke(env: AvoEnv, eventId: string, hash: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "rmAqwJ9TcQob3QHtujaG",
          "br": "RmqHn8A2W",
          "en": env,
          "ev": eventId,
          "ha": hash,
          "sc": "JmwsgKTQe50MTmUE9FVs",
          "se": (new Date()).toISOString(),
          "so": "YVbONYzfD",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}

_avo_invoke_meta = function _avo_invoke_meta(env: AvoEnv, type: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "rmAqwJ9TcQob3QHtujaG",
          "br": "RmqHn8A2W",
          "en": env,
          "ty": type,
          "sc": "JmwsgKTQe50MTmUE9FVs",
          "se": (new Date()).toISOString(),
          "so": "YVbONYzfD",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}


let _avo_debugger_log: any;
let _avo_debugger_events_during_boot: any = [];
let _avo_debugger_ready = false;

if (typeof (window as any) !== 'undefined') {
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    let iframe: any = document.getElementById("avo-debugger");
    if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
      let message = {
        type_: "avo-debugger-boot-events",
        schemaId: "JmwsgKTQe50MTmUE9FVs",
        href: window.location.href,
        events: _avo_debugger_events_during_boot
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  });
}

_avo_debugger_log = function _avo_debugger_log(eventId: string, eventName: string, messages: any[], eventProperties: any[], userProperties: any[], groupProperties: any[]) {
  if (typeof (window as any) === 'undefined') { return; }
  let event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties,
    userProperties,
    groupProperties
  };

  if (_avo_debugger_ready) {
    let message = {type_: "avo-debugger-events", events: [event]};
    (document.getElementById("avo-debugger") as any).contentWindow.postMessage(message, "https://www.avo.app/_debugger")
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
}

function _avo_debugger_send_position(position: webDebuggerPosition) {
  if (typeof window === 'undefined') { return; }
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    if (event.data.type_ === "avo-debugger-ready" && position !== null) {
      var message = {type_: "avo-debugger-position", position: position};
      document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  })
}


export interface ProductsCart {
  amount: number
  identifier: string
  variation: string
  currency: CurrencyValueType
  quantity: number
  marketingcloudpid: string
  variantId: string
}

export interface Products {
  productId: string
  category: CategoryValueType
  itemCategory2: string
  itemCategory3: string
  itemCategory4: string
  itemCategory5: string
  affiliation: AffiliationValueType
  itemListName: ItemListNameValueType
  variant: VariantValueType | null | undefined
  price: number
  quantity: number
  currency: CurrencyValueType
  position: number
  imageUrl: string
  onlineMaterial: string
  fitId: string
  plpGridImages: PlpGridImagesValueType
  shopTheLookId: string
  mixAndMatch: MixAndMatchValueType
  productSetId: string
  name: string
  listId: ListIdValueType
  sku: string
  fullLook: string
  value: number
  locationId: string
  url: string
}

export const MixAndMatch = {
  'TRUE': 'true',
  'FALSE': 'false',
} as const;
export type MixAndMatchType = typeof MixAndMatch;
export type MixAndMatchValueType = MixAndMatchType[keyof MixAndMatchType];

export const ItemListName = {
  'CATEGORY_PAGE': 'category page',
  'CATEGORY_PAGE_FILTERED': 'category page filtered',
  'SHOP_THE_LOOK': 'shop the look',
  'WISHLIST': 'wishlist',
  'YOU_MIGHT_ALSO_LIKE': 'you might also like',
  'COLOUR_SWATCH': 'colour swatch',
  'BLACK_TIE_PACKAGE': 'black tie package',
  'VIEW_SIMILAR_ITEMS': 'view similar items',
  'SEARCH': 'search',
  'CUSTOM_MADE': 'custom made',
  'GIFTCARD_LANDING': 'giftcard landing',
  'GIFT_WRAPPING': 'gift wrapping',
  'EXPLORE_THE_COLLECTION': 'explore the collection',
  'DISCOVER_THE_NEW_COLLECTION': 'discover the new collection',
  'MOST_POPULAR': 'most popular',
  'CATEGORY_PAGE_LANDING': 'category page landing',
  '': ' ',
  'COLOR_SWATCH': 'color swatch',
} as const;
export type ItemListNameType = typeof ItemListName;
export type ItemListNameValueType = ItemListNameType[keyof ItemListNameType];

export const Language = {
  'EN': 'en',
  'ES': 'es',
  'FR': 'fr',
  'DE': 'de',
  'IT': 'it',
  'SV': 'sv',
  'PL': 'pl',
  'ZH': 'zh',
} as const;
export type LanguageType = typeof Language;
export type LanguageValueType = LanguageType[keyof LanguageType];

export const PlpGridImages = {
  '2_PRODUCTS_DEFAULT': '2_products_default',
  '1_PRODUCT_WITH_2_IMAGES_DEFAULT': '1_product_with_2_images_default',
  '1_PRODUCT_WITH_3_IMAGES_DEFAULT': '1_product_with_3_images_default',
  '1_PRODUCT_WITH_1_IMAGE_DEFAULT': '1_product_with_1_image_default',
  '3_PRODUCTS_GRID': '3_products_grid',
  '4_PRODUCTS_GRID': '4_products_grid',
  '1_PRODUCT_DEFAULT': '1_product_default',
  '2_PRODUCTS_GRID': '2_products_grid',
  '': ' ',
} as const;
export type PlpGridImagesType = typeof PlpGridImages;
export type PlpGridImagesValueType = PlpGridImagesType[keyof PlpGridImagesType];

export const Category = {
  'JACKETS': 'jackets',
  'SUITS': 'suits',
  'TROUSERS': 'trousers',
  'KNITWEAR': 'knitwear',
  'COATS': 'coats',
  'SHIRTS': 'shirts',
  'SHOES': 'shoes',
  'GIFT_CARD': 'gift card',
  'BELTS': 'belts',
  'WAISTCOATS': 'waistcoats',
  'TIESSUB': 'tiessub',
  'HANDKERCHIEFS': 'handkerchiefs',
  'TIES': 'ties',
  'SUSPENDERS': 'suspenders',
  'CUFFLINKS': 'cufflinks',
  'SHORTS': 'shorts',
  'SOCKS': 'socks',
  'GLOVES': 'gloves',
  'SCARVES': 'scarves',
  'SHOECARE': 'shoecare',
  'HATS': 'hats',
  'GIFT_WRAPPING': 'gift-wrapping',
} as const;
export type CategoryType = typeof Category;
export type CategoryValueType = CategoryType[keyof CategoryType];

export const LoggedIn = {
  'TRUE': 'true',
  'FALSE': 'false',
} as const;
export type LoggedInType = typeof LoggedIn;
export type LoggedInValueType = LoggedInType[keyof LoggedInType];

export const EventCategory = {
  'CHECKOUT_INTERACTIONS': 'checkout_interactions',
  'PLP_INTERACTIONS': 'plp_interactions',
  'PDP_INTERACTIONS': 'pdp_interactions',
  'GLOBAL_INTERACTIONS': 'global_interactions',
  'FOOTER_INTERACTIONS': 'footer_interactions',
  'JOURNAL_INTERACTIONS': 'journal_interactions',
  'CM_INTERACTIONS': 'cm_interactions',
  'LOOKBUILDER_INTERACTIONS': 'lookbuilder_interactions',
  'INSTORE_INTERACTIONS': 'instore_interactions',
  'ECOMMERCE_INTERACTIONS': 'ecommerce_interactions',
  'BTC_INTERACTIONS': 'btc_interactions',
  'BOOKING_INTERACTIONS': 'booking_interactions',
  'LOGIN_INTERACTIONS': 'login_interactions',
  'CONFIGURATOR_INTERACTIONS': 'configurator_interactions',
} as const;
export type EventCategoryType = typeof EventCategory;
export type EventCategoryValueType = EventCategoryType[keyof EventCategoryType];

export const ListId = {
  'CATEGORY_PAGE': 'category_page',
  'CATEGORY_PAGE_FILTERED': 'category_page_filtered',
  'SHOP_THE_LOOK_MINICART': 'shop-the-look_minicart',
  'SHOP_THE_LOOK_WISHLIST': 'shop-the-look_wishlist',
  'SHOP_THE_LOOK_SLIDER': 'shop-the-look_slider',
  'SHOP_THE_LOOK_SLIDER_1': 'shop-the-look_slider_1',
  'SHOP_THE_LOOK_SLIDER_2': 'shop-the-look_slider_2',
  'SHOP_THE_LOOK_SLIDER_3': 'shop-the-look_slider_3',
  'SHOP_THE_LOOK_SLIDER_4': 'shop-the-look_slider_4',
  'SHOP_THE_LOOK_SLIDER_5': 'shop-the-look_slider_5',
  'SHOP_THE_LOOK_SLIDER_6': 'shop-the-look_slider_6',
  'SHOP_THE_LOOK_SLIDER_7': 'shop-the-look_slider_7',
  'WISHLIST': 'wishlist',
  'YOU_MIGHT_ALSO_LIKE_PDP': 'you-might-also-like_pdp',
  'COLOUR_SWATCH_PDP': 'colour-swatch_pdp',
  'BLACK_TIE_PACKAGE_JOURNAL': 'black-tie-package_journal',
  'VIEW_SIMILAR_ITEMS_OOS': 'view-similar-items_oos',
  'SEARCH': 'search',
  'SHOP_THE_LOOK_LOOKBUILDER_SUMMARY_SCREEN': 'shop-the-look_lookbuilder_summary-screen',
  'CUSTOM_MADE': 'custom-made',
  'GIFTCARD_LANDING': 'giftcard_landing',
  'GIFT_WRAPPING_MINI_CART': 'gift-wrapping_mini-cart',
  'DISCOVER_THE_NEW_COLLECTION_CSR_LANDING': 'discover-the-new-collection_csr-landing',
  'MOST_POPULAR_STORE_DETAIL': 'most-popular_store-detail',
  'MOST_POPULAR_STORE_LOCATOR': 'most-popular_store-locator',
  'DISCOVER_THE_NEW_COLLECTION_NEWSLETTER_EMAILPREFERENCES': 'discover-the-new-collection_newsletter-emailpreferences',
  '': ' ',
  'SHOP_THE_LOOK_CUSTOMMADE': 'shop-the-look_custommade',
  'GIFT_WRAPPING_CART': 'gift-wrapping_cart',
  'BLACK_TIE_PACKAGE_CONFIGURATOR': 'black-tie-package_configurator',
  'CATEGORY_PAGE_LANDING_CLASSIC_SUITS': 'category-page-landing_classic-suits',
  'CATEGORY_PAGE_LANDING_JACKETS_TUXEDO': 'category-page-landing_jackets-tuxedo',
  'CATEGORY_PAGE_LANDING_PERENNIAL_SUITS': 'category-page-landing_perennial-suits',
  'CATEGORY_PAGE_LANDING_SHIRTS_TUXEDO': 'category-page-landing_shirts-tuxedo',
  'CATEGORY_PAGE_LANDING_SUITS_TUXEDO	': 'category-page-landing_suits-tuxedo	',
  'CATEGORY_PAGE_LANDING_TRAVELLER_SUITS': 'category-page-landing_traveller-suits',
  'CATEGORY_PAGE_LANDING_TUXEDO_SUITS': 'category-page-landing_tuxedo-suits',
  'CATEGORY_PAGE_LANDING_WEDDING_SUITS': 'category-page-landing_wedding-suits',
  'COLOR_SWATCH_PDP': 'color-swatch_pdp',
  'DISCOVER_OUR_COLLECTION_JOURNAL_ALTER_YOUR_FIT': 'discover-our-collection_journal_alter-your-fit',
  'DISCOVER_OUR_COLLECTION_JOURNAL_CUSTOM_MADE': 'discover-our-collection_journal_custom-made',
  'DISCOVER_OUR_COLLECTION_JOURNAL_THE_HALLMARKS_OF_A_QUALITY_SHIRT': 'discover-our-collection_journal_the-hallmarks-of-a-quality-shirt',
  'DISCOVER_OUR_COLLECTION_JOURNAL_THE_HALLMARKS_OF_A_QUALITY_SHOE': 'discover-our-collection_journal_the-hallmarks-of-a-quality-shoe',
  'E_THOMAS_COLLECTION_JOURNAL_E_THOMAS_MILL': 'e.thomas-collection_journal_e-thomas-mill',
  'EXPLORE_THE_COLLECTION_JOURNAL_4_PLY_TRAVELLER_COLLECTION': 'explore-the-collection_journal_4-ply-traveller-collection',
  'EXPLORE_THE_COLLECTION_JOURNAL_ANGELICO_MILL': 'explore-the-collection_journal_angelico-mill',
  'EXPLORE_THE_COLLECTION_JOURNAL_CANDIANI_MILL': 'explore-the-collection_journal_candiani-mill',
  'EXPLORE_THE_COLLECTION_JOURNAL_DELFINO_MILL': 'explore-the-collection_journal_delfino-mill',
  'EXPLORE_THE_COLLECTION_JOURNAL_DRAGO_MILL': 'explore-the-collection_journal_drago-mill',
  'EXPLORE_THE_COLLECTION_JOURNAL_FOSSATI_MILL': 'explore-the-collection_journal_fossati-mill',
  'EXPLORE_THE_COLLECTION_JOURNAL_HOW_TO_TAKE_CARE_OF_YOUR_KNITS': 'explore-the-collection_journal_how-to-take-care-of-your-knits',
  'EXPLORE_THE_COLLECTION_JOURNAL_HOW_TO_TAKE_CARE_OF_YOUR_SUITS': 'explore-the-collection_journal_how-to-take-care-of-your-suits',
  'EXPLORE_THE_COLLECTION_JOURNAL_PERENNIAL_SUITS': 'explore-the-collection_journal_perennial-suits',
  'EXPLORE_THE_COLLECTION_JOURNAL_REDA_MILL': 'explore-the-collection_journal_reda-mill',
  'EXPLORE_THE_COLLECTION_JOURNAL_REGGIANI_MILL': 'explore-the-collection_journal_reggiani-mill',
  'EXPLORE_THE_COLLECTION_JOURNAL_RELAXED_TROUSERS_GUIDE	': 'explore-the-collection_journal_relaxed-trousers-guide	',
  'EXPLORE_THE_COLLECTION_JOURNAL_WEBA_MILL': 'explore-the-collection_journal_weba-mill',
  'SHOP_FRENCH_LINEN_JOURNAL_EXPEDITION_JORT_THE_TAILORING_TRAIL': 'shop-french-linen_journal_expedition-jort-the-tailoring-trail',
  'SHOP_INDONESIAN_MOTHER_OF_PEARL_JOURNAL_EXPEDITION_JORT_THE_TAILORING_TRAIL': 'shop-indonesian-mother-of-pearl_journal_expedition-jort-the-tailoring-trail',
} as const;
export type ListIdType = typeof ListId;
export type ListIdValueType = ListIdType[keyof ListIdType];

export const Country = {
  'US': 'us',
  'CA': 'ca',
  'NL': 'nl',
  'GB': 'gb',
  'DE': 'de',
  'FR': 'fr',
  'BE': 'be',
  'IT': 'it',
  'SE': 'se',
  'CH': 'ch',
  'PL': 'pl',
  'ES': 'es',
  'FI': 'fi',
  'DK': 'dk',
  'AT': 'at',
  'NO': 'no',
  'CZ': 'cz',
  'HR': 'hr',
  'HU': 'hu',
  'EE': 'ee',
  'LV': 'lv',
  'LT': 'lt',
  'GR': 'gr',
  'IE': 'ie',
  'SG': 'sg',
  'IN': 'in',
  'AU': 'au',
  'CN': 'cn',
  'BN': 'bn',
  'JP': 'jp',
  'HK': 'hk',
  'MO': 'mo',
  'MV': 'mv',
  'MY': 'my',
  'IR': 'ir',
  'LY': 'ly',
  'PT': 'pt',
  'RO': 'ro',
  'CY': 'cy',
  'SK': 'sk',
  'BG': 'bg',
  'SI': 'si',
  'AE': 'ae',
  'GH': 'gh',
  'GI': 'gi',
  'GW': 'gw',
  'IS': 'is',
  'JE': 'je',
  'LI': 'li',
  'MC': 'mc',
  'MT': 'mt',
  'QA': 'qa',
  'DO': 'do',
  'GP': 'gp',
  'MQ': 'mq',
  'PR': 'pr',
  'TT': 'tt',
  'KR': 'kr',
  'LU': 'lu',
} as const;
export type CountryType = typeof Country;
export type CountryValueType = CountryType[keyof CountryType];

export const Option = {
  'CUFFLINK': 'cufflink',
  'LAPEL': 'lapel',
  'JACKET': 'jacket',
  'WAISTCOAT': 'waistcoat',
  'SHIRT': 'shirt',
  'SHOES': 'shoes',
  'SUSPENDERS': 'suspenders',
  'TROUSERS': 'trousers',
  'BOW_TIE': 'bow tie',
} as const;
export type OptionType = typeof Option;
export type OptionValueType = OptionType[keyof OptionType];

export const Affiliation = {
  'WEBSTORE_EUR': 'webstore-eur',
  'WEBSTORE_USD': 'webstore-usd',
  'WEBSTORE_CAD': 'webstore-cad',
  'WEBSTORE_PLN': 'webstore-pln',
  'WEBSTORE_AUD': 'webstore-aud',
  'WEBSTORE_SEK': 'webstore-sek',
  'WEBSTORE_CHF': 'webstore-chf',
  'WEBSTORE_DKK': 'webstore-dkk',
  'WEBSTORE_AED': 'webstore-aed',
  'WEBSTORE_NOK': 'webstore-nok',
  'WEBSTORE_CNY': 'webstore-cny',
  'WEBSTORE_SGD': 'webstore-sgd',
  'WEBSTORE_HKD': 'webstore-hkd',
  'WEBSTORE_GBP': 'webstore-gbp',
} as const;
export type AffiliationType = typeof Affiliation;
export type AffiliationValueType = AffiliationType[keyof AffiliationType];

export const Brand = {
  'SUITSUPPLY': 'suitsupply',
} as const;
export type BrandType = typeof Brand;
export type BrandValueType = BrandType[keyof BrandType];

export const SsMedium = {
  'BT_PANEL': 'bt_panel',
  'CS_PANEL': 'cs_panel',
  '': '^$',
} as const;
export type SsMediumType = typeof SsMedium;
export type SsMediumValueType = SsMediumType[keyof SsMediumType];

export const PageType = {
  'HOME': 'home',
  'PRODUCT': 'product',
  'CATEGORY': 'category',
  'JOURNAL': 'journal',
  'STORE': 'store',
  'LOOKBUILDER': 'lookbuilder',
  'LOGIN': 'login',
  'WISHLIST': 'wishlist',
  'ACCOUNT': 'account',
  'CHECKOUT': 'checkout',
  'CART': 'cart',
  'SUPPORT': 'support',
  'CUSTOMMADE': 'custommade',
  'GIFTCARD': 'giftcard',
  'ERROR': 'error',
  'INSTORE': 'instore',
  'CATEGORY_LANDING': 'category_landing',
  'BLACK_TIE_CONFIGURATOR': 'black-tie-configurator',
} as const;
export type PageTypeType = typeof PageType;
export type PageTypeValueType = PageTypeType[keyof PageTypeType];

export const Currency = {
  'EUR': 'eur',
  'USD': 'usd',
  'CNY': 'cny',
  'AUD': 'aud',
  'BGN': 'bgn',
  'CAD': 'cad',
  'CZK': 'czk',
  'DKK': 'dkk',
  'HKD': 'hkd',
  'HUF': 'huf',
  'ISK': 'isk',
  'INR': 'inr',
  'JPY': 'jpy',
  'CHF': 'chf',
  'MOP': 'mop',
  'MYR': 'myr',
  'NOK': 'nok',
  'QAR': 'qar',
  'RON': 'ron',
  'SGD': 'sgd',
  'SEK': 'sek',
  'AED': 'aed',
  'GBP': 'gbp',
  'PLN': 'pln',
} as const;
export type CurrencyType = typeof Currency;
export type CurrencyValueType = CurrencyType[keyof CurrencyType];

export const Creative = {
  'HOMEPAGE_ADBLOCK': 'homepage_adblock',
  'HOMEPAGE_RELATED_ARTICLE': 'homepage_related-article',
  'HOMEPAGE_USP': 'homepage_usp',
  'HOMEPAGE_TEXTLINK': 'homepage_textlink',
  'HOMEPAGE_SEOBLOCK': 'homepage_seoblock',
  'FOOTER_LINK': 'footer_link',
  'FOOTER_USP': 'footer_usp',
  'PLP_HEADER': 'plp_header',
  'PLP_ADBLOCK': 'plp_adblock',
  'PLP_WIDGET': 'plp_widget',
  'JOURNAL_HUB_RELATED_ARTICLES': 'journal-hub_related-articles',
  'JOURNAL_HUB_ADBLOCK': 'journal-hub_adblock',
  'JOURNAL_HUB_HEADER': 'journal-hub_header',
  'JOURNAL_BUTTONLINK': 'journal_buttonlink',
  'JOURNAL_ADBLOCK': 'journal_adblock',
  'JOURNAL_TEXTLINK': 'journal_textlink',
  'PDP_ACCORDION': 'pdp_accordion',
  'PDP_EMBEDDED_ARTICLE': 'pdp_embedded-article',
  'PDP_USP': 'pdp_usp',
  'PDP_RELATED_ARTICLES': 'pdp_related-articles',
  'PDP_BUTTONLINK': 'pdp_buttonlink',
  'PDP_PANEL': 'pdp_panel',
  'STORE_TEXTLINK': 'store_textlink',
  'STORE_ADBLOCK': 'store_adblock',
  'STORE_FAQLINK': 'store_faqlink',
  'STORE_OVERVIEW_TEXTLINK': 'store-overview_textlink',
  'STORE_SEARCH_TEXTLINK': 'store-search_textlink',
  'STORE_SEARCH_SLIDER_TEXTLINK': 'store-search_slider-textlink',
  'STORE_SEARCH_ADBLOCK': 'store-search_adblock',
  'PDP_PANEL_WISHLIST': 'pdp_panel_wishlist',
  'PLP_PANEL_WISHLIST': 'plp_panel_wishlist',
  'PDP_BUTTONLINK_MWTW': 'pdp_buttonlink_mwtw',
  'PDP_PANEL_MWTW': 'pdp_panel_mwtw',
  'PDP_USP_MWTW': 'pdp_usp_mwtw',
  'PDP_PANEL_SA': 'pdp_panel_sa',
  'FOOTER_PANEL_SA': 'footer_panel_sa',
  'PANEL_MINICART': 'panel_minicart',
  'LOOKBUILDER_SUMMARY_ADBLOCK': 'lookbuilder-summary_adblock',
  'SHOP_THE_LOOK_USP': 'shop-the-look_usp',
  'SHOP_THE_LOOK_PANEL': 'shop-the-look_panel',
  'PDP_ICONLINK_MWTW': 'pdp_iconlink_mwtw',
  'CLP_ADBLOCK': 'clp_adblock',
  'CLP_BUTTONLINK': 'clp_buttonlink',
} as const;
export type CreativeType = typeof Creative;
export type CreativeValueType = CreativeType[keyof CreativeType];

export const Variant = {
  'XXL': 'xxl',
  'XL': 'xl',
  'L': 'l',
  'M': 'm',
  'S': 's',
  'XS': 'xs',
  '98': '98',
  '95': '95',
  '94': '94',
  '90': '90',
  '9_5': '9.5',
  '9': '9',
  '85': '85',
  '8_5': '8.5',
  '8': '8',
  '75': '75',
  '7_5': '7.5',
  '7': '7',
  'PHYSICAL': 'physical',
  'ELECTRONIC': 'electronic',
  '62': '62',
  '60': '60',
  '6_5': '6.5',
  '6': '6',
  '58': '58',
  '56S': '56s',
  '56': '56',
  '54': '54',
  '52S': '52s',
  '52L': '52l',
  '52': '52',
  '50': '50',
  '5': '5',
  '48S': '48s',
  '48L': '48l',
  '48': '48',
  '47L': '47l',
  '47S': '47s',
  '47': '47',
  '46S': '46s',
  '46L': '46l',
  '46': '46',
  '45_46': '45/46',
  '45': '45',
  '44S': '44s',
  '44L': '44l',
  '44': '44',
  '43L_44L': '43l/44l',
  '43_44': '43/44',
  '43L': '43l',
  '43_46': '43-46',
  '43': '43',
  '42S': '42s',
  '42L': '42l',
  '42': '42',
  '41L_42L': '41l/42l',
  '41_42': '41/42',
  '41L': '41l',
  '41': '41',
  '40S': '40s',
  '40L': '40l',
  '40_7': '40-7',
  '40': '40',
  '39L_40L': '39l/40l',
  '39_40': '39/40',
  '39L': '39l',
  '39_7': '39-7',
  '39_42': '39-42',
  '39': '39',
  '38S': '38s',
  '38L': '38l',
  '38_7': '38-7',
  '38': '38',
  '37_38': '37/38',
  '37S': '37s',
  '37L': '37l',
  '37': '37',
  '36S': '36s',
  '36L': '36l',
  '36_34': '36/34',
  '36': '36',
  '35_36': '35/36',
  '35_34': '35/34',
  '35': '35',
  '34S': '34s',
  '34L': '34l',
  '34_34': '34/34',
  '34_32': '34/32',
  '34': '34',
  '33_34': '33/34',
  '33_32': '33/32',
  '32S': '32s',
  '32L': '32l',
  '32_34': '32/34',
  '32_32': '32/32',
  '32': '32',
  '31_34': '31/34',
  '31_32': '31/32',
  '30S': '30s',
  '30L': '30l',
  '30_32': '30/32',
  '30': '30',
  '29_32': '29/32',
  '29': '29',
  '28': '28',
  '27': '27',
  '26': '26',
  '25': '25',
  '24': '24',
  '23': '23',
  '22': '22',
  '19': '19',
  '18_1_2': '18 1/2',
  '18': '18',
  '17L': '17l',
  '17_3_4L': '17 3/4l',
  '18_1_2S': '18 1/2s',
  '17_1_2L': '17 1/2l',
  '17_1_2': '17 1/2',
  '17': '17',
  '16L': '16l',
  '16_1_2': '16 1/2',
  '16': '16',
  '15_3_4': '15 3/4',
  '15_1_2L': '15 1/2l',
  '15_1_2': '15 1/2',
  '15': '15',
  '14_1_2S': '14 1/2s',
  '14_1_2': '14 1/2',
  '14': '14',
  '13': '13',
  '12': '12',
  '118': '118',
  '114': '114',
  '110': '110',
  '11': '11',
  '106': '106',
  '105': '105',
  '102': '102',
  '10_5': '10.5',
  '10': '10',
  '': ' ',
  'ONE_SIZE': 'one-size',
} as const;
export type VariantType = typeof Variant;
export type VariantValueType = VariantType[keyof VariantType];

let Segment: any;

export function initAvo(options: {env: AvoEnv; webDebugger?: boolean;
  webDebuggerOptions?: webDebuggerOptions; strict?: boolean; noop?: boolean;
  reportFailureAs?: 'error' | 'warn' | 'log'; inspector?: AvoInspector;
  avoLogger?: AvoLogger}, destinationOptions: any,
  SegmentDestination: CustomDestination) {
  if (__AVO_ENV__ !== null) {
    return;
  }
  __AVO_ENV__ = options.env;
  if (options.avoLogger !== undefined) {
    __AVO_LOGGER__ = options.avoLogger;
  }
  if (options.noop === true) {
    __AVO_NOOP__ = true;
  }
  if (__AVO_NOOP__ && __AVO_ENV__ == AvoEnv.Prod) {
    InternalAvoLogger.warn("[avo] ****************************************************");
    InternalAvoLogger.warn("[avo] WARNING Avo cannot be initialized in noop mode in production:");
    InternalAvoLogger.warn("[avo] - Overwriting configuration with noop=false.");
    InternalAvoLogger.warn("[avo] - Please reach out if you want to be able to run Avo in production mode with noop=true");
    InternalAvoLogger.warn("[avo] ****************************************************");
    __AVO_NOOP__ = false;
  }
  if (__AVO_NOOP__) {
    InternalAvoLogger.log("[avo] ****************************************************");
    InternalAvoLogger.log("[avo] Avo is now initialized in noop mode. This means:");
    InternalAvoLogger.log("[avo] - No events will be sent");
    InternalAvoLogger.log("[avo] - No network requests are made");
    InternalAvoLogger.log("[avo] ****************************************************");
  }
  if (options.strict !== undefined) {
    __STRICT__ = options.strict !== false;
  }
  if (options.reportFailureAs !== undefined) {
    __REPORT_FAILURE_AS__ = options.reportFailureAs;
  }
  __WEB_DEBUGGER__ = !__AVO_NOOP__ && ((typeof window !== 'undefined' && (window as any).location.search.indexOf("avo_debug=1") > -1) || (options.webDebugger !== false && __AVO_ENV__ !== AvoEnv.Prod));
  if (!__AVO_NOOP__ && options.inspector !== undefined) {
    __INSPECTOR__ = options.inspector;
  } else if (__AVO_ENV__ !== 'prod') {
    InternalAvoLogger.warn("[avo] Avo Inspector not provided in initAvo() call");
  }

  destinationOptions = destinationOptions || {};

  if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
    if (options.webDebuggerOptions?.position) {
      _avo_debugger_send_position(options.webDebuggerOptions.position)
    }

(function() {
  if (typeof (window as any) === 'undefined') { return; }
  let init = function() {
    let iframe: any = document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.id = "avo-debugger";
    iframe.src = "https://www.avo.app/_debugger";
    iframe.style = "display: none;";
  };

  if (document.body) {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', init);
  }
})();

  }
  if (!__AVO_NOOP__) {
    if (__AVO_ENV__ === AvoEnv.Prod) {
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
    }
    if (__AVO_ENV__ === AvoEnv.Staging) {
    }

    Segment = SegmentDestination;
    if (__AVO_ENV__ === 'prod') {
      Segment && Segment.make && Segment.make(__AVO_ENV__, "4OhwIsrqFzQ0aeuYev8muGSWiimGtGIG");
    } else if (__AVO_ENV__ === 'dev') {
      Segment && Segment.make && Segment.make(__AVO_ENV__, null);
    } else {
      Segment && Segment.make && Segment.make(__AVO_ENV__, null);
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
      // debug console in Avo
      _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
    }
  }
}

function assertPrice(price: number, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertFloat("5RMsvPEmT", _label ? "price" + ': ' + _label : "price", price));
  messages = messages.concat(AvoAssert.assertMin("5RMsvPEmT", _label ? "price" + ": " + _label : "price", 0.000000, price));
  return messages;
}

function assertValue(value: number, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertFloat("Kddfsr3Xx", _label ? "value" + ': ' + _label : "value", value));
  messages = messages.concat(AvoAssert.assertMin("Kddfsr3Xx", _label ? "value" + ": " + _label : "value", 0.000000, value));
  return messages;
}

function assertProductsCart(productsCart: ProductsCart[], _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertList("NbRKFviNt", "products_cart", productsCart));
  if (productsCart !== null && productsCart !== undefined && productsCart.forEach) {
    productsCart.forEach(function (value, index) {
      messages = messages.concat(AvoAssert.assertObject("NbRKFviNt", "item at index " + index + " in products_cart", value));
      messages = messages.concat(assertAmount(value.amount, "item at index " + index + " in products_cart"));
      messages = messages.concat(assertQuantity(value.quantity, "item at index " + index + " in products_cart"));
    });
  }
  return messages;
}

function assertProductsCartOptional(
  productsCart: ProductsCart[] | null | undefined, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  if (productsCart !== undefined && productsCart !== null) {
    messages = messages.concat(AvoAssert.assertList("NbRKFviNt", "products_cart", productsCart));
    productsCart.forEach(function (value, index) {
      messages = messages.concat(AvoAssert.assertObject("NbRKFviNt", "item at index " + index + " in products_cart", value));
      messages = messages.concat(assertAmount(value.amount, "item at index " + index + " in products_cart"));
      messages = messages.concat(assertQuantity(value.quantity, "item at index " + index + " in products_cart"));
    });
  }
  return messages;
}

function assertAmount(amount: number, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertFloat("Vk6lWRKl5", _label ? "amount" + ': ' + _label : "amount", amount));
  messages = messages.concat(AvoAssert.assertMin("Vk6lWRKl5", _label ? "amount" + ": " + _label : "amount", 0.000000, amount));
  return messages;
}

function assertQuantity(quantity: number, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertInt("fg1kaNXr_", _label ? "quantity" + ': ' + _label : "quantity", quantity));
  messages = messages.concat(AvoAssert.assertMin("fg1kaNXr_", _label ? "quantity" + ": " + _label : "quantity", 1, quantity));
  return messages;
}

function assertPosition(position: number, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertInt("pBPbnpdFj", _label ? "position" + ': ' + _label : "position", position));
  messages = messages.concat(AvoAssert.assertMin("pBPbnpdFj", _label ? "position" + ": " + _label : "position", 1, position));
  return messages;
}

function assertProducts(products: Products[], _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertList("rg6ssTw5D", "products", products));
  if (products !== null && products !== undefined && products.forEach) {
    products.forEach(function (value, index) {
      messages = messages.concat(AvoAssert.assertObject("rg6ssTw5D", "item at index " + index + " in products", value));
      messages = messages.concat(assertPrice(value.price, "item at index " + index + " in products"));
      messages = messages.concat(assertQuantity(value.quantity, "item at index " + index + " in products"));
      messages = messages.concat(assertPosition(value.position, "item at index " + index + " in products"));
      messages = messages.concat(assertValue(value.value, "item at index " + index + " in products"));
    });
  }
  return messages;
}

export function setAvoLogger(avoLogger: AvoLogger | null) {
  __AVO_LOGGER__ = avoLogger;
}

export interface ProductListViewedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  listId: ListIdValueType;
  itemListName: ItemListNameValueType;
  products: Products[];
  value: number;
}
/**
 * Product List Viewed: When a product is shown in the viewport

 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.listId: This describes the listname + unique location

for custommade it's: custom-made
 * @param properties.itemListName: Name of list where products are shown

For custommade, it's 'custom made'
 * @param properties.products: An array of products needed for the specific event
 * @param properties.value: Price * Quantity
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/syjGhZU5cD}
 */
export function productListViewed(properties: ProductListViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "Ygy1AgJzJ", name: "list_id", value: properties.listId});
  eventPropertiesArray.push({id: "6NzVmVGcY", name: "item_list_name", value: properties.itemListName});
  eventPropertiesArray.push({id: "rg6ssTw5D", name: "products", value: properties.products.map(function(value) { return {
      "product_id": value.productId,
      "category": value.category,
      "item_category2": value.itemCategory2,
      "item_category3": value.itemCategory3,
      "item_category4": value.itemCategory4,
      "item_category5": value.itemCategory5,
      "affiliation": value.affiliation,
      "item_list_name": value.itemListName,
      "brand": "suitsupply",
      "variant": value.variant,
      "price": value.price,
      "quantity": value.quantity,
      "currency": value.currency,
      "position": value.position,
      "image_url": value.imageUrl,
      "online_material": value.onlineMaterial,
      "fit_id": value.fitId,
      "plp_grid_images": value.plpGridImages,
      "shop_the_look_id": value.shopTheLookId,
      "mix_and_match": value.mixAndMatch,
      "product_set_id": value.productSetId,
      "name": value.name,
      "list_id": value.listId,
      "sku": value.sku,
      "full_look": value.fullLook,
      "value": value.value,
      "location_id": value.locationId,
      "url": value.url,
    }
  })});
  eventPropertiesArray.push({id: "Kddfsr3Xx", name: "value", value: properties.value});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertProducts(properties.products));
    messages = messages.concat(assertValue(properties.value));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "syjGhZU5cD", "da6976af504db2a4f83fee0ded1427f4dd06ca860708dfcfc9971706bd5ca6a5", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Product List Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("syjGhZU5cD", "Product List Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Product List Viewed': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product List Viewed", eventProperties, "syjGhZU5cD", "da6976af504db2a4f83fee0ded1427f4dd06ca860708dfcfc9971706bd5ca6a5");
    }
    // destination Segment
    Segment.logEvent("Product List Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ProductClickedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  listId: ListIdValueType;
  itemListName: ItemListNameValueType;
  productId: string;
  category: CategoryValueType;
  itemCategory2: string;
  itemCategory3: string;
  itemCategory4: string;
  itemCategory5: string;
  affiliation: AffiliationValueType;
  name: string;
  variant: VariantValueType;
  price: number;
  quantity: number;
  position: number;
  imageUrl: string;
  onlineMaterial: string | null | undefined;
  fitId: string | null | undefined;
  plpGridImages: PlpGridImagesValueType;
  shopTheLookId: string;
  cartId: string;
  mixAndMatch: MixAndMatchValueType;
  productSetId: string;
  sku: string;
  fullLook: string;
  value: number;
}
/**
 * Product Clicked: When clicking on a product to go to PLP, or in a panel when clicking on 'select size' When a product is shown in the viewport

 *
 * When to trigger this event:
 * 1. When clicking on a product on a PLP
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/kHvR74th3/trigger/hFTLKlhB8
 * 2. When clicking on a product on a PDP in the 'You Might Also Like' carousel.
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/kHvR74th3/trigger/uc6_I1PJe
 * 3. When clicking on 'Select Size' in the 'Shop the Look' panel
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/kHvR74th3/trigger/2eMjubKrf
 * 4. When clicking on a product on a Journal
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/kHvR74th3/trigger/cWUk2zzqA
 * 5. When clicking on a product on the store locator page
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/kHvR74th3/trigger/Bpf-If4w-
 * 6. When clicking on a product on a store detail page
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/kHvR74th3/trigger/D-ioHoDIM
 * 7. When clicking on 'shop giftcard' button, on the Giftcard landing page
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/kHvR74th3/trigger/ecFszVd66
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.listId: This describes the listname + unique location

for custommade it's: custom-made
 * @param properties.itemListName: Name of list where products are shown

For custommade, it's 'custom made'
 * @param properties.productId: SKU of product

for custommade:
trousers: dyo-trousers
jacket: dyo-jacket
waistcoat: dyo-waistcoat
coat: dyo-coat
shirt: dyo-shirt
 * @param properties.category: Product Category
 * @param properties.itemCategory2: no description
 * @param properties.itemCategory3: no description
 * @param properties.itemCategory4: no description
 * @param properties.itemCategory5: no description
 * @param properties.affiliation: This should be webstore-{{currencycode lowercase}}
 * @param properties.name: Name of product as listed in Salesforce, english only, lowercase
Will be gift card for giftcard

for custommade:
trousers: dyo-trousers
jacket: dyo-jacket
waistcoat: dyo-waistcoat
coat: dyo-coat
shirt: dyo-shirt
 * @param properties.variant: Should return size for all products except giftcard. For Giftcard should return 'physical' or 'electronic'
 * @param properties.price: Price of single product
 * @param properties.quantity: Amount of products added to cart
 * @param properties.position: Position of product, should always start with 1
 * @param properties.imageUrl: URL of shown image/hero of product
Can sometimes be different from viewport url
 * @param properties.onlineMaterial: Material of product
 * @param properties.fitId: no description
 * @param properties.plpGridImages: The view of the product on PLP, how many products per row, and how many pictures if 1 item is shown on a row.
 * @param properties.shopTheLookId: This describes the shop the look id, if available
 * @param properties.cartId: Cart ID of customer
 * @param properties.mixAndMatch: Should return 'true' or 'false'
 * @param properties.productSetId: Product Set Id when product is mix and match
 * @param properties.sku: SKU of product, same as product_id
 * @param properties.fullLook: Send an additional variable that returns all the SKU's of the products when a full look is added to Wishlist (lookbuilder).
 * @param properties.value: Price * Quantity
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/kHvR74th3}
 */
export function productClicked(properties: ProductClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "Ygy1AgJzJ", name: "list_id", value: properties.listId});
  eventPropertiesArray.push({id: "6NzVmVGcY", name: "item_list_name", value: properties.itemListName});
  eventPropertiesArray.push({id: "RmqMZ4ONr", name: "product_id", value: properties.productId});
  eventPropertiesArray.push({id: "IiKR7dr0F", name: "category", value: properties.category});
  eventPropertiesArray.push({id: "sQMBHWFY_", name: "item_category2", value: properties.itemCategory2});
  eventPropertiesArray.push({id: "JieId3fKm", name: "item_category3", value: properties.itemCategory3});
  eventPropertiesArray.push({id: "5vOpX9hBs", name: "item_category4", value: properties.itemCategory4});
  eventPropertiesArray.push({id: "AtqbQIXpb", name: "item_category5", value: properties.itemCategory5});
  eventPropertiesArray.push({id: "c5sqtMlHz", name: "affiliation", value: properties.affiliation});
  eventPropertiesArray.push({id: "A8Oa9tHEe", name: "name", value: properties.name});
  eventPropertiesArray.push({id: "rNNvglLqV", name: "variant", value: properties.variant});
  eventPropertiesArray.push({id: "5RMsvPEmT", name: "price", value: properties.price});
  eventPropertiesArray.push({id: "fg1kaNXr_", name: "quantity", value: properties.quantity});
  eventPropertiesArray.push({id: "pBPbnpdFj", name: "position", value: properties.position});
  eventPropertiesArray.push({id: "EmHDBRDoN", name: "image_url", value: properties.imageUrl});
  properties.onlineMaterial !== undefined && properties.onlineMaterial !== null ?
    eventPropertiesArray.push({id: "h_-BHBawq", name: "online_material", value: properties.onlineMaterial}) :
    eventPropertiesArray.push({id: "h_-BHBawq", name: "online_material", value: null});
  properties.fitId !== undefined && properties.fitId !== null ?
    eventPropertiesArray.push({id: "SfqMYUwv4", name: "fit_id", value: properties.fitId}) :
    eventPropertiesArray.push({id: "SfqMYUwv4", name: "fit_id", value: null});
  eventPropertiesArray.push({id: "D8L1_0pcs", name: "plp_grid_images", value: properties.plpGridImages});
  eventPropertiesArray.push({id: "vPAMuxU06", name: "shop_the_look_id", value: properties.shopTheLookId});
  eventPropertiesArray.push({id: "14smmnfJh", name: "cart_id", value: properties.cartId});
  eventPropertiesArray.push({id: "3YGdl5qO_", name: "mix_and_match", value: properties.mixAndMatch});
  eventPropertiesArray.push({id: "kjpadIQOI", name: "product_set_id", value: properties.productSetId});
  eventPropertiesArray.push({id: "Yf36kEtqN", name: "sku", value: properties.sku});
  eventPropertiesArray.push({id: "1Ull1Kol-", name: "full_look", value: properties.fullLook});
  eventPropertiesArray.push({id: "Kddfsr3Xx", name: "value", value: properties.value});
  eventPropertiesArray.push({id: "cAQamFkP_", name: "brand", value: "suitsupply"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertPrice(properties.price));
    messages = messages.concat(assertQuantity(properties.quantity));
    messages = messages.concat(assertPosition(properties.position));
    messages = messages.concat(assertValue(properties.value));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "kHvR74th3", "4c4d8a644679a074d981e5d84401dd3eb4b1247f7311150d7868fc039701950e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Product Clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("kHvR74th3", "Product Clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Product Clicked': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product Clicked", eventProperties, "kHvR74th3", "4c4d8a644679a074d981e5d84401dd3eb4b1247f7311150d7868fc039701950e");
    }
    // destination Segment
    Segment.logEvent("Product Clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ProductViewedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  listId: ListIdValueType;
  itemListName: ItemListNameValueType;
  productId: string;
  category: CategoryValueType;
  itemCategory2: string;
  itemCategory3: string;
  itemCategory4: string;
  itemCategory5: string;
  affiliation: AffiliationValueType;
  name: string;
  variant: VariantValueType | null | undefined;
  price: number;
  quantity: number;
  position: number;
  imageUrl: string;
  onlineMaterial: string | null | undefined;
  fitId: string | null | undefined;
  plpGridImages: PlpGridImagesValueType;
  shopTheLookId: string;
  cartId: string;
  mixAndMatch: MixAndMatchValueType;
  productSetId: string;
  sku: string;
  fullLook: string;
  value: number;
}
/**
 * Product Viewed: When landing an a PDP, or clicking on 'select size' in panel

 *
 * When to trigger this event:
 * 1. When clicking on 'Select Size' in the 'Shop the Look' panel
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/4a040kO3L/trigger/biZR2zvCbF
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.listId: This describes the listname + unique location

for custommade it's: custom-made
 * @param properties.itemListName: Name of list where products are shown

For custommade, it's 'custom made'
 * @param properties.productId: SKU of product

for custommade:
trousers: dyo-trousers
jacket: dyo-jacket
waistcoat: dyo-waistcoat
coat: dyo-coat
shirt: dyo-shirt
 * @param properties.category: Product Category
 * @param properties.itemCategory2: no description
 * @param properties.itemCategory3: no description
 * @param properties.itemCategory4: no description
 * @param properties.itemCategory5: no description
 * @param properties.affiliation: This should be webstore-{{currencycode lowercase}}
 * @param properties.name: Name of product as listed in Salesforce, english only, lowercase
Will be gift card for giftcard

for custommade:
trousers: dyo-trousers
jacket: dyo-jacket
waistcoat: dyo-waistcoat
coat: dyo-coat
shirt: dyo-shirt
 * @param properties.variant: Should return size for all products except giftcard. For Giftcard should return 'physical' or 'electronic'
 * @param properties.price: Price of single product
 * @param properties.quantity: Amount of products added to cart
 * @param properties.position: Position of product, should always start with 1
 * @param properties.imageUrl: URL of shown image/hero of product
Can sometimes be different from viewport url
 * @param properties.onlineMaterial: Material of product
 * @param properties.fitId: no description
 * @param properties.plpGridImages: The view of the product on PLP, how many products per row, and how many pictures if 1 item is shown on a row.
 * @param properties.shopTheLookId: This describes the shop the look id, if available
 * @param properties.cartId: Cart ID of customer
 * @param properties.mixAndMatch: Should return 'true' or 'false'
 * @param properties.productSetId: Product Set Id when product is mix and match
 * @param properties.sku: SKU of product, same as product_id
 * @param properties.fullLook: Send an additional variable that returns all the SKU's of the products when a full look is added to Wishlist (lookbuilder).
 * @param properties.value: Price * Quantity
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/4a040kO3L}
 */
export function productViewed(properties: ProductViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "Ygy1AgJzJ", name: "list_id", value: properties.listId});
  eventPropertiesArray.push({id: "6NzVmVGcY", name: "item_list_name", value: properties.itemListName});
  eventPropertiesArray.push({id: "RmqMZ4ONr", name: "product_id", value: properties.productId});
  eventPropertiesArray.push({id: "IiKR7dr0F", name: "category", value: properties.category});
  eventPropertiesArray.push({id: "sQMBHWFY_", name: "item_category2", value: properties.itemCategory2});
  eventPropertiesArray.push({id: "JieId3fKm", name: "item_category3", value: properties.itemCategory3});
  eventPropertiesArray.push({id: "5vOpX9hBs", name: "item_category4", value: properties.itemCategory4});
  eventPropertiesArray.push({id: "AtqbQIXpb", name: "item_category5", value: properties.itemCategory5});
  eventPropertiesArray.push({id: "c5sqtMlHz", name: "affiliation", value: properties.affiliation});
  eventPropertiesArray.push({id: "A8Oa9tHEe", name: "name", value: properties.name});
  properties.variant !== undefined && properties.variant !== null ?
    eventPropertiesArray.push({id: "rNNvglLqV", name: "variant", value: properties.variant}) :
    eventPropertiesArray.push({id: "rNNvglLqV", name: "variant", value: null});
  eventPropertiesArray.push({id: "5RMsvPEmT", name: "price", value: properties.price});
  eventPropertiesArray.push({id: "fg1kaNXr_", name: "quantity", value: properties.quantity});
  eventPropertiesArray.push({id: "pBPbnpdFj", name: "position", value: properties.position});
  eventPropertiesArray.push({id: "EmHDBRDoN", name: "image_url", value: properties.imageUrl});
  properties.onlineMaterial !== undefined && properties.onlineMaterial !== null ?
    eventPropertiesArray.push({id: "h_-BHBawq", name: "online_material", value: properties.onlineMaterial}) :
    eventPropertiesArray.push({id: "h_-BHBawq", name: "online_material", value: null});
  properties.fitId !== undefined && properties.fitId !== null ?
    eventPropertiesArray.push({id: "SfqMYUwv4", name: "fit_id", value: properties.fitId}) :
    eventPropertiesArray.push({id: "SfqMYUwv4", name: "fit_id", value: null});
  eventPropertiesArray.push({id: "D8L1_0pcs", name: "plp_grid_images", value: properties.plpGridImages});
  eventPropertiesArray.push({id: "vPAMuxU06", name: "shop_the_look_id", value: properties.shopTheLookId});
  eventPropertiesArray.push({id: "14smmnfJh", name: "cart_id", value: properties.cartId});
  eventPropertiesArray.push({id: "3YGdl5qO_", name: "mix_and_match", value: properties.mixAndMatch});
  eventPropertiesArray.push({id: "kjpadIQOI", name: "product_set_id", value: properties.productSetId});
  eventPropertiesArray.push({id: "Yf36kEtqN", name: "sku", value: properties.sku});
  eventPropertiesArray.push({id: "1Ull1Kol-", name: "full_look", value: properties.fullLook});
  eventPropertiesArray.push({id: "Kddfsr3Xx", name: "value", value: properties.value});
  eventPropertiesArray.push({id: "cAQamFkP_", name: "brand", value: "suitsupply"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertPrice(properties.price));
    messages = messages.concat(assertQuantity(properties.quantity));
    messages = messages.concat(assertPosition(properties.position));
    messages = messages.concat(assertValue(properties.value));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "4a040kO3L", "67a16070ed49f231ca327fc0d65693259511fa33276ce7d8938ffcef5fcb6ed0", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Product Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("4a040kO3L", "Product Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Product Viewed': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product Viewed", eventProperties, "4a040kO3L", "67a16070ed49f231ca327fc0d65693259511fa33276ce7d8938ffcef5fcb6ed0");
    }
    // destination Segment
    Segment.logEvent("Product Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ProductAddedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  listId: ListIdValueType;
  itemListName: ItemListNameValueType;
  productId: string;
  category: CategoryValueType;
  itemCategory2: string;
  itemCategory3: string;
  itemCategory4: string;
  itemCategory5: string;
  affiliation: AffiliationValueType;
  name: string;
  variant: VariantValueType;
  price: number;
  quantity: number;
  position: number;
  value: number;
  imageUrl: string;
  onlineMaterial: string | null | undefined;
  fitId: string | null | undefined;
  plpGridImages: PlpGridImagesValueType;
  shopTheLookId: string;
  productsCart: ProductsCart[];
  cartId: string;
  mixAndMatch: MixAndMatchValueType;
  productSetId: string;
  sku: string;
  fullLook: string;
}
/**
 * Product Added: When clicking on 'add to bag' on a PDP, panel or increasing value on the cart page.
for Custommade: when clicking on 'add to bag' on the summary screen.
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 * @param properties.listId: This describes the listname + unique location

for custommade it's: custom-made
 * @param properties.itemListName: Name of list where products are shown

For custommade, it's 'custom made'
 * @param properties.productId: SKU of product

for custommade:
trousers: dyo-trousers
jacket: dyo-jacket
waistcoat: dyo-waistcoat
coat: dyo-coat
shirt: dyo-shirt
 * @param properties.category: Product Category
 * @param properties.itemCategory2: no description
 * @param properties.itemCategory3: no description
 * @param properties.itemCategory4: no description
 * @param properties.itemCategory5: no description
 * @param properties.affiliation: This should be webstore-{{currencycode lowercase}}
 * @param properties.name: Name of product as listed in Salesforce, english only, lowercase
Will be gift card for giftcard

for custommade:
trousers: dyo-trousers
jacket: dyo-jacket
waistcoat: dyo-waistcoat
coat: dyo-coat
shirt: dyo-shirt
 * @param properties.variant: Should return size for all products except giftcard. For Giftcard should return 'physical' or 'electronic'
 * @param properties.price: Price of single product
 * @param properties.quantity: Amount of products added to cart
 * @param properties.position: Position of product, should always start with 1
 * @param properties.value: Price * Quantity
 * @param properties.imageUrl: URL of shown image/hero of product
Can sometimes be different from viewport url
 * @param properties.onlineMaterial: Material of product
 * @param properties.fitId: no description
 * @param properties.plpGridImages: The view of the product on PLP, how many products per row, and how many pictures if 1 item is shown on a row.
 * @param properties.shopTheLookId: This describes the shop the look id, if available
 * @param properties.productsCart: Previously known ssm_products. Should contain an array of all products in the cart. Needs to be cleared on the 'Order Completed' event.
 * @param properties.cartId: Cart ID of customer
 * @param properties.mixAndMatch: Should return 'true' or 'false'
 * @param properties.productSetId: Product Set Id when product is mix and match
 * @param properties.sku: SKU of product, same as product_id
 * @param properties.fullLook: Send an additional variable that returns all the SKU's of the products when a full look is added to Wishlist (lookbuilder).
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/0mNPEK9jV}
 */
export function productAdded(properties: ProductAddedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "Ygy1AgJzJ", name: "list_id", value: properties.listId});
  eventPropertiesArray.push({id: "6NzVmVGcY", name: "item_list_name", value: properties.itemListName});
  eventPropertiesArray.push({id: "RmqMZ4ONr", name: "product_id", value: properties.productId});
  eventPropertiesArray.push({id: "IiKR7dr0F", name: "category", value: properties.category});
  eventPropertiesArray.push({id: "sQMBHWFY_", name: "item_category2", value: properties.itemCategory2});
  eventPropertiesArray.push({id: "JieId3fKm", name: "item_category3", value: properties.itemCategory3});
  eventPropertiesArray.push({id: "5vOpX9hBs", name: "item_category4", value: properties.itemCategory4});
  eventPropertiesArray.push({id: "AtqbQIXpb", name: "item_category5", value: properties.itemCategory5});
  eventPropertiesArray.push({id: "c5sqtMlHz", name: "affiliation", value: properties.affiliation});
  eventPropertiesArray.push({id: "A8Oa9tHEe", name: "name", value: properties.name});
  eventPropertiesArray.push({id: "rNNvglLqV", name: "variant", value: properties.variant});
  eventPropertiesArray.push({id: "5RMsvPEmT", name: "price", value: properties.price});
  eventPropertiesArray.push({id: "fg1kaNXr_", name: "quantity", value: properties.quantity});
  eventPropertiesArray.push({id: "pBPbnpdFj", name: "position", value: properties.position});
  eventPropertiesArray.push({id: "Kddfsr3Xx", name: "value", value: properties.value});
  eventPropertiesArray.push({id: "EmHDBRDoN", name: "image_url", value: properties.imageUrl});
  properties.onlineMaterial !== undefined && properties.onlineMaterial !== null ?
    eventPropertiesArray.push({id: "h_-BHBawq", name: "online_material", value: properties.onlineMaterial}) :
    eventPropertiesArray.push({id: "h_-BHBawq", name: "online_material", value: null});
  properties.fitId !== undefined && properties.fitId !== null ?
    eventPropertiesArray.push({id: "SfqMYUwv4", name: "fit_id", value: properties.fitId}) :
    eventPropertiesArray.push({id: "SfqMYUwv4", name: "fit_id", value: null});
  eventPropertiesArray.push({id: "D8L1_0pcs", name: "plp_grid_images", value: properties.plpGridImages});
  eventPropertiesArray.push({id: "vPAMuxU06", name: "shop_the_look_id", value: properties.shopTheLookId});
  eventPropertiesArray.push({id: "NbRKFviNt", name: "products_cart", value: properties.productsCart.map(function(value) { return {
      "amount": value.amount,
      "identifier": value.identifier,
      "variation": value.variation,
      "currency": value.currency,
      "quantity": value.quantity,
      "marketingcloudpid": value.marketingcloudpid,
      "variant_id": value.variantId,
    }
  })});
  eventPropertiesArray.push({id: "14smmnfJh", name: "cart_id", value: properties.cartId});
  eventPropertiesArray.push({id: "3YGdl5qO_", name: "mix_and_match", value: properties.mixAndMatch});
  eventPropertiesArray.push({id: "kjpadIQOI", name: "product_set_id", value: properties.productSetId});
  eventPropertiesArray.push({id: "Yf36kEtqN", name: "sku", value: properties.sku});
  eventPropertiesArray.push({id: "1Ull1Kol-", name: "full_look", value: properties.fullLook});
  eventPropertiesArray.push({id: "cAQamFkP_", name: "brand", value: "suitsupply"});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertPrice(properties.price));
    messages = messages.concat(assertQuantity(properties.quantity));
    messages = messages.concat(assertPosition(properties.position));
    messages = messages.concat(assertValue(properties.value));
    messages = messages.concat(assertProductsCart(properties.productsCart));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "0mNPEK9jV", "0b82cb7d86f071e5cead0b95a1b103da056a0998518a6daaef6db271874122c3", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Product Added", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("0mNPEK9jV", "Product Added", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Product Added': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Product Added", eventProperties, "0mNPEK9jV", "0b82cb7d86f071e5cead0b95a1b103da056a0998518a6daaef6db271874122c3");
    }
    // destination Segment
    Segment.logEvent("Product Added", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PageLoadedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  customDestinationPageName_: string;
  localeVisited: string;
  productsCart: ProductsCart[] | null | undefined;
}
/**
 * Page Loaded: When a page or a virtual page is loaded
for custommade: products_cart and referrer not needed
 *
 * When to trigger this event:
 * 1. Title: instore - checkin - login
url: https://suitsupply.com/en-nl//instore/checkin-login/
location_id: https://suitsupply.com/en-nl//instore/checkin-login/
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/4l0ZskS226/trigger/GzKmdfzMs
 * 2. title: instore - checkin - register
location_id: https://suitsupply.com/en-nl/instore/checkin-register/
url: https://suitsupply.com/instore/checkin-register/
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/4l0ZskS226/trigger/h5T0sz4zf
 * 3. title: instore - checkin - long succeeded
url: https://suitsupply.com/instore/checkin-succeeded-wait-long/
location_id: https://suitsupply.com/en-nl/instore/checkin-succeeded-wait-long/
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/4l0ZskS226/trigger/WL4lVMb0g
 * 4. title: instore - checkin - short succeeded
url: https://suitsupply.com/instore/checkin-succeeded-wait-short/
location_id: https://suitsupply.com/en-nl/instore/checkin-succeeded-wait-short/
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/4l0ZskS226/trigger/Djt5Cknvx
 * 5. 1. black-tie-configurator page 1 (jacket):

url:

https://suitsupply.com/men/black-tie-package/mix-match-tuxedo/jacket


location_id:

https://suitsupply.com/en-nl/men/black-tie-package/mix-match-tuxedo/jacket
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/4l0ZskS226/trigger/bjjJ9GC32
 * 6. 3.black-tie-configurator page 3 (trousers):

url:

https://suitsupply.com/men/black-tie-package/mix-match-tuxedo/trousers

location_id:

https://suitsupply.com/en-nl/men/black-tie-package/mix-match-tuxedo/trousers
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/4l0ZskS226/trigger/9N-3rJX7w
 * 7. 4.black-tie-configurator page 4 (shirt):

url:

https://suitsupply.com/men/black-tie-package/mix-match-tuxedo/shirt

location_id:

https://suitsupply.com/en-nl/men/black-tie-package/mix-match-tuxedo/shirt
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/4l0ZskS226/trigger/hRyZsTCWg
 * 8. 5.black-tie-configurator page 5 (shoes):

url:

https://suitsupply.com/men/black-tie-package/mix-match-tuxedo/shoes

location_id:

Two piece::
https://suitsupply.com/en-nl/men/black-tie-package/mix-match-tuxedo/shoes
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/4l0ZskS226/trigger/SgNDh79wH
 * 9. 6.black-tie-configurator page 6 (bow-tie):

url:

https://suitsupply.com/men/black-tie-package/mix-match-tuxedo/bowtie

location_id:

https://suitsupply.com/en-nl/men/black-tie-package/mix-match-tuxedo/bowtie
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/4l0ZskS226/trigger/fqSg1J0Lw
 * 10. 7.black-tie-configurator page 7 (cufflinks):

url:

https://suitsupply.com/men/black-tie-package/mix-match-tuxedo/cufflinks


location_id:

https://suitsupply.com/en-nl/men/black-tie-package/mix-match-tuxedo/cufflinks
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/4l0ZskS226/trigger/bObRbcuk9
 * 11. 8.black-tie-configurator page 8 (suspenders):

url:

https://suitsupply.com/men/black-tie-package/mix-match-tuxedo/suspenders

location_id:

https://suitsupply.com/en-nl/men/black-tie-package/mix-match-tuxedo/suspenders
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/4l0ZskS226/trigger/sW1aJ_pG0
 * 12. 9.black-tie-configurator page 9 (summary):

url:

https://suitsupply.com/men/black-tie-package/mix-match-tuxedo/summary

location_id:

https://suitsupply.com/en-nl/men/black-tie-package/mix-match-tuxedo/summary
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/4l0ZskS226/trigger/dmE9uQLdg
 * 13. 1b.black-tie-configurator page 1b (jacket lapel):

url:

https://suitsupply.com/men/black-tie-package/mix-match-tuxedo/jacket/lapel


location_id:

https://suitsupply.com/en-nl/men/black-tie-package/mix-match-tuxedo/jacket/lapel
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/4l0ZskS226/trigger/tfcaQfTbT
 * 14. 2.black-tie-configurator page 1 (waistcoat):

url:

https://suitsupply.com/men/black-tie-package/mix-match-tuxedo/waistcoat

location_id:

https://suitsupply.com/en-nl/men/black-tie-package/mix-match-tuxedo/waistcoat
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/4l0ZskS226/trigger/JtGp7KJRc
 * 15. page_type: category_landing
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/4l0ZskS226/trigger/A-zNqBmM1
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.customDestinationPageName_: Name of the page or screen
 * @param properties.localeVisited: Locale visited, for example en-us or es-us
 * @param properties.productsCart: Previously known ssm_products. Should contain an array of all products in the cart. Needs to be cleared on the 'Order Completed' event.
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/4l0ZskS226}
 */
export function pageLoaded(properties: PageLoadedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "QSRXqGwpG", name: "locale_visited", value: properties.localeVisited});
  properties.productsCart !== undefined && properties.productsCart !== null ?
    eventPropertiesArray.push({id: "NbRKFviNt", name: "products_cart", value: properties.productsCart.map(function(value) { return {
        "amount": value.amount,
        "identifier": value.identifier,
        "variation": value.variation,
        "currency": value.currency,
        "quantity": value.quantity,
        "marketingcloudpid": value.marketingcloudpid,
        "variant_id": value.variantId,
      }
    })}) :
    eventPropertiesArray.push({id: "NbRKFviNt", name: "products_cart", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertProductsCartOptional(properties.productsCart));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "4l0ZskS226", "16024a3c1643b726a8c5a1457e88317856868c760555c2a48995a1049b41b5d3", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Page Loaded", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("4l0ZskS226", "Page Loaded", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Page Loaded': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    // destination Segment
    if (Segment.logPage) {
      Segment.logPage(properties.customDestinationPageName_, (Object as any).assign({}, eventProperties));
    } else {
      InternalAvoLogger.error('Page method is not implemented in the destination', '');
    }
  } else {
    // do nothing
  }
}

export interface PageLoadedVirtualPageViewProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string | null | undefined;
  gaAwClid: string | null | undefined;
  customDestinationPageName_: string;
  localeVisited: string;
  productsCart: ProductsCart[] | null | undefined;
}
/**
 * Page Loaded: When a page or a virtual page is loaded
for custommade: products_cart and referrer not needed, event variant
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.customDestinationPageName_: Name of the page or screen
 * @param properties.localeVisited: Locale visited, for example en-us or es-us
 * @param properties.productsCart: Previously known ssm_products. Should contain an array of all products in the cart. Needs to be cleared on the 'Order Completed' event.
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/4l0ZskS226.zXISHUvOO}
 */
export function pageLoadedVirtualPageView(
  properties: PageLoadedVirtualPageViewProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  properties.fbFbc !== undefined && properties.fbFbc !== null ?
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc}) :
    eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: null});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "QSRXqGwpG", name: "locale_visited", value: properties.localeVisited});
  properties.productsCart !== undefined && properties.productsCart !== null ?
    eventPropertiesArray.push({id: "NbRKFviNt", name: "products_cart", value: properties.productsCart.map(function(value) { return {
        "amount": value.amount,
        "identifier": value.identifier,
        "variation": value.variation,
        "currency": value.currency,
        "quantity": value.quantity,
        "marketingcloudpid": value.marketingcloudpid,
        "variant_id": value.variantId,
      }
    })}) :
    eventPropertiesArray.push({id: "NbRKFviNt", name: "products_cart", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertProductsCartOptional(properties.productsCart));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "4l0ZskS226.zXISHUvOO", "013f595395a2d47b95d5dea677fb82e8aeb7c9017ea8cc0260d2e271f53c585c", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Page Loaded", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("4l0ZskS226.zXISHUvOO", "Page Loaded", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Page Loaded': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    // destination Segment
    if (Segment.logPage) {
      Segment.logPage(properties.customDestinationPageName_, (Object as any).assign({}, eventProperties));
    } else {
      InternalAvoLogger.error('Page method is not implemented in the destination', '');
    }
  } else {
    // do nothing
  }
}

export interface PromotionViewedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  promotionId: string;
  name: string;
  creative: CreativeValueType;
  position: number;
  ssMedium: SsMediumValueType | null | undefined;
  ssSource: string | null | undefined;
}
/**
 * Promotion Viewed: When a promotion is being shown in the viewport
Only add the 'products' object, if there are products in the promotion.

ss_source and ss_medium should only be filled for booking panel and SA panel

 *
 * When to trigger this event:
 * 1. Journal - Support

promotion_name and id:
delivery-time-cost_support
returns-refunds_support
secure-payments_support
custommade-customizing_app
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/Xo8CpcqD3k/trigger/dmF0VfnHj
 * 2. Footer - Newsletter

promotion_id & name:
newsletter_campaign

Creative:
footer_link
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/Xo8CpcqD3k/trigger/9dwfTm8_b
 * 3. Lookbuilder - Shop the look

promotion id + name:
lookbuilder_app

creative:
shop-the-look_panel
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/Xo8CpcqD3k/trigger/Do1oLQCdp
 * 4. Lookbuilder - wishlist

Promotion id + name:
lookbuilder_app

Creative:
panel_wishlist
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/Xo8CpcqD3k/trigger/XvOaPaSL_
 * 5. Lookbuilder - minicart

Promotion id + name:
lookbuilder_app

Creative:
panel_minicart
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/Xo8CpcqD3k/trigger/81fjlwsyo
 * 6. Lookbuilder - MWTW

Promotion id + name:
lookbuilder_app

Creative:
pdp_panel_mwtw
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/Xo8CpcqD3k/trigger/udZGnmpX6
 * 7. ﻿Lookbuilder - summary screen

Promotion id + name:
lookbuilder_app

Creative:
lookbuilder_summary
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/Xo8CpcqD3k/trigger/uqdx_UxpT
 * 8. creative: journal_adblock
promotion_id: black-tie-configurator-tuxedo_app
name: black-tie-configurator-tuxedo_app
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/Xo8CpcqD3k/trigger/wIqd9HpV3
 * 9. creative: clp_adblock
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/Xo8CpcqD3k/trigger/WmsGiomFq
 * 10. Creative: clp_buttonlink
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/Xo8CpcqD3k/trigger/hqttb-Imb
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 * @param properties.promotionId: ID of promotion, is the same as promotion name
 * @param properties.name: Name of product as listed in Salesforce, english only, lowercase
Will be gift card for giftcard

for custommade:
trousers: dyo-trousers
jacket: dyo-jacket
waistcoat: dyo-waistcoat
coat: dyo-coat
shirt: dyo-shirt
 * @param properties.creative: Name of location and type of the promotion
 * @param properties.position: Position of product, should always start with 1
 * @param properties.ssMedium: To make sure utm tags are not overwriting the utm tags from ads, we want to make sure the BT entries are tracked with the ss_medium tag. Otherwise we are not able to track the costs per lead from our facebook or other campaigns.
 * @param properties.ssSource: To make sure utm tags are not overwriting the utm tags from ads, we want to make sure the BT entries are tracked with the ss_medium tag. Otherwise we are not able to track the costs per lead from our facebook or other campaigns.

For store pages: ss_source: store_{{storename}}
For store experience pages: ss_source: journal_{{journal name with “-” between words}}
for store custommade near me: ss_source: journal_store_{{journal name with “-“ between words + store parameter in new landing pages}}
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/Xo8CpcqD3k}
 */
export function promotionViewed(properties: PromotionViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "9zU3NBFWZ", name: "promotion_id", value: properties.promotionId});
  eventPropertiesArray.push({id: "A8Oa9tHEe", name: "name", value: properties.name});
  eventPropertiesArray.push({id: "oFjan4Q_Q", name: "creative", value: properties.creative});
  eventPropertiesArray.push({id: "pBPbnpdFj", name: "position", value: properties.position});
  properties.ssMedium !== undefined && properties.ssMedium !== null ?
    eventPropertiesArray.push({id: "dCzQ_0RdM", name: "ss_medium", value: properties.ssMedium}) :
    eventPropertiesArray.push({id: "dCzQ_0RdM", name: "ss_medium", value: null});
  properties.ssSource !== undefined && properties.ssSource !== null ?
    eventPropertiesArray.push({id: "PBq0iEV4f", name: "ss_source", value: properties.ssSource}) :
    eventPropertiesArray.push({id: "PBq0iEV4f", name: "ss_source", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertPosition(properties.position));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Xo8CpcqD3k", "097992203887eb05a63e060b8e2c75ddecb8910c4d9b33bd9ebe1c3115c888ef", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Promotion Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Xo8CpcqD3k", "Promotion Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Promotion Viewed': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Promotion Viewed", eventProperties, "Xo8CpcqD3k", "097992203887eb05a63e060b8e2c75ddecb8910c4d9b33bd9ebe1c3115c888ef");
    }
    // destination Segment
    Segment.logEvent("Promotion Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PromotionClickedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  promotionId: string;
  name: string;
  creative: CreativeValueType;
  position: number;
  ssMedium: SsMediumValueType | null | undefined;
  ssSource: string | null | undefined;
}
/**
 * Promotion Clicked: When a promotion is clicked
Only add the 'products' object, if there are products in the promotion.

ss_source and ss_medium should only be filled for booking panel and SA panel
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 * @param properties.promotionId: ID of promotion, is the same as promotion name
 * @param properties.name: Name of product as listed in Salesforce, english only, lowercase
Will be gift card for giftcard

for custommade:
trousers: dyo-trousers
jacket: dyo-jacket
waistcoat: dyo-waistcoat
coat: dyo-coat
shirt: dyo-shirt
 * @param properties.creative: Name of location and type of the promotion
 * @param properties.position: Position of product, should always start with 1
 * @param properties.ssMedium: To make sure utm tags are not overwriting the utm tags from ads, we want to make sure the BT entries are tracked with the ss_medium tag. Otherwise we are not able to track the costs per lead from our facebook or other campaigns.
 * @param properties.ssSource: To make sure utm tags are not overwriting the utm tags from ads, we want to make sure the BT entries are tracked with the ss_medium tag. Otherwise we are not able to track the costs per lead from our facebook or other campaigns.

For store pages: ss_source: store_{{storename}}
For store experience pages: ss_source: journal_{{journal name with “-” between words}}
for store custommade near me: ss_source: journal_store_{{journal name with “-“ between words + store parameter in new landing pages}}
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/lb-B8o9Lf}
 */
export function promotionClicked(properties: PromotionClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "9zU3NBFWZ", name: "promotion_id", value: properties.promotionId});
  eventPropertiesArray.push({id: "A8Oa9tHEe", name: "name", value: properties.name});
  eventPropertiesArray.push({id: "oFjan4Q_Q", name: "creative", value: properties.creative});
  eventPropertiesArray.push({id: "pBPbnpdFj", name: "position", value: properties.position});
  properties.ssMedium !== undefined && properties.ssMedium !== null ?
    eventPropertiesArray.push({id: "dCzQ_0RdM", name: "ss_medium", value: properties.ssMedium}) :
    eventPropertiesArray.push({id: "dCzQ_0RdM", name: "ss_medium", value: null});
  properties.ssSource !== undefined && properties.ssSource !== null ?
    eventPropertiesArray.push({id: "PBq0iEV4f", name: "ss_source", value: properties.ssSource}) :
    eventPropertiesArray.push({id: "PBq0iEV4f", name: "ss_source", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertPosition(properties.position));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "lb-B8o9Lf", "5436d40911bd3e1b7b5b4664444f5a9f35f7c0809143b8591e9c179c06991223", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Promotion Clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("lb-B8o9Lf", "Promotion Clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Promotion Clicked': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Promotion Clicked", eventProperties, "lb-B8o9Lf", "5436d40911bd3e1b7b5b4664444f5a9f35f7c0809143b8591e9c179c06991223");
    }
    // destination Segment
    Segment.logEvent("Promotion Clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SelectSizeProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string | null | undefined;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * select_size: When clicking the 'select size' button on PDP.

event_category:
If the event is happening on a journal page, the event_category will be plp_interactions, it's too much work for dev to create a specific journal_interactions.
For lookbuilder it will be lookbuilder_interactions


event_label: {{category}}_{{SKU}}

 *
 * When to trigger this event:
 * 1. When clicking on the 'select size' button
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/QZ8ruF0wR/trigger/K_ScJetAs
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/QZ8ruF0wR}
 */
export function selectSize(properties: SelectSizeProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  properties.eventLabel !== undefined && properties.eventLabel !== null ?
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel}) :
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "QZ8ruF0wR", "80e92af612ad59094ace47b7f9ae91598730a827162ee7625f03580062057418", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("select_size", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("QZ8ruF0wR", "select_size", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("select_size", eventProperties, "QZ8ruF0wR", "80e92af612ad59094ace47b7f9ae91598730a827162ee7625f03580062057418");
    }
    // destination Segment
    Segment.logEvent("select_size", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SelectSizeBtcProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string | null | undefined;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  sku: string;
  option: OptionValueType;
}
/**
 * select_size: When clicking the 'select size' button on PDP.

event_category:
If the event is happening on a journal page, the event_category will be plp_interactions, it's too much work for dev to create a specific journal_interactions.
For lookbuilder it will be lookbuilder_interactions


event_label: {{category}}_{{SKU}}, variant description: When clicking the 'select size' button on PDP.

event_category:
If the event is happening on a journal page, the event_category will be plp_interactions, it's too much work for dev to create a specific journal_interactions.
For lookbuilder it will be lookbuilder_interactions


event_label: {{category}}_{{SKU}}
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 * @param properties.sku: SKU of product, same as product_id
 * @param properties.option: This property denotes the configurator option that the user is presently exploring. It possesses distinct values for each individual configurator.

Black Tie Configurator: This variable indicates the specific item the user is currently selecting within the configurators, such as a jacket, lapel, shirt, or trousers.

Custom Made: This term describes the selected option and the corresponding outcome that was chosen.
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/QZ8ruF0wR.KGc1IPpsI}
 */
export function selectSizeBtc(properties: SelectSizeBtcProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  properties.eventLabel !== undefined && properties.eventLabel !== null ?
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel}) :
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "Yf36kEtqN", name: "sku", value: properties.sku});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "option", value: properties.option});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "QZ8ruF0wR.KGc1IPpsI", "dae0e7b36c9ed39b8afb4a12f1f3ccdb0c3029394cbb7c3d03cb0a2a5c03ea58", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("select_size", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("QZ8ruF0wR.KGc1IPpsI", "select_size", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("select_size", eventProperties, "QZ8ruF0wR.KGc1IPpsI", "dae0e7b36c9ed39b8afb4a12f1f3ccdb0c3029394cbb7c3d03cb0a2a5c03ea58");
    }
    // destination Segment
    Segment.logEvent("select_size", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SizeChangedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string | null | undefined;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * size_changed: When selecting a size in the size panel

event_label: {{category}}_{{SKU}}

event_category:
If the event is happening on a journal page, the event_category will be plp_interactions, it's too much work for dev to create a specific journal_interactions.
For lookbuilder it will be lookbuilder_interactions

 *
 * When to trigger this event:
 * 1. When clicking on a size in the panel
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/FLODrHtrT/trigger/THklYHMK-
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/FLODrHtrT}
 */
export function sizeChanged(properties: SizeChangedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  properties.eventLabel !== undefined && properties.eventLabel !== null ?
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel}) :
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "FLODrHtrT", "f4a7b26615dd9ab98aba09b161d671de7ca643470ffe0d418ad011ae663d835e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("size_changed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("FLODrHtrT", "size_changed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("size_changed", eventProperties, "FLODrHtrT", "f4a7b26615dd9ab98aba09b161d671de7ca643470ffe0d418ad011ae663d835e");
    }
    // destination Segment
    Segment.logEvent("size_changed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ChangeCountryProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string | null | undefined;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * change_country: When changing countries.
event_label: {{country from}}_{{country to}}
event_location: menu|pop-up|footer
 *
 * When to trigger this event:
 * 1. When selecting a country
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/P8tUoYxJa/trigger/iAlBJNRHt
 * 2. When clicking on the change country button, in the pop up banner
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/P8tUoYxJa/trigger/XocwpVpmE
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/P8tUoYxJa}
 */
export function changeCountry(properties: ChangeCountryProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  properties.eventLabel !== undefined && properties.eventLabel !== null ?
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel}) :
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "P8tUoYxJa", "3c2845816a44ed661cc9d75643d6657ba807c16e824e6128501408f2ba10163c", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("change_country", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("P8tUoYxJa", "change_country", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("change_country", eventProperties, "P8tUoYxJa", "3c2845816a44ed661cc9d75643d6657ba807c16e824e6128501408f2ba10163c");
    }
    // destination Segment
    Segment.logEvent("change_country", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ChangeLanguageProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string | null | undefined;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * change_language: When switching between languages.
event_label: {{language from}}_{{language to}}
event_location: menu|pop-up|footer
 *
 * When to trigger this event:
 * 1. When selecting a language
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/wv224noP0/trigger/dpnHqNIFZ
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/wv224noP0}
 */
export function changeLanguage(properties: ChangeLanguageProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  properties.eventLabel !== undefined && properties.eventLabel !== null ?
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel}) :
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "wv224noP0", "bbf2fc0eb8adacd3c3695fe1702a2f235869303e8221a92dac5a77deb033f619", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("change_language", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("wv224noP0", "change_language", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("change_language", eventProperties, "wv224noP0", "bbf2fc0eb8adacd3c3695fe1702a2f235869303e8221a92dac5a77deb033f619");
    }
    // destination Segment
    Segment.logEvent("change_language", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface HeaderAccountClickProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string | null | undefined;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * header_account_click: When clicking on the account logo in the header.
event_label: white|transparent
event_location: header
 *
 * When to trigger this event:
 * 1. When clicking on the account logo in the header
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/u1i6ex4HW/trigger/c4TmbDce1
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/u1i6ex4HW}
 */
export function headerAccountClick(properties: HeaderAccountClickProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  properties.eventLabel !== undefined && properties.eventLabel !== null ?
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel}) :
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "u1i6ex4HW", "a05c9a6a106e06819f2f0949e6ecf49e63b2f5ec0f6b3fda773ab862b3377704", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("header_account_click", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("u1i6ex4HW", "header_account_click", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("header_account_click", eventProperties, "u1i6ex4HW", "a05c9a6a106e06819f2f0949e6ecf49e63b2f5ec0f6b3fda773ab862b3377704");
    }
    // destination Segment
    Segment.logEvent("header_account_click", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface HeaderLogoClickProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string | null | undefined;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * header_logo_click: When clicking on the SuitSupply logo in the header.
event_label: white|transparent
event_location: header
 *
 * When to trigger this event:
 * 1. When clicking on the SuitSupply logo in the header
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/vJwt-sKKq/trigger/evtGMEfPQ
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/vJwt-sKKq}
 */
export function headerLogoClick(properties: HeaderLogoClickProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  properties.eventLabel !== undefined && properties.eventLabel !== null ?
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel}) :
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "vJwt-sKKq", "e2c26fc6a222139be0f41cd08397d5c993b1bdb60d3182f288f9af7f56510385", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("header_logo_click", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("vJwt-sKKq", "header_logo_click", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("header_logo_click", eventProperties, "vJwt-sKKq", "e2c26fc6a222139be0f41cd08397d5c993b1bdb60d3182f288f9af7f56510385");
    }
    // destination Segment
    Segment.logEvent("header_logo_click", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface HeaderMinicartClickProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string | null | undefined;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * header_minicart_click: When clicking on the minicart logo in the header.
event_label: white|transparent
event_location: header
 *
 * When to trigger this event:
 * 1. When clicking on the minicart logo in the header
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/Vzc7Bw6Sp/trigger/zaQ-uoQtx
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/Vzc7Bw6Sp}
 */
export function headerMinicartClick(properties: HeaderMinicartClickProperties
  ) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  properties.eventLabel !== undefined && properties.eventLabel !== null ?
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel}) :
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Vzc7Bw6Sp", "e4ab2b6aecdfa3ee447657d0cc9df5e0e022254206dd8f417065b2ac8c02cc49", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("header_minicart_click", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Vzc7Bw6Sp", "header_minicart_click", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("header_minicart_click", eventProperties, "Vzc7Bw6Sp", "e4ab2b6aecdfa3ee447657d0cc9df5e0e022254206dd8f417065b2ac8c02cc49");
    }
    // destination Segment
    Segment.logEvent("header_minicart_click", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface HeaderWishlistClickProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string | null | undefined;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * header_wishlist_click: When clicking on the wishlist logo in the header.
event_label: white|transparent
event_location: header
 *
 * When to trigger this event:
 * 1. When clicking on the wishlist logo in the header
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/1Rx8sEaGk/trigger/1_A8oMR5X
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/1Rx8sEaGk}
 */
export function headerWishlistClick(properties: HeaderWishlistClickProperties
  ) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  properties.eventLabel !== undefined && properties.eventLabel !== null ?
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel}) :
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "1Rx8sEaGk", "2a80c9f323540d34d7928cb9e993a4886dd1fb460ed866820d9deb11f5527143", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("header_wishlist_click", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("1Rx8sEaGk", "header_wishlist_click", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("header_wishlist_click", eventProperties, "1Rx8sEaGk", "2a80c9f323540d34d7928cb9e993a4886dd1fb460ed866820d9deb11f5527143");
    }
    // destination Segment
    Segment.logEvent("header_wishlist_click", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface MenuOpenedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string | null | undefined;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * menu_opened: When clicking on the menu logo in the header.
event_label: white|transparent
event_location: header
 *
 * When to trigger this event:
 * 1. When clicking on the menu hamburger in the header
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/Rh7-qbVFo/trigger/Ici_uz6Uv
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/Rh7-qbVFo}
 */
export function menuOpened(properties: MenuOpenedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  properties.eventLabel !== undefined && properties.eventLabel !== null ?
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel}) :
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Rh7-qbVFo", "33e280758601cf00be6d8db422f6ba6c89d7209c43f6304ce5a6c522c19a4e60", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("menu_opened", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Rh7-qbVFo", "menu_opened", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("menu_opened", eventProperties, "Rh7-qbVFo", "33e280758601cf00be6d8db422f6ba6c89d7209c43f6304ce5a6c522c19a4e60");
    }
    // destination Segment
    Segment.logEvent("menu_opened", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface MenuClickedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string | null | undefined;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * menu_clicked: When selecting an option in the menu

event_label: {{first level shoes, clothes etc}}_{{category}}_{{subcategory}}
example:
1 level: clothing
2 levels: clothing_suits
3 levels: clothing_suits_classic-suits

event_location: menu
 *
 * When to trigger this event:
 * 1. When clicking an item in the menu
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/q034uYqya/trigger/dTt9M_KVq
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/q034uYqya}
 */
export function menuClicked(properties: MenuClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  properties.eventLabel !== undefined && properties.eventLabel !== null ?
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel}) :
    eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "q034uYqya", "735d908d27bfba8a7750ff4a7def22606df67eea6a112ad7259c4aa8935267d6", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("menu_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("q034uYqya", "menu_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("menu_clicked", eventProperties, "q034uYqya", "735d908d27bfba8a7750ff4a7def22606df67eea6a112ad7259c4aa8935267d6");
    }
    // destination Segment
    Segment.logEvent("menu_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FooterClickProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * footer_click: When a link is clicked in the footer.

event_label: {{last path url}} example: returns-refunds.html|suits.
for socials: facebook|tiktok|youtube|instagram
for contact: whatsapp|phone|email|wechat
for China: wechat-qr-official|wechat-miniprogram|weibo|https://www.beian.miit.gov.cn/
 *
 * When to trigger this event:
 * 1. When clicking a link in the footer
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/8E2-KsTj0l/trigger/Pf0yTDV5q
 * 2. When clicking on the social icons
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/8E2-KsTj0l/trigger/HkHP6k1jC
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/8E2-KsTj0l}
 */
export function footerClick(properties: FooterClickProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "8E2-KsTj0l", "c216352b097ccd2ba60426a00240ec7c5a1715745dca8eb4d1b3a1e8faa8351f", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("footer_click", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("8E2-KsTj0l", "footer_click", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("footer_click", eventProperties, "8E2-KsTj0l", "c216352b097ccd2ba60426a00240ec7c5a1715745dca8eb4d1b3a1e8faa8351f");
    }
    // destination Segment
    Segment.logEvent("footer_click", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface NewsletterSubscribeProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * newsletter_subscribe: When clicking on the subscribe to email button

event_label: email_verified|email_error_{{error message}}
 *
 * When to trigger this event:
 * 1. When clicking on the subscribe button
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/_M87doXAKz/trigger/5xFgRsIGI
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/_M87doXAKz}
 */
export function newsletterSubscribe(properties: NewsletterSubscribeProperties
  ) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "_M87doXAKz", "72a1e75394a72b631626ed884606709cc539c87a9a534731f8ada9f6d5d2e3c8", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("newsletter_subscribe", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("_M87doXAKz", "newsletter_subscribe", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("newsletter_subscribe", eventProperties, "_M87doXAKz", "72a1e75394a72b631626ed884606709cc539c87a9a534731f8ada9f6d5d2e3c8");
    }
    // destination Segment
    Segment.logEvent("newsletter_subscribe", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FabricOptionSelectProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  option: OptionValueType;
}
/**
 * fabric_option_select: Firing when the fabric option is clicked.

- cm_option: {{clicked fabric group}}_{{clicked color code}} example: most-popular_black-pure-s110s-wool|black_black-wool-stretch

- event_location: cm_{{category}}_{{subcategory}} example: cm_suits_jacket
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 * @param properties.option: This property denotes the configurator option that the user is presently exploring. It possesses distinct values for each individual configurator.

Black Tie Configurator: This variable indicates the specific item the user is currently selecting within the configurators, such as a jacket, lapel, shirt, or trousers.

Custom Made: This term describes the selected option and the corresponding outcome that was chosen.
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/yhrlHOrZQ}
 */
export function fabricOptionSelect(properties: FabricOptionSelectProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "option", value: properties.option});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "yhrlHOrZQ", "af1582d0c50475d231bb4b519720f54b59580aeb3ba0beaa2ab13dde52d4af2a", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("fabric_option_select", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("yhrlHOrZQ", "fabric_option_select", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("fabric_option_select", eventProperties, "yhrlHOrZQ", "af1582d0c50475d231bb4b519720f54b59580aeb3ba0beaa2ab13dde52d4af2a");
    }
    // destination Segment
    Segment.logEvent("fabric_option_select", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FabricOptionSelectCmProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  option: OptionValueType;
}
/**
 * fabric_option_select: Firing when the fabric option is clicked.

- cm_option: {{clicked fabric group}}_{{clicked color code}} example: most-popular_black-pure-s110s-wool|black_black-wool-stretch

- event_location: cm_{{category}}_{{subcategory}} example: cm_suits_jacket, variant description: Firing when the fabric option is clicked.

- cm_option: {{clicked fabric group}}_{{clicked color code}} example: most-popular_black-pure-s110s-wool|black_black-wool-stretch

- event_location: cm_{{category}}_{{subcategory}} example: cm_suits_jacket
 *
 * When to trigger this event:
 * 1. When a fabric is selected
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/yhrlHOrZQ.DN8RxOWJq/trigger/Nubt3kVnxT
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 * @param properties.option: This property denotes the configurator option that the user is presently exploring. It possesses distinct values for each individual configurator.

Black Tie Configurator: This variable indicates the specific item the user is currently selecting within the configurators, such as a jacket, lapel, shirt, or trousers.

Custom Made: This term describes the selected option and the corresponding outcome that was chosen.
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/yhrlHOrZQ.DN8RxOWJq}
 */
export function fabricOptionSelectCm(
  properties: FabricOptionSelectCmProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "option", value: properties.option});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "yhrlHOrZQ.DN8RxOWJq", "64148324b305bfb6e1fde481fa75ff5da7b79378f4c0778c099002de119425d8", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("fabric_option_select", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("yhrlHOrZQ.DN8RxOWJq", "fabric_option_select", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("fabric_option_select", eventProperties, "yhrlHOrZQ.DN8RxOWJq", "64148324b305bfb6e1fde481fa75ff5da7b79378f4c0778c099002de119425d8");
    }
    // destination Segment
    Segment.logEvent("fabric_option_select", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FabricOptionConfirmProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  option: OptionValueType;
}
/**
 * fabric_option_confirm: Firing when the fabric option is confirmed.

- cm_option: {{clicked fabric group}}_{{clicked color code}} example: most-popular_black-pure-s110s-wool|black_black-wool-stretch

- event_location: cm_{{category}}_{{subcategory}} example: cm_suits_jacket
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 * @param properties.option: This property denotes the configurator option that the user is presently exploring. It possesses distinct values for each individual configurator.

Black Tie Configurator: This variable indicates the specific item the user is currently selecting within the configurators, such as a jacket, lapel, shirt, or trousers.

Custom Made: This term describes the selected option and the corresponding outcome that was chosen.
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/kCtNIDRn2}
 */
export function fabricOptionConfirm(properties: FabricOptionConfirmProperties
  ) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "option", value: properties.option});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "kCtNIDRn2", "0ca388bb7f6b2f6cd650ebb1def21d2cd5f73906a0a2309936a7d1a46ee09723", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("fabric_option_confirm", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("kCtNIDRn2", "fabric_option_confirm", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("fabric_option_confirm", eventProperties, "kCtNIDRn2", "0ca388bb7f6b2f6cd650ebb1def21d2cd5f73906a0a2309936a7d1a46ee09723");
    }
    // destination Segment
    Segment.logEvent("fabric_option_confirm", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FabricOptionConfirmCmProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  option: OptionValueType;
}
/**
 * fabric_option_confirm: Firing when the fabric option is confirmed.

- cm_option: {{clicked fabric group}}_{{clicked color code}} example: most-popular_black-pure-s110s-wool|black_black-wool-stretch

- event_location: cm_{{category}}_{{subcategory}} example: cm_suits_jacket, variant description: Firing when the fabric option is confirmed.

- cm_option: {{clicked fabric group}}_{{clicked color code}} example: most-popular_black-pure-s110s-wool|black_black-wool-stretch

- event_location: cm_{{category}}_{{subcategory}} example: cm_suits_jacket
 *
 * When to trigger this event:
 * 1. When a fabric option is confirmed
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/kCtNIDRn2.HYxFESfRt/trigger/OB3YWK1aGb
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 * @param properties.option: This property denotes the configurator option that the user is presently exploring. It possesses distinct values for each individual configurator.

Black Tie Configurator: This variable indicates the specific item the user is currently selecting within the configurators, such as a jacket, lapel, shirt, or trousers.

Custom Made: This term describes the selected option and the corresponding outcome that was chosen.
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/kCtNIDRn2.HYxFESfRt}
 */
export function fabricOptionConfirmCm(
  properties: FabricOptionConfirmCmProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "option", value: properties.option});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "kCtNIDRn2.HYxFESfRt", "9eba95d8723a12f63f555257c195203d7bb4fd7ea7f9c04a02be2ef8d0936b86", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("fabric_option_confirm", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("kCtNIDRn2.HYxFESfRt", "fabric_option_confirm", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("fabric_option_confirm", eventProperties, "kCtNIDRn2.HYxFESfRt", "9eba95d8723a12f63f555257c195203d7bb4fd7ea7f9c04a02be2ef8d0936b86");
    }
    // destination Segment
    Segment.logEvent("fabric_option_confirm", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface StyleOptionConfirmProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  option: OptionValueType;
}
/**
 * style_option_confirm: Firing when the style option is selected.

- cm_option:{{selected option}} example: business|casual|classic-double-breasted

- event_location: cm_{{category}}_{{subcategory}} example: cm_suits_jacket
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 * @param properties.option: This property denotes the configurator option that the user is presently exploring. It possesses distinct values for each individual configurator.

Black Tie Configurator: This variable indicates the specific item the user is currently selecting within the configurators, such as a jacket, lapel, shirt, or trousers.

Custom Made: This term describes the selected option and the corresponding outcome that was chosen.
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/yhBSr917Q}
 */
export function styleOptionConfirm(properties: StyleOptionConfirmProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "option", value: properties.option});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "yhBSr917Q", "46dc48eb8ac72f379c32b6eed9dcf909724942b40eec23e668cb1666c96a9dae", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("style_option_confirm", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("yhBSr917Q", "style_option_confirm", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("style_option_confirm", eventProperties, "yhBSr917Q", "46dc48eb8ac72f379c32b6eed9dcf909724942b40eec23e668cb1666c96a9dae");
    }
    // destination Segment
    Segment.logEvent("style_option_confirm", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface StyleOptionConfirmCmProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  option: OptionValueType;
}
/**
 * style_option_confirm: Firing when the style option is selected.

- cm_option:{{selected option}} example: business|casual|classic-double-breasted

- event_location: cm_{{category}}_{{subcategory}} example: cm_suits_jacket, variant description: Firing when the style option is selected.

- cm_option:{{selected option}} example: business|casual|classic-double-breasted

- event_location: cm_{{category}}_{{subcategory}} example: cm_suits_jacket
 *
 * When to trigger this event:
 * 1. When a style is confirmed
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/yhBSr917Q.hGFiz-Dxi/trigger/m34JLfPpg4g
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 * @param properties.option: This property denotes the configurator option that the user is presently exploring. It possesses distinct values for each individual configurator.

Black Tie Configurator: This variable indicates the specific item the user is currently selecting within the configurators, such as a jacket, lapel, shirt, or trousers.

Custom Made: This term describes the selected option and the corresponding outcome that was chosen.
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/yhBSr917Q.hGFiz-Dxi}
 */
export function styleOptionConfirmCm(
  properties: StyleOptionConfirmCmProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "option", value: properties.option});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "yhBSr917Q.hGFiz-Dxi", "d0ed890fe6e2206e33421afd6ad4b882a8bb60dc25a77506b21685a7fb20c83a", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("style_option_confirm", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("yhBSr917Q.hGFiz-Dxi", "style_option_confirm", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("style_option_confirm", eventProperties, "yhBSr917Q.hGFiz-Dxi", "d0ed890fe6e2206e33421afd6ad4b882a8bb60dc25a77506b21685a7fb20c83a");
    }
    // destination Segment
    Segment.logEvent("style_option_confirm", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SummaryEditProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  option: OptionValueType;
}
/**
 * summary_edit: When clicking on 'edit' icon on the summary screen

- event_location: {{category}}_summary -> example cm_suits_summary
- option: jacket|trousers|waistcoat

 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 * @param properties.option: This property denotes the configurator option that the user is presently exploring. It possesses distinct values for each individual configurator.

Black Tie Configurator: This variable indicates the specific item the user is currently selecting within the configurators, such as a jacket, lapel, shirt, or trousers.

Custom Made: This term describes the selected option and the corresponding outcome that was chosen.
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/KNnHKBbfV}
 */
export function summaryEdit(properties: SummaryEditProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "option", value: properties.option});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "KNnHKBbfV", "72a7b56e049a88991e1e8d5ca4bb2928a25f4c196c9fa59555ff32dfe491cbcf", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("summary_edit", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("KNnHKBbfV", "summary_edit", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("summary_edit", eventProperties, "KNnHKBbfV", "72a7b56e049a88991e1e8d5ca4bb2928a25f4c196c9fa59555ff32dfe491cbcf");
    }
    // destination Segment
    Segment.logEvent("summary_edit", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SummaryEditBtcProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  option: OptionValueType;
}
/**
 * summary_edit: When clicking on 'edit' icon on the summary screen

- event_location: {{category}}_summary -> example cm_suits_summary
- option: jacket|trousers|waistcoat, variant description: When clicking on 'edit' icon on the summary screen

- event_location: {{category}}_summary example suits_summary
 *
 * When to trigger this event:
 * 1. When clicking 'edit' icon on summary screen
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/KNnHKBbfV.VD6o7DM5f/trigger/Of6Lrt9RgZZ
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 * @param properties.option: This property denotes the configurator option that the user is presently exploring. It possesses distinct values for each individual configurator.

Black Tie Configurator: This variable indicates the specific item the user is currently selecting within the configurators, such as a jacket, lapel, shirt, or trousers.

Custom Made: This term describes the selected option and the corresponding outcome that was chosen.
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/KNnHKBbfV.VD6o7DM5f}
 */
export function summaryEditBtc(properties: SummaryEditBtcProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "option", value: properties.option});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "KNnHKBbfV.VD6o7DM5f", "da7bae00788e12b5c3454463debf06d9d0bbef0b584de8cf3217f071f3d0d099", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("summary_edit", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("KNnHKBbfV.VD6o7DM5f", "summary_edit", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("summary_edit", eventProperties, "KNnHKBbfV.VD6o7DM5f", "da7bae00788e12b5c3454463debf06d9d0bbef0b584de8cf3217f071f3d0d099");
    }
    // destination Segment
    Segment.logEvent("summary_edit", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface StyleOptionChangeProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  option: OptionValueType;
}
/**
 * style_option_change: Firing when the style change/edit is selected.

- cm_option:{{selected option}} example: business|casual|classic-double-breasted

- event_location: {{category}}_{{subcategory}} example: cm_suits_jacket
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 * @param properties.option: This property denotes the configurator option that the user is presently exploring. It possesses distinct values for each individual configurator.

Black Tie Configurator: This variable indicates the specific item the user is currently selecting within the configurators, such as a jacket, lapel, shirt, or trousers.

Custom Made: This term describes the selected option and the corresponding outcome that was chosen.
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/RxyJeDhDH}
 */
export function styleOptionChange(properties: StyleOptionChangeProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "option", value: properties.option});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "RxyJeDhDH", "77a53182494c90349802a5113efa7e1b1dc05cc91c6ad8c8654e1e73a377af3f", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("style_option_change", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("RxyJeDhDH", "style_option_change", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("style_option_change", eventProperties, "RxyJeDhDH", "77a53182494c90349802a5113efa7e1b1dc05cc91c6ad8c8654e1e73a377af3f");
    }
    // destination Segment
    Segment.logEvent("style_option_change", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface StyleOptionChangeCmProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  option: OptionValueType;
}
/**
 * style_option_change: Firing when the style change/edit is selected.

- cm_option:{{selected option}} example: business|casual|classic-double-breasted

- event_location: {{category}}_{{subcategory}} example: cm_suits_jacket, variant description: Firing when the style change/edit is selected.

- cm_option:{{selected option}} example: business|casual|classic-double-breasted

- event_location: {{category}}_{{subcategory}} example: cm_suits_jacket
 *
 * When to trigger this event:
 * 1. When the option to change the style is clicked
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/RxyJeDhDH.S_cLcXx5U/trigger/TA80z2P5Z5
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 * @param properties.option: This property denotes the configurator option that the user is presently exploring. It possesses distinct values for each individual configurator.

Black Tie Configurator: This variable indicates the specific item the user is currently selecting within the configurators, such as a jacket, lapel, shirt, or trousers.

Custom Made: This term describes the selected option and the corresponding outcome that was chosen.
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/RxyJeDhDH.S_cLcXx5U}
 */
export function styleOptionChangeCm(properties: StyleOptionChangeCmProperties
  ) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "option", value: properties.option});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "RxyJeDhDH.S_cLcXx5U", "919ae85e12d2f0d9ca963236f365a0714e8fbd8bbb6c655b97e669112d12a852", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("style_option_change", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("RxyJeDhDH.S_cLcXx5U", "style_option_change", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("style_option_change", eventProperties, "RxyJeDhDH.S_cLcXx5U", "919ae85e12d2f0d9ca963236f365a0714e8fbd8bbb6c655b97e669112d12a852");
    }
    // destination Segment
    Segment.logEvent("style_option_change", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface StyleOptionChangeConfirmProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  option: OptionValueType;
}
/**
 * style_option_change_confirm: Firing when the pop up 'change style' is clicked.

- cm_option: {{selected option}} example: business|casual|classic-double-breasted

- event_location: cm_{{category}}_{{subcategory}} example: cm_suits_jacket
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 * @param properties.option: This property denotes the configurator option that the user is presently exploring. It possesses distinct values for each individual configurator.

Black Tie Configurator: This variable indicates the specific item the user is currently selecting within the configurators, such as a jacket, lapel, shirt, or trousers.

Custom Made: This term describes the selected option and the corresponding outcome that was chosen.
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/EvgoQxQSE}
 */
export function styleOptionChangeConfirm(
  properties: StyleOptionChangeConfirmProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "option", value: properties.option});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "EvgoQxQSE", "b479ed4adb1780e0e74ba79b3c685afe4d36bb057cf7033ea8067d8f50d2619d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("style_option_change_confirm", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("EvgoQxQSE", "style_option_change_confirm", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("style_option_change_confirm", eventProperties, "EvgoQxQSE", "b479ed4adb1780e0e74ba79b3c685afe4d36bb057cf7033ea8067d8f50d2619d");
    }
    // destination Segment
    Segment.logEvent("style_option_change_confirm", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface StyleOptionChangeConfirmCmProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  option: OptionValueType;
}
/**
 * style_option_change_confirm: Firing when the pop up 'change style' is clicked.

- cm_option: {{selected option}} example: business|casual|classic-double-breasted

- event_location: cm_{{category}}_{{subcategory}} example: cm_suits_jacket, variant description: Firing when the pop up 'change style' is clicked.

- cm_option: {{selected option}} example: business|casual|classic-double-breasted

- event_location: cm_{{category}}_{{subcategory}} example: cm_suits_jacket
 *
 * When to trigger this event:
 * 1. When the option to change the style is confirmed
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/EvgoQxQSE.EF8pKryBB/trigger/ewMRGOdHwg
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 * @param properties.option: This property denotes the configurator option that the user is presently exploring. It possesses distinct values for each individual configurator.

Black Tie Configurator: This variable indicates the specific item the user is currently selecting within the configurators, such as a jacket, lapel, shirt, or trousers.

Custom Made: This term describes the selected option and the corresponding outcome that was chosen.
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/EvgoQxQSE.EF8pKryBB}
 */
export function styleOptionChangeConfirmCm(
  properties: StyleOptionChangeConfirmCmProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "option", value: properties.option});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "EvgoQxQSE.EF8pKryBB", "80a5013d2bd8d5fc27f5ff94428379ba38753d8b737140d1ef5418d88da2ef81", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("style_option_change_confirm", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("EvgoQxQSE.EF8pKryBB", "style_option_change_confirm", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("style_option_change_confirm", eventProperties, "EvgoQxQSE.EF8pKryBB", "80a5013d2bd8d5fc27f5ff94428379ba38753d8b737140d1ef5418d88da2ef81");
    }
    // destination Segment
    Segment.logEvent("style_option_change_confirm", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface StyleOptionChangeCancelProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  option: OptionValueType;
}
/**
 * style_option_change_cancel: Firing when the pop up 'change style' is cancelled.

- cm_option: {{selected option}} example: business|casual|classic-double-breasted

- event_location: cm_{{category}}_{{subcategory}} example: cm_suits_jacket
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 * @param properties.option: This property denotes the configurator option that the user is presently exploring. It possesses distinct values for each individual configurator.

Black Tie Configurator: This variable indicates the specific item the user is currently selecting within the configurators, such as a jacket, lapel, shirt, or trousers.

Custom Made: This term describes the selected option and the corresponding outcome that was chosen.
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/viJG3zwB_}
 */
export function styleOptionChangeCancel(
  properties: StyleOptionChangeCancelProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "option", value: properties.option});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "viJG3zwB_", "fd40af67aa3dfd9775fe24488fb5d25f708d54c848dba844b5d71f1bd4e41600", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("style_option_change_cancel", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("viJG3zwB_", "style_option_change_cancel", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("style_option_change_cancel", eventProperties, "viJG3zwB_", "fd40af67aa3dfd9775fe24488fb5d25f708d54c848dba844b5d71f1bd4e41600");
    }
    // destination Segment
    Segment.logEvent("style_option_change_cancel", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface StyleOptionChangeCancelCmProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  option: OptionValueType;
}
/**
 * style_option_change_cancel: Firing when the pop up 'change style' is cancelled.

- cm_option: {{selected option}} example: business|casual|classic-double-breasted

- event_location: cm_{{category}}_{{subcategory}} example: cm_suits_jacket, variant description: Firing when the pop up 'change style' is cancelled.

- cm_option: {{selected option}} example: business|casual|classic-double-breasted

- event_location: cm_{{category}}_{{subcategory}} example: cm_suits_jacket
 *
 * When to trigger this event:
 * 1. When the option to change the style is cancelled
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/viJG3zwB_.vx6zOys-N/trigger/OHfcR6tqbI
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 * @param properties.option: This property denotes the configurator option that the user is presently exploring. It possesses distinct values for each individual configurator.

Black Tie Configurator: This variable indicates the specific item the user is currently selecting within the configurators, such as a jacket, lapel, shirt, or trousers.

Custom Made: This term describes the selected option and the corresponding outcome that was chosen.
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/viJG3zwB_.vx6zOys-N}
 */
export function styleOptionChangeCancelCm(
  properties: StyleOptionChangeCancelCmProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "option", value: properties.option});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "viJG3zwB_.vx6zOys-N", "ff2c178aac3d83a1350875fc1e6ead41ec1e20a1e0e4d8ef72a8fe801f7aee25", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("style_option_change_cancel", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("viJG3zwB_.vx6zOys-N", "style_option_change_cancel", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("style_option_change_cancel", eventProperties, "viJG3zwB_.vx6zOys-N", "ff2c178aac3d83a1350875fc1e6ead41ec1e20a1e0e4d8ef72a8fe801f7aee25");
    }
    // destination Segment
    Segment.logEvent("style_option_change_cancel", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface OptionSelectProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string | null | undefined;
  option: OptionValueType;
  sku: string;
}
/**
 * option_select: This event is fired when the user clicks on an option within a step in the configurator so we can see how many times the user change the options before confirming to the next step.
option: {{SKU}}
event_location: btc_suits_bow-tie {{to be confirmed}}


-----
btc_option isn't name in this case since a lot of bowties have the same name (for example Black Self-Tied, therefor we use the SKU in the btc_option

 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 * @param properties.option: This property denotes the configurator option that the user is presently exploring. It possesses distinct values for each individual configurator.

Black Tie Configurator: This variable indicates the specific item the user is currently selecting within the configurators, such as a jacket, lapel, shirt, or trousers.

Custom Made: This term describes the selected option and the corresponding outcome that was chosen.
 * @param properties.sku: SKU of product, same as product_id
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/2yi_RMQ3b}
 */
export function optionSelect(properties: OptionSelectProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  properties.eventLocation !== undefined && properties.eventLocation !== null ?
    eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation}) :
    eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: null});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "option", value: properties.option});
  eventPropertiesArray.push({id: "Yf36kEtqN", name: "sku", value: properties.sku});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "2yi_RMQ3b", "ca70b0babe4dd707891163859d4679e5bd3cb4469ccc3dfb6b0ed29230bce009", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("option_select", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("2yi_RMQ3b", "option_select", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("option_select", eventProperties, "2yi_RMQ3b", "ca70b0babe4dd707891163859d4679e5bd3cb4469ccc3dfb6b0ed29230bce009");
    }
    // destination Segment
    Segment.logEvent("option_select", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface OptionSelectBtcProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string | null | undefined;
  option: OptionValueType;
  sku: string;
}
/**
 * option_select: This event is fired when the user clicks on an option within a step in the configurator so we can see how many times the user change the options before confirming to the next step.
option: {{SKU}}
event_location: btc_suits_bow-tie {{to be confirmed}}


-----
btc_option isn't name in this case since a lot of bowties have the same name (for example Black Self-Tied, therefor we use the SKU in the btc_option, variant description: This event represents user interactions with the options displayed in the configurator. It is utilized to monitor the frequency with which users switch between options within the same step.
 *
 * When to trigger this event:
 * 1. When the user clicks in one of the option within a step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/2yi_RMQ3b.rcb34uNta/trigger/nCfT_05Oab4
 * 2. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/2yi_RMQ3b.rcb34uNta/trigger/zCso_mfT8ky
 * 3. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/2yi_RMQ3b.rcb34uNta/trigger/2SqN0t48Wwe
 * 4. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/2yi_RMQ3b.rcb34uNta/trigger/4BAK_Wdtv1P
 * 5. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/2yi_RMQ3b.rcb34uNta/trigger/XoQ1RXEJiuk
 * 6. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/2yi_RMQ3b.rcb34uNta/trigger/TvHV6ONrV1Q
 * 7. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/2yi_RMQ3b.rcb34uNta/trigger/RoDh1PdsSex
 * 8. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/2yi_RMQ3b.rcb34uNta/trigger/4QvWjZAYH2_
 * 9. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/2yi_RMQ3b.rcb34uNta/trigger/n1HK9m8xnik
 * 10. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/2yi_RMQ3b.rcb34uNta/trigger/mXff69r_uDF
 * 11. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/2yi_RMQ3b.rcb34uNta/trigger/IVJD4Jwpizf
 * 12. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/2yi_RMQ3b.rcb34uNta/trigger/s0UQ0XxRDgP
 * 13. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/2yi_RMQ3b.rcb34uNta/trigger/3r_gyfPaOuz
 * 14. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/2yi_RMQ3b.rcb34uNta/trigger/GlU7Fky6r
 * 15. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/2yi_RMQ3b.rcb34uNta/trigger/VlwlB9jRa
 * 16. When the user selects an option within the step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/2yi_RMQ3b.rcb34uNta/trigger/0zXJbUEpK
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 * @param properties.option: This property denotes the configurator option that the user is presently exploring. It possesses distinct values for each individual configurator.

Black Tie Configurator: This variable indicates the specific item the user is currently selecting within the configurators, such as a jacket, lapel, shirt, or trousers.

Custom Made: This term describes the selected option and the corresponding outcome that was chosen.
 * @param properties.sku: SKU of product, same as product_id
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/2yi_RMQ3b.rcb34uNta}
 */
export function optionSelectBtc(properties: OptionSelectBtcProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  properties.eventLocation !== undefined && properties.eventLocation !== null ?
    eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation}) :
    eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: null});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "option", value: properties.option});
  eventPropertiesArray.push({id: "Yf36kEtqN", name: "sku", value: properties.sku});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "2yi_RMQ3b.rcb34uNta", "015b56ed967ca3d5b7de34ce7e13924a2af8f401fce3bfba0695f0fa719d41c8", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("option_select", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("2yi_RMQ3b.rcb34uNta", "option_select", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("option_select", eventProperties, "2yi_RMQ3b.rcb34uNta", "015b56ed967ca3d5b7de34ce7e13924a2af8f401fce3bfba0695f0fa719d41c8");
    }
    // destination Segment
    Segment.logEvent("option_select", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PreviousStepClickedProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * previous_step_clicked: When clicking on the previous arrow in the configurator
event_category: interactions
event_location: {{category}}_{{subcategory}} example: suits_jacket_lapel
 *
 * When to trigger this event:
 * 1.
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/nIjDUsmcwn/trigger/ySRM6FVek
 * 2.
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/nIjDUsmcwn/trigger/Cq9EZLjcQ
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/nIjDUsmcwn}
 */
export function previousStepClicked(properties: PreviousStepClickedProperties
  ) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "nIjDUsmcwn", "e84084d634fc6df161339095bee146f088efd51d4e85a6feafcbaa70aac9c8ee", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("previous_step_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("nIjDUsmcwn", "previous_step_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("previous_step_clicked", eventProperties, "nIjDUsmcwn", "e84084d634fc6df161339095bee146f088efd51d4e85a6feafcbaa70aac9c8ee");
    }
    // destination Segment
    Segment.logEvent("previous_step_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface NextStepClickProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * next_step_click: When clicking on the next arrow in the configurator
event_category: interactions
event_location: {{category}}_{{subcategory}} example: suits_jacket_lapel
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/7pxnr-tZL}
 */
export function nextStepClick(properties: NextStepClickProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "7pxnr-tZL", "4bdb1a1c14cac209ccf11af7462b06bf1840d80e1dbaa2536347a62b3e564991", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("next_step_click", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("7pxnr-tZL", "next_step_click", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("next_step_click", eventProperties, "7pxnr-tZL", "4bdb1a1c14cac209ccf11af7462b06bf1840d80e1dbaa2536347a62b3e564991");
    }
    // destination Segment
    Segment.logEvent("next_step_click", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface NextStepClickBtcProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * next_step_click: When clicking on the next arrow in the configurator
event_category: interactions
event_location: {{category}}_{{subcategory}} example: suits_jacket_lapel, variant description: when clicking on next arrow in configurator in the Black Tie Configurator
event_category: btc_interactions
event_location: {{category}}_{{subcategory}} example: suits_jacket_lapel
 *
 * When to trigger this event:
 * 1. When the user clicks in the "Finish look" button
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/7pxnr-tZL.wk_dh8jyE/trigger/TrZeotyovI
 * 2. When the user clicks in the "Finish look" button
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/7pxnr-tZL.wk_dh8jyE/trigger/fHpDpdXrR
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/7pxnr-tZL.wk_dh8jyE}
 */
export function nextStepClickBtc(properties: NextStepClickBtcProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "7pxnr-tZL.wk_dh8jyE", "94840fb48b260e31c4ce04741177ff6a9377715edef6d7bab150687ebf9d7f8a", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("next_step_click", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("7pxnr-tZL.wk_dh8jyE", "next_step_click", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("next_step_click", eventProperties, "7pxnr-tZL.wk_dh8jyE", "94840fb48b260e31c4ce04741177ff6a9377715edef6d7bab150687ebf9d7f8a");
    }
    // destination Segment
    Segment.logEvent("next_step_click", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SizeGuideClickProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  option: OptionValueType;
}
/**
 * size_guide_click: When clicking on the 'size guide' button

btc_option: {{SKU}}
event_location: example: btc_suits_shirt
 *
 * When to trigger this event:
 * 1. When clicking 'Size guide' button
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/-AnVCYt0T/trigger/xC8kgIxIk
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 * @param properties.option: This property denotes the configurator option that the user is presently exploring. It possesses distinct values for each individual configurator.

Black Tie Configurator: This variable indicates the specific item the user is currently selecting within the configurators, such as a jacket, lapel, shirt, or trousers.

Custom Made: This term describes the selected option and the corresponding outcome that was chosen.
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/-AnVCYt0T}
 */
export function sizeGuideClick(properties: SizeGuideClickProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "option", value: properties.option});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "-AnVCYt0T", "4a0a2bc13fd0e57334a6754951bbade58203da8a35e0b2a6351c483a371576f5", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("size_guide_click", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("-AnVCYt0T", "size_guide_click", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("size_guide_click", eventProperties, "-AnVCYt0T", "4a0a2bc13fd0e57334a6754951bbade58203da8a35e0b2a6351c483a371576f5");
    }
    // destination Segment
    Segment.logEvent("size_guide_click", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SizeConfirmProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  option: OptionValueType;
}
/**
 * size_confirm: When clicking on the 'confirm' button

btc_option: {{SKU}}
event_location: example: btc_suits_shirt
 *
 * When to trigger this event:
 * 1. When clicking 'confirm' button
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/y80nFRNre/trigger/YUSb5ZlpJ
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 * @param properties.option: This property denotes the configurator option that the user is presently exploring. It possesses distinct values for each individual configurator.

Black Tie Configurator: This variable indicates the specific item the user is currently selecting within the configurators, such as a jacket, lapel, shirt, or trousers.

Custom Made: This term describes the selected option and the corresponding outcome that was chosen.
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/y80nFRNre}
 */
export function sizeConfirm(properties: SizeConfirmProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "option", value: properties.option});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "y80nFRNre", "24eefe169a58a4c560ad8e91c7926d0c237804da93dbf3d8049ebfba147e871a", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("size_confirm", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("y80nFRNre", "size_confirm", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("size_confirm", eventProperties, "y80nFRNre", "24eefe169a58a4c560ad8e91c7926d0c237804da93dbf3d8049ebfba147e871a");
    }
    // destination Segment
    Segment.logEvent("size_confirm", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SliderNextProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * slider_next: When clicking on 'next' on the slider

event_location: category-card_{{category}}
event_location:
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/43HCIUZACc}
 */
export function sliderNext(properties: SliderNextProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "43HCIUZACc", "1fe66c0d4973c0e58ed0676fb134d5f56cb2763e44731f6a6a7dd1fa0ff22b7b", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("slider_next", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("43HCIUZACc", "slider_next", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("slider_next", eventProperties, "43HCIUZACc", "1fe66c0d4973c0e58ed0676fb134d5f56cb2763e44731f6a6a7dd1fa0ff22b7b");
    }
    // destination Segment
    Segment.logEvent("slider_next", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface OptionConfirmProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  option: OptionValueType;
  sku: string;
}
/**
 * option_confirm: This event triggers when the user clicks the "Next" button within a step of the configurator, allowing us to track which item the user selected.
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 * @param properties.option: This property denotes the configurator option that the user is presently exploring. It possesses distinct values for each individual configurator.

Black Tie Configurator: This variable indicates the specific item the user is currently selecting within the configurators, such as a jacket, lapel, shirt, or trousers.

Custom Made: This term describes the selected option and the corresponding outcome that was chosen.
 * @param properties.sku: SKU of product, same as product_id
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/5UFGR6B8D2Y}
 */
export function optionConfirm(properties: OptionConfirmProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "option", value: properties.option});
  eventPropertiesArray.push({id: "Yf36kEtqN", name: "sku", value: properties.sku});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "5UFGR6B8D2Y", "2add283139d0d659a6f74a5a4e6d501dc15273124940bb1b548be0374fc26ad2", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("option_confirm", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("5UFGR6B8D2Y", "option_confirm", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("option_confirm", eventProperties, "5UFGR6B8D2Y", "2add283139d0d659a6f74a5a4e6d501dc15273124940bb1b548be0374fc26ad2");
    }
    // destination Segment
    Segment.logEvent("option_confirm", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface OptionConfirmBtcProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
  option: OptionValueType;
  sku: string;
}
/**
 * option_confirm: This event triggers when the user clicks the "Next" button within a step of the configurator, allowing us to track which item the user selected., event variant
 *
 * When to trigger this event:
 * 1. This event triggers when the user clicks the "Next" button within a step of the configurator, allowing us to track which item the user selected.
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/5UFGR6B8D2Y.TBlHgH5Qy/trigger/SUQv14u3AQE
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 * @param properties.option: This property denotes the configurator option that the user is presently exploring. It possesses distinct values for each individual configurator.

Black Tie Configurator: This variable indicates the specific item the user is currently selecting within the configurators, such as a jacket, lapel, shirt, or trousers.

Custom Made: This term describes the selected option and the corresponding outcome that was chosen.
 * @param properties.sku: SKU of product, same as product_id
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/5UFGR6B8D2Y.TBlHgH5Qy}
 */
export function optionConfirmBtc(properties: OptionConfirmBtcProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  eventPropertiesArray.push({id: "bEtCdP6Ew", name: "option", value: properties.option});
  eventPropertiesArray.push({id: "Yf36kEtqN", name: "sku", value: properties.sku});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "5UFGR6B8D2Y.TBlHgH5Qy", "0eedc0975ac8e0d5da2eb920fb720bd421373a842571965c59b33f4512cc472e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("option_confirm", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("5UFGR6B8D2Y.TBlHgH5Qy", "option_confirm", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("option_confirm", eventProperties, "5UFGR6B8D2Y.TBlHgH5Qy", "0eedc0975ac8e0d5da2eb920fb720bd421373a842571965c59b33f4512cc472e");
    }
    // destination Segment
    Segment.logEvent("option_confirm", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FinalStepClickProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * final_step_click: When clicking on the next arrow in the configurator
event_category: interactions
event_location: {{category}}_{{subcategory}} example: suits_jacket_lapel
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/65GxEUULi}
 */
export function finalStepClick(properties: FinalStepClickProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "65GxEUULi", "6ce8db681057cb23a7b49ddd7790bedcd59511666cca3433a626a8c8e7291757", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("final_step_click", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("65GxEUULi", "final_step_click", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("final_step_click", eventProperties, "65GxEUULi", "6ce8db681057cb23a7b49ddd7790bedcd59511666cca3433a626a8c8e7291757");
    }
    // destination Segment
    Segment.logEvent("final_step_click", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FinalStepClickBtcProperties {
  currency: CurrencyValueType;
  country: CountryValueType;
  language: LanguageValueType;
  url: string;
  locationId: string;
  userId: string;
  loggedIn: LoggedInValueType;
  pageType: PageTypeValueType;
  gaClientId: string;
  gaSessionId: string;
  gaSessionNumber: string;
  fbFbp: string;
  fbFbc: string;
  gaAwClid: string | null | undefined;
  eventLabel: string;
  eventCategory: EventCategoryValueType;
  eventLocation: string;
}
/**
 * final_step_click: When clicking on the next arrow in the configurator
event_category: interactions
event_location: {{category}}_{{subcategory}} example: suits_jacket_lapel, variant description: when clicking on next arrow in configurator in the Black Tie Configurator
event_category: btc_interactions
event_location: {{category}}_{{subcategory}} example: suits_jacket_lapel
 *
 * When to trigger this event:
 * 1. When user clicks in the next button
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/65GxEUULi.wk_dh8jyE/trigger/_al0WAiNS96
 * 2. When user clicks in the arrow to go to the next step
 * View in Avo: https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/65GxEUULi.wk_dh8jyE/trigger/HnHHzccursz
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency code, ISO standard in lowercase letters
 * @param properties.country: Local country of website, ISO standard, lowercase
 * @param properties.language: language used on page, ISO standard, lowercase
 * @param properties.url: URL without locale
 * @param properties.locationId: Url with locale
 * @param properties.userId: Unique customer ID
 * @param properties.loggedIn: 'true' or 'false'
 * @param properties.pageType: describes the page_type
 * @param properties.gaClientId: gets the client id from the local storage
 * @param properties.gaSessionId: Gets the Google analytics session id from the local storage
 * @param properties.gaSessionNumber: Gets the session number from the local storage
 * @param properties.fbFbp: fbp cookie value
 * @param properties.fbFbc: fbc
 cookie value
 * @param properties.gaAwClid: Google Adwords click id
 * @param properties.eventLabel: Description of event, not always required
 * @param properties.eventCategory: Overall event category
 * @param properties.eventLocation: Describes where event is located on the website. Never applicable to 'ecommerce' events, and

- For wishlist panel: wishlist_panel
- For add to bag panel: minicart_panel
- For OOS panel: oos
- For shop the look panel: 'products_mwtw_{{number}}'
- For mix and match panel: mixandmatch
- for Perfect White shirt finder: pwsf_panel
- For Size Passport: size-passport_panel
- For PDP size guide button: pdp
 *
 * @see {@link https://www.avo.app/schemas/JmwsgKTQe50MTmUE9FVs/branches/RmqHn8A2W/events/65GxEUULi.wk_dh8jyE}
 */
export function finalStepClickBtc(properties: FinalStepClickBtcProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "jhDpoElQU", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "bCDyX8-bZ", name: "country", value: properties.country});
  eventPropertiesArray.push({id: "8iHihZ0rO", name: "language", value: properties.language});
  eventPropertiesArray.push({id: "olJP9CQ0tb", name: "url", value: properties.url});
  eventPropertiesArray.push({id: "memdKZdQJ1", name: "location_id", value: properties.locationId});
  eventPropertiesArray.push({id: "piJkZJx8sqS", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "Kvhizl7kAi", name: "logged_in", value: properties.loggedIn});
  eventPropertiesArray.push({id: "gppkY78PuY", name: "page_type", value: properties.pageType});
  eventPropertiesArray.push({id: "ba9__C5yS", name: "ga_client_id", value: properties.gaClientId});
  eventPropertiesArray.push({id: "sFU-LulvR", name: "ga_session_id", value: properties.gaSessionId});
  eventPropertiesArray.push({id: "QQnci_8-j", name: "ga_session_number", value: properties.gaSessionNumber});
  eventPropertiesArray.push({id: "MlkVULjI2U", name: "fb_fbp", value: properties.fbFbp});
  eventPropertiesArray.push({id: "ftuMAZCD8", name: "fb_fbc", value: properties.fbFbc});
  properties.gaAwClid !== undefined && properties.gaAwClid !== null ?
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: properties.gaAwClid}) :
    eventPropertiesArray.push({id: "tUxjzh1QQ", name: "ga_aw_clid", value: null});
  eventPropertiesArray.push({id: "5mI3k6x6_", name: "event_label", value: properties.eventLabel});
  eventPropertiesArray.push({id: "VdCb01TKRz", name: "event_category", value: properties.eventCategory});
  eventPropertiesArray.push({id: "te07FO5wjg", name: "event_location", value: properties.eventLocation});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "65GxEUULi.wk_dh8jyE", "d3dd126d4ff8a2a69bb3206db5d5ddbd2cf6b72a53f95739b211ce0f6263540e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("final_step_click", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("65GxEUULi.wk_dh8jyE", "final_step_click", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("final_step_click", eventProperties, "65GxEUULi.wk_dh8jyE", "d3dd126d4ff8a2a69bb3206db5d5ddbd2cf6b72a53f95739b211ce0f6263540e");
    }
    // destination Segment
    Segment.logEvent("final_step_click", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export default {
  AvoEnv,
  initAvo,
  avoInspectorApiKey,
  MixAndMatch,
  ItemListName,
  Language,
  PlpGridImages,
  Category,
  LoggedIn,
  EventCategory,
  ListId,
  Country,
  Option,
  Affiliation,
  Brand,
  SsMedium,
  PageType,
  Currency,
  Creative,
  Variant,
  productListViewed,
  productClicked,
  productViewed,
  productAdded,
  pageLoaded,
  pageLoadedVirtualPageView,
  promotionViewed,
  promotionClicked,
  selectSize,
  selectSizeBtc,
  sizeChanged,
  changeCountry,
  changeLanguage,
  headerAccountClick,
  headerLogoClick,
  headerMinicartClick,
  headerWishlistClick,
  menuOpened,
  menuClicked,
  footerClick,
  newsletterSubscribe,
  fabricOptionSelect,
  fabricOptionSelectCm,
  fabricOptionConfirm,
  fabricOptionConfirmCm,
  styleOptionConfirm,
  styleOptionConfirmCm,
  summaryEdit,
  summaryEditBtc,
  styleOptionChange,
  styleOptionChangeCm,
  styleOptionChangeConfirm,
  styleOptionChangeConfirmCm,
  styleOptionChangeCancel,
  styleOptionChangeCancelCm,
  optionSelect,
  optionSelectBtc,
  previousStepClicked,
  nextStepClick,
  nextStepClickBtc,
  sizeGuideClick,
  sizeConfirm,
  sliderNext,
  optionConfirm,
  optionConfirmBtc,
  finalStepClick,
  finalStepClickBtc,
}

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["productListViewed","productClicked","productViewed","productAdded","pageLoaded","pageLoadedVirtualPageView","promotionViewed","promotionClicked","selectSize","selectSizeBtc","sizeChanged","changeCountry","changeLanguage","headerAccountClick","headerLogoClick","headerMinicartClick","headerWishlistClick","menuOpened","menuClicked","footerClick","newsletterSubscribe","fabricOptionSelect","fabricOptionSelectCm","fabricOptionConfirm","fabricOptionConfirmCm","styleOptionConfirm","styleOptionConfirmCm","summaryEdit","summaryEditBtc","styleOptionChange","styleOptionChangeCm","styleOptionChangeConfirm","styleOptionChangeConfirmCm","styleOptionChangeCancel","styleOptionChangeCancelCm","optionSelect","optionSelectBtc","previousStepClicked","nextStepClick","nextStepClickBtc","sizeGuideClick","sizeConfirm","sliderNext","optionConfirm","optionConfirmBtc","finalStepClick","finalStepClickBtc"]
