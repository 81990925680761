import type { Options } from '@contentful/rich-text-react-renderer'
import { INLINES } from '@contentful/rich-text-types'
import style from '@susu/headless-commerce/components/CMSPageSections/TextAndButtonBannerV2/TextAndButtonBannerV2.module.scss'
import PriceInfo from '@susu/headless-commerce/components/PriceInfo/PriceInfo'
import type { Tags } from '@susu/headless-commerce/contexts/tags'
import type {
  ContentfulMetadata,
  PhoneInfoValue,
  Sys,
} from '@susu/headless-commerce/gql/generated/graphql'
import type { CountryConfiguration } from '@susu/headless-commerce/types/CountryConfiguration'
import type { CurrencyConfiguration } from '@susu/headless-commerce/types/CurrencyConfiguration'
import React from 'react'

import { isUndefined } from '@susu/undefined'
import { generateIdentifier } from './identifier'

export const getContentFulTag = (
  contentfulMetadata: Partial<ContentfulMetadata> | undefined,
  id: string,
) => {
  if (isUndefined(contentfulMetadata?.tags)) {
    return undefined
  }
  const contentFulTag = contentfulMetadata?.tags.find((tag) => tag?.id === id)

  if (!contentFulTag) {
    return contentfulMetadata?.tags.find((tag) => tag?.id === 'countryDefault')
  }

  return contentFulTag
}

export async function getQueryTags(countryCode: string): Promise<Tags> {
  return [`country${countryCode.toUpperCase()}`, 'countryDefault']
}
type Entry = Record<string, unknown> & {
  sys: Sys
}

const extractInlineEntries = (entries: Entry[]) => {
  const entryMap = new Map<string, unknown>()
  for (const entry of entries) {
    if (entry && 'sys' in entry) {
      entryMap.set(entry?.sys?.id, entry)
    }
  }
  return entryMap
}

const createRenderNode = (
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  entryMap: Map<any, any>,
  currencyCode: string,
  country: CountryConfiguration,
  currencies: CurrencyConfiguration,
) => {
  return {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    [INLINES.EMBEDDED_ENTRY]: (node: any) => {
      const fieldEntry = entryMap.get(node?.data?.target?.sys?.id)
      const priceEntry = fieldEntry?.pricesCollection?.items.find(
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        (entry: any) => entry.currencyCode === currencyCode,
      )

      return (
        <PriceInfo
          price={priceEntry?.priceValue}
          country={country}
          currencies={currencies}
        />
      )
    },
  }
}

/**
 * Utility function used for creating render node option for price info for Contentful's normal and rich text fields.
 */
export function createRenderNodeForPriceInfo(
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  inlineEntries: any,
  currencyCode: string,
  country: CountryConfiguration,
  currencies: CurrencyConfiguration,
) {
  const entryMap = extractInlineEntries(
    inlineEntries?.links?.entries?.inline ?? [],
  )
  const renderNode = createRenderNode(
    entryMap,
    currencyCode,
    country,
    currencies,
  )

  return {
    renderNode,
  }
}

const createRenderNodeForTitlePriceHelper = (
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  entryMap: Map<any, any>,
  currencyCode: string,
  country: CountryConfiguration,
  currencies: CurrencyConfiguration,
  index: number,
) => {
  return {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    [INLINES.EMBEDDED_ENTRY]: (node: any) => {
      const fieldEntry = entryMap.get(node?.data?.target?.sys?.id)
      const priceEntry = fieldEntry?.pricesCollection?.items.find(
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        (entry: any) => entry.currencyCode === currencyCode,
      )
      index++

      return index === 0 ? (
        <PriceInfo
          price={priceEntry?.priceValue}
          country={country}
          currencies={currencies}
        />
      ) : (
        <sub className={style['textbanner__additional-price']}>
          <PriceInfo
            price={priceEntry?.priceValue}
            country={country}
            currencies={currencies}
          />
        </sub>
      )
    },
  }
}

/**
 * Utility function used for creating render node option for price info for Contentful's normal and rich text fields.
 */
export function createRenderNodeForTitlePrice(
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  inlineEntries: any,
  currencyCode: string,
  country: CountryConfiguration,
  currencies: CurrencyConfiguration,
) {
  const entryMap = extractInlineEntries(
    inlineEntries?.links?.entries?.inline ?? [],
  )
  const renderNode = createRenderNodeForTitlePriceHelper(
    entryMap,
    currencyCode,
    country,
    currencies,
    inlineEntries?.links?.entries?.inline?.indexOf(entryMap),
  )

  return {
    renderNode,
  }
}

/**
 * Utility function used for creating render node options hero banner.
 */
export function createRenderOptionForHeroBanner(
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  inlineEntries: any,
  currencyCode: string,
  country: CountryConfiguration,
  currencies: CurrencyConfiguration,
) {
  const entryMap = extractInlineEntries(
    inlineEntries?.links?.entries?.inline ?? [],
  )
  const renderNode = createRenderNode(
    entryMap,
    currencyCode,
    country,
    currencies,
  )

  const renderText = (text: string) => {
    const lineBreakRegex = /<br\s*\/?>/i // Match <br>, <br/>, or <br />
    const parts = text.split(lineBreakRegex)

    return (
      <span>
        {parts.map((part, index) => (
          <React.Fragment key={generateIdentifier()}>
            {part}
            {index < parts.length - 1 && <br />}
          </React.Fragment>
        ))}
      </span>
    )
  }

  return {
    renderNode,
    renderText,
  }
}

export function createRenderNodeForPhoneNumber(
  phoneNumberInfo: PhoneInfoValue | null,
): Options {
  return {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: () => {
        return (
          <span>
            {phoneNumberInfo?.phoneDisplay ?? phoneNumberInfo?.phoneValue}
          </span>
        )
      },
    },
  }
}

export function createRenderOptionsForICP(icp: string): Options {
  return {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: () => {
        return <span>{icp}</span>
      },
    },
  }
}

export function createRenderOptionsForAccordion() {
  return {
    renderNode: {
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      [INLINES.EMBEDDED_ENTRY]: (node: any) => {
        return <span>{node}</span>
      },
    },
  }
}
