import '@susu/headless-commerce/libs/avo/avo'

import { customer } from '@susu/headless-commerce/contexts/customer'

import type {
  PageLoadedProperties,
  ProductListViewedProperties,
  PromotionClickedProperties,
  PromotionViewedProperties,
} from '@susu/headless-commerce/libs/avo/avo'
import {
  pageLoaded as avoPageLoaded,
  productListViewed,
  promotionClicked,
  promotionViewed,
} from '@susu/headless-commerce/libs/avo/avo'
import { loaded as analyticsLoaded } from '@susu/headless-commerce/libs/segment/avoDestination'
import type { EnrichEventsProps } from '@susu/headless-commerce/libs/segment/utils'
import {
  enrichEvent,
  gaLoaded,
} from '@susu/headless-commerce/libs/segment/utils'
import { isServer } from '@susu/headless-commerce/utils/environment'
import { isUndefined } from '@susu/undefined'

import type { CommonEventProperties } from './segment'

/**
 * Type definition for tracking events list event.
 */
export type TrackingEventsListEvent = {
  /**
   * Name of the event, can be one of several specific properties.
   */
  name:
    | 'PageLoadedProperties'
    | 'PromotionClickedProperties'
    | 'ProductListViewedProperties'
    | 'PromotionViewedProperties'

  /**
   * Event data corresponding to the event name.
   */
  event: {
    enrichProps: EnrichEventsProps
    properties:
      | Omit<PageLoadedProperties, keyof CommonEventProperties>
      | Omit<PromotionClickedProperties, keyof CommonEventProperties>
      | Omit<ProductListViewedProperties, keyof CommonEventProperties>
      | Omit<PromotionViewedProperties, keyof CommonEventProperties>
  }
}

/**
 * List of tracking events to be sent.
 */
export let trackingEventsList: Array<TrackingEventsListEvent> = []

/**
 * Flag to indicate if the PageLoadedProperties event has been sent.
 */
export let hasPageLoadedBeenSent = false

/**
 * Function to sort events by putting the PageLoadedProperties event first.
 * @param a The first tracking event.
 * @param b The second tracking event.
 * @returns A negative number if a is 'PageLoadedProperties', positive if b is 'PageLoadedProperties', or 0 otherwise.
 */
export const sortPageLoadedEventFirst = (
  a: TrackingEventsListEvent,
  b: TrackingEventsListEvent,
) => {
  if (a.name === 'PageLoadedProperties') {
    return -1
  }
  if (b.name === 'PageLoadedProperties') {
    return 1
  }
  return 0
}

/**
 * Function to send tracking events to the analytics service.
 * Ensures customer, analytics and GA are loaded before sending.
 * Sends the events in the trackingEventsList and clears the list afterwards.
 */
export const sendTrackingEvents = (
  events: Array<TrackingEventsListEvent>,
  pageLoadedSent: boolean,
  avoPageLoaded: (properties: PageLoadedProperties) => void,
  promotionViewed: (properties: PromotionViewedProperties) => void,
  promotionClicked: (properties: PromotionClickedProperties) => void,
  productListViewed: (properties: ProductListViewedProperties) => void,
): [Array<TrackingEventsListEvent>, boolean] => {
  if (
    isUndefined(customer.value) ||
    analyticsLoaded.value !== true ||
    gaLoaded.value !== true
  ) {
    return [events, pageLoadedSent]
  }

  let newPageLoadedSent = false
  let sortedTrackingEventsList: typeof events = events
  if (!pageLoadedSent) {
    const hasPageLoaded = events.some(
      (event) => event.name === 'PageLoadedProperties',
    )
    if (!hasPageLoaded) {
      return [events, false]
    }
    sortedTrackingEventsList = events.sort(sortPageLoadedEventFirst)
    newPageLoadedSent = true
  }

  for (const event of sortedTrackingEventsList) {
    const enrichedEvent = enrichEvent(
      event.event.enrichProps,
      event.event.properties,
    )

    switch (event.name) {
      case 'PageLoadedProperties':
        avoPageLoaded(enrichedEvent as PageLoadedProperties)
        break
      case 'PromotionViewedProperties':
        promotionViewed(enrichedEvent as PromotionViewedProperties)
        break
      case 'PromotionClickedProperties':
        promotionClicked(enrichedEvent as PromotionClickedProperties)
        break
      case 'ProductListViewedProperties':
        productListViewed(enrichedEvent as ProductListViewedProperties)
        break
    }
  }

  return [[], newPageLoadedSent]
}

/**
 * Function to queue a tracking event if not on the server.
 * Adds the event to the trackingEventsList and triggers sending of events.
 * @param name The name of the tracking event.
 * @param event The event data.
 */
export const queueTrackingEvent = async (
  name: TrackingEventsListEvent['name'],
  event: TrackingEventsListEvent['event'],
) => {
  if (isServer()) {
    return
  }

  trackingEventsList.push({
    name,
    event,
  })
  ;[trackingEventsList, hasPageLoadedBeenSent] = sendTrackingEvents(
    trackingEventsList,
    hasPageLoadedBeenSent,
    avoPageLoaded,
    promotionViewed,
    promotionClicked,
    productListViewed,
  )
}
