'use client'

import {
  useComputed,
  useSignal,
  useSignals,
} from '@preact/signals-react/runtime'
import ExternalLink from '@susu/headless-commerce/components/ExternalLink/ExternalLink'
import { cart } from '@susu/headless-commerce/contexts/cart'
import { useCountry } from '@susu/headless-commerce/contexts/country/client'
import { useHeader } from '@susu/headless-commerce/contexts/header/client'
import { useLocale } from '@susu/headless-commerce/contexts/locale/client'
import type {
  CustomMadeLineItem,
  GiftCertificateItem,
  ProductLineItem,
} from '@susu/headless-commerce/gql/generated/graphql'
import { usePageType } from '@susu/headless-commerce/hooks/usePageType'
import { getCartTotalItems } from '@susu/headless-commerce/utils/cart'
import { URLData } from '@susu/headless-commerce/utils/tracking/segment'
import { trackEvent } from '@susu/headless-commerce/utils/tracking/tracking'
import log from '@susu/log'
import type { ReactNode } from 'react'
import { useCallback } from 'react'

export type ClientBagButtonProps = {
  children?: ReactNode
}

export default function ClientBagButton({
  children,
}: Readonly<ClientBagButtonProps>) {
  useSignals()

  const pageType = usePageType()
  const country = useSignal(useCountry())
  const locale = useSignal(useLocale())
  const { headerTrackingValue, segmentEventLabel } = useHeader()

  const handleClick = useCallback(async () => {
    const { url, locationId } = URLData()
    await trackEvent({
      ga: {
        eventCategory: 'Global_Interactions',
        eventAction: 'Minicart_Header_Click',
        eventLabel: headerTrackingValue,
      },
      segment: {
        event: 'headerMinicartClick',
        enrichProps: {
          locale: locale.value,
          country: country.value,
        },
        properties: {
          pageType,
          eventLabel: segmentEventLabel,
          eventCategory: 'global_interactions',
          eventLocation: 'header',
          url,
          locationId,
        },
      },
    })
  }, [country, headerTrackingValue, locale, pageType, segmentEventLabel])

  const countInputProps = useComputed(() => {
    const cartCount = getCartTotalItems(
      (cart.value?.lineItems ?? []) as Array<
        CustomMadeLineItem | ProductLineItem
      >,
      (cart.value?.giftCertificateItems ?? []) as Array<GiftCertificateItem>,
    )
    const result = {
      'data-count': cartCount > 0 ? cartCount : null,
    }
    log.trace({
      method: 'ClientBagButton:countInputProps',
      result,
    })
    return result
  })

  log.trace({
    method: 'ClientBagButton',
    country,
    locale,
  })

  return (
    <span {...countInputProps.value}>
      <ExternalLink
        href={`/${locale}/cart`}
        onClick={handleClick}
        automationId="header-minicart"
      >
        {children}
      </ExternalLink>
    </span>
  )
}
