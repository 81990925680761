'use client'

import { customer } from '@susu/headless-commerce/contexts/customer'
import { HeaderProvider } from '@susu/headless-commerce/contexts/header/Provider'
import { useHeader } from '@susu/headless-commerce/contexts/header/client'
import type { CMSHeaderType } from '@susu/headless-commerce/contexts/header/types'
import log from '@susu/log'
import classNames from 'classnames'
import type { ReactNode, RefObject } from 'react'
import { memo, useEffect, useRef } from 'react'

import styles from './Header.module.scss'

export type ClientHeaderProps = {
  headerType: CMSHeaderType
  children?: ReactNode
}

const ClientHeader = memo(function ClientHeader({
  children,
  headerType,
}: Readonly<ClientHeaderProps>) {
  const {
    getHeaderInitialClass,
    headerInitialized,
    isHeaderTransparent,
    isHeaderVisible,
    logoColor,
    setHeaderRef,
    setHeaderValues,
  } = useHeader()
  const headerRef: RefObject<HTMLElement> = useRef(null)

  const headerClasses = classNames(
    styles.header,

    [styles[`header__color-${isHeaderTransparent ? logoColor : 'dark'}`]],
    {
      [styles.header__transparent]: isHeaderTransparent,
      [styles.header__hidden]: !isHeaderVisible,
      [styles[getHeaderInitialClass(headerType)]]: !headerInitialized,
    },
    'js-header',
  )

  useEffect(() => {
    if (headerRef.current) {
      setHeaderRef(headerRef)
    }
  }, [setHeaderRef])

  useEffect(() => {
    setHeaderValues(headerType)
  }, [headerType, setHeaderValues])

  return (
    <header className={headerClasses} ref={headerRef} data-testid="header">
      {children}
    </header>
  )
})

export default function ClientHeaderWrapper({
  children,
  headerType,
}: Readonly<ClientHeaderProps>) {
  log.trace({
    method: 'ClientHeaderWrapper',
    headerType,
    customer: customer.value,
  })

  return (
    <HeaderProvider headerType={headerType}>
      <ClientHeader headerType={headerType}>{children}</ClientHeader>
    </HeaderProvider>
  )
}
