export const isUndefined = (value) => value === undefined;
export const isNull = (value) => value === null;
export const isObject = (value) => typeof value === 'object' && !isNull(value);
export const isString = (value) => typeof value === 'string';
export const getPropertyByPath = (obj, path) => {
    const [first, ...rest] = path.split('.');
    const value = obj[first];
    if (isObject(value)) {
        return getPropertyByPath(value, rest.join('.'));
    }
    return value;
};
export const interpolate = (template, parameters) => template.replace(/\{(\w+)\}/g, (_, match) => parameters[match] !== undefined ? String(parameters[match]) : `{${match}}`);
export const translate = (translations, key, parameters) => {
    const value = getPropertyByPath(translations, key);
    return !isString(value)
        ? key
        : !isUndefined(parameters)
            ? interpolate(value, parameters)
            : value;
};
export const createI18n = (translations, language) => {
    const languageTranslations = translations[language];
    if (isUndefined(languageTranslations)) {
        throw new Error(`No translations found for language: ${language}`);
    }
    return (key, parameters) => translate(languageTranslations, key, parameters);
};
