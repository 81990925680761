'use client'

import { useComputed, useSignals } from '@preact/signals-react/runtime'
import ExternalLink from '@susu/headless-commerce/components/ExternalLink/ExternalLink'
import { useCountry } from '@susu/headless-commerce/contexts/country/client'
import { customer } from '@susu/headless-commerce/contexts/customer'
import { useHeader } from '@susu/headless-commerce/contexts/header/client'
import { useLocale } from '@susu/headless-commerce/contexts/locale/client'
import { useGetCountOfWishlistQuery } from '@susu/headless-commerce/gql/generated/getCountOfWishlist.urql'
import type { SiteId } from '@susu/headless-commerce/gql/generated/graphql'
import { usePageType } from '@susu/headless-commerce/hooks/usePageType'
import type { HeaderWishlistClickProperties } from '@susu/headless-commerce/libs/avo/avo'
import { convertLocaleToCommerceGraphQLFormat } from '@susu/headless-commerce/utils/localeUtils'
import { URLData } from '@susu/headless-commerce/utils/tracking/segment'
import { trackEvent } from '@susu/headless-commerce/utils/tracking/tracking'
import log from '@susu/log'
import classNames from 'classnames'
import type { ReactNode } from 'react'
import { useCallback, useMemo } from 'react'

import { isNotUndefined } from '@susu/undefined'
import styles from '../Header.module.scss'

export type WishlistButtonProps = {
  children?: ReactNode
}

export default function WishlistButton({
  children,
}: Readonly<WishlistButtonProps>) {
  useSignals()

  const pageType = usePageType()
  const country = useCountry()
  const locale = useLocale()
  const { headerTrackingValue, segmentEventLabel } = useHeader()

  // This causes the component to rerender when the customer is ready.
  const isReady = useComputed(() => {
    const isCustomerReady =
      isNotUndefined(customer.value) &&
      isNotUndefined(customer.value.customerId) &&
      // Yuck, really?
      customer.value.customerId !== 'undefined' &&
      isNotUndefined(customer.value.isRegistered)

    const result = isNotUndefined(country.siteID) && isCustomerReady

    log.trace({
      method: 'WishlistButton:isReady',
      customer: customer.value,
      customerId: customer.value?.customerId,
      isRegistered: customer.value?.isRegistered,
      result,
    })

    return result
  })

  const variables = useComputed(() => {
    const variables = {
      customerId: String(customer.value?.customerId),
      siteInfo: {
        siteId: country.siteID as SiteId,
        locale: convertLocaleToCommerceGraphQLFormat(locale),
        currency: country.ecommerce.currencyCode,
      },
    }

    log.trace({
      method: 'WishlistButton:variables',
      variables,
    })

    return variables
  })

  const context = useComputed(() => {
    const context = {
      clientName: 'commerce',
    }

    log.trace({
      method: 'WishlistButton:context',
      context,
    })

    return context
  })

  const [{ data, error }] = useGetCountOfWishlistQuery({
    variables: variables.value,
    context: context.value,
    pause: isReady.value === false,
  })

  const handleClick = useCallback(async () => {
    const { url, locationId } = URLData()

    await trackEvent({
      ga: {
        eventCategory: 'Global_Interactions',
        eventAction: 'Wishlist_Header_Click',
        eventLabel: headerTrackingValue,
      },
      segment: {
        event: 'headerWishlistClick',
        enrichProps: {
          locale,
          country,
        },
        properties: {
          pageType: pageType as HeaderWishlistClickProperties['pageType'],
          eventLabel: segmentEventLabel,
          eventCategory: 'global_interactions',
          eventLocation: 'header',
          url,
          locationId,
        },
      },
    })
  }, [country, headerTrackingValue, locale, pageType, segmentEventLabel])

  const wishlistCount = data?.getCountOfWishlist ?? 0

  const classes = classNames(styles.header__btn, {
    [styles.header__btn__wishlist]: wishlistCount > 0,
  })

  if (error) {
    log.error(error)
  }

  log.trace({
    method: 'WishlistButton:render',
    isReady,
    error,
    data,
  })

  return (
    <span className={classes}>
      <ExternalLink
        href={`/${locale}/wishlist`}
        onClick={handleClick}
        automationId="header-wishlist"
      >
        {children}
      </ExternalLink>
    </span>
  )
}
