'use client'

import { useI18N } from '@susu/i18n/client'
import styles from './Search.module.scss'

export type ClientFailedProps = {
  inputValue: string
}

export const ClientFailed = ({ inputValue }: ClientFailedProps) => {
  const { t } = useI18N()
  const suggestionBlockClass = styles['search__sugestion-block']

  return (
    <div className={suggestionBlockClass}>
      <div className={`title-03-medium ${styles['search__no-results__title']}`}>
        {t('navigation.no_results')} {`'${inputValue}'`}
      </div>
      <div className="body-light">{t('navigation.no_results_description')}</div>
    </div>
  )
}
