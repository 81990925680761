import {
  isAcceptance,
  isLive,
  isTesting,
} from '@susu/headless-commerce/utils/environment'
import log from '@susu/log'

if (isLive()) {
  log.level = 'error'
} else if (isAcceptance()) {
  log.level = 'debug'
} else if (isTesting()) {
  log.level = 'trace'
} else {
  log.level = 'silly'
}
