'use client';
import { useComputed, useSignals } from '@preact/signals-react/runtime';
import { createContext, useContext } from 'react';
import { createI18n, } from '.';
export const I18NContext = createContext({
    language: 'en',
    messages: {},
    t: () => {
        return '';
    },
});
export const useI18N = () => useContext(I18NContext);
export const ClientI18NProvider = ({ children, language, messages, }) => {
    useSignals();
    const value = useComputed(() => {
        const t = createI18n(messages, language);
        return {
            language,
            messages,
            t,
        };
    });
    return (<I18NContext.Provider value={value.value}>{children}</I18NContext.Provider>);
};
