'use client'

import { useSignalEffect } from '@preact/signals-react'
import { useSignal, useSignals } from '@preact/signals-react/runtime'
import { ClientSideSliderProvider } from '@susu/headless-commerce/components/SideSlider/contexts/ClientSideSliderContext'
import type { Locale } from '@susu/headless-commerce/config/locale'
import type { CurrencyConfiguration } from '@susu/headless-commerce/types/CurrencyConfiguration'
import type { Countries } from '@susu/headless-commerce/types/EdgeConfiguration'
import ClientUrqlProvider from '@susu/headless-commerce/utils/ClientUrqlProvider'
import {
  getCountryCode,
  getLanguageFromLocale,
} from '@susu/headless-commerce/utils/localeUtils'
import type { TranslationsByLanguage } from '@susu/i18n'
import { ClientI18NProvider } from '@susu/i18n/client'
import log from '@susu/log'
import type { ReactNode } from 'react'
import { setCart, setCartSSMProducts, setCartSegmentproducts } from './cart'
import { ClientCountriesProvider } from './countries/client'
import { ClientCountryProvider } from './country/client'
import { ClientCurrenciesProvider } from './currencies/client'
import { setCustomer } from './customer'
import { ClientLocaleProvider } from './locale/client'

// Configure the logger
import '@susu/headless-commerce/utils/log'

export type ClientProvidersProps = {
  children: ReactNode
  countries: Countries
  currencies: CurrencyConfiguration
  locale: Locale
  messages: TranslationsByLanguage
}

export const ClientProviders = ({
  children,
  locale,
  currencies,
  countries,
  messages,
}: ClientProvidersProps) => {
  useSignals()

  const localeLanguage = getLanguageFromLocale(locale)
  const countryCode = getCountryCode(locale)
  const country = countries[countryCode]

  const initialized = useSignal(false)
  useSignalEffect(() => {
    if (initialized.value) {
      return
    }
    initialized.value = true
    ;(async () => {
      try {
        log.trace({
          method: 'ClientProviders:effect',
          countryCode,
          country,
        })
        await setCustomer(country)
        await setCart(locale, country)
        await setCartSSMProducts(country)
        await setCartSegmentproducts(country)
      } catch (error) {
        log.error(error)
      }
    })()
  })

  return (
    <ClientLocaleProvider locale={locale}>
      <ClientCurrenciesProvider currencies={currencies}>
        <ClientCountriesProvider countries={countries}>
          <ClientCountryProvider country={country}>
            <ClientI18NProvider language={localeLanguage} messages={messages}>
              <ClientUrqlProvider>
                <ClientSideSliderProvider>{children}</ClientSideSliderProvider>
              </ClientUrqlProvider>
            </ClientI18NProvider>
          </ClientCountryProvider>
        </ClientCountriesProvider>
      </ClientCurrenciesProvider>
    </ClientLocaleProvider>
  )
}
