import { isServer } from '@susu/headless-commerce/utils/environment'
import {
  concatenatePaths,
  pathnameWithoutLocalePrefix,
} from '@susu/headless-commerce/utils/pathname'

/**
 *
 * @returns Pops 4th parameter from href as it is always locale and returns modified url
 */
export const getURLTrackingValue = (url: URL, stepName: string) => {
  if (isServer()) {
    return ''
  }
  url.pathname = concatenatePaths(
    pathnameWithoutLocalePrefix(url.pathname),
    stepName,
  )
  return url.toString()
}

/**
 *
 * @param stepName Step name
 * @returns adds stepname
 */
export const getLocationIdValue = (url: URL, stepName: string) => {
  url.pathname = concatenatePaths(url.pathname, stepName)
  return url.toString()
}
