import type { Locale } from '@susu/headless-commerce/config/locale'
import type { PromotionEvents } from '@susu/headless-commerce/gql/generated/graphql'
import type { CountryConfiguration } from '@susu/headless-commerce/types/CountryConfiguration'
import { generateUrlFromLinkContent } from '@susu/headless-commerce/utils/UrlGenerator'
import classNames from 'classnames'
import Image from 'next/image'
import { useCallback, useMemo } from 'react'

import ClientLink from '../../../Link/ClientLink'
import type { SubmenuText } from '../NavigationMenu/utils/menuStructure'
import type { NavigationMenuItemClickArgs } from '../NavigationMenuItem/ClientNavigationMenuItem'
import styles from './NavigationSubMenuText.module.scss'

export type NavigationSubMenuTextProps = {
  country: CountryConfiguration
  locale: Locale
  subMenu: SubmenuText
  visible: boolean
  onClick: (config: NavigationMenuItemClickArgs) => void
}

export default function NavigationSubMenuText({
  country,
  subMenu,
  visible,
  locale,
  onClick,
}: NavigationSubMenuTextProps) {
  const { icon, text, link, withArrow, parentItem } = subMenu
  const { automationId, promotionEvents } = link
  const href = generateUrlFromLinkContent(link, country.siteID, locale)
  const style = useMemo(
    () => ({ display: `${visible ? 'flex' : 'none'}` }),
    [visible],
  )
  const clickHandler = useCallback(() => {
    onClick({
      id: 'none',
      promotionEvents: promotionEvents as PromotionEvents,
      hasChildren: false,
      parentItem: parentItem,
    })
  }, [promotionEvents, onClick, parentItem])

  return (
    <li
      className={classNames(styles['navigation-item-submenu'], {
        [styles['navigation-item-submenu__no-href']]: !href,
      })}
      style={style}
    >
      {icon?.length && (
        <Image src={icon[0].secure_url} width="16" height="16" alt={text} />
      )}
      <ClientLink
        onClick={clickHandler}
        className={classNames(styles['navigation-item-submenu__link'])}
        trailingIcon={withArrow ? 'arrow_cta' : undefined}
        label={text}
        size="lg"
        weight="light"
        href={href}
        hoverEffect={Boolean(href)}
        automationId={String(automationId)}
      />
    </li>
  )
}
