import { isUndefined } from '@susu/undefined';
const jobIsNotResolved = (a) => a.resolved === false;
const getJobPromise = (a) => a.promise;
const getJobResult = (a) => a.result;
const getResults = (jobs) => {
    return jobs.map(getJobResult);
};
const waitForNextJob = async (jobs) => {
    const waitingJobs = jobs.filter(jobIsNotResolved);
    const jobPromises = waitingJobs.map(getJobPromise);
    const [finishedIndex, finishedResult] = await Promise.race(jobPromises);
    jobs[finishedIndex].resolved = true;
    jobs[finishedIndex].result = finishedResult;
};
const createJobRunner = async (index, deferreds) => {
    const result = (await deferreds[index]());
    return [index, result];
};
// export async function test<A extends unknown[]>(
//   deferreds: {
//     [K in keyof A]: () => Promise<A[K]>
//   },
// ): Promise<A> {
//   const promises = deferreds.map((deferred) => deferred())
//   return Promise.all(promises) as Promise<A>
// }
// const abc = await test([
//   async () => 'a',
//   async () => 123,
//   async () => new Date(),
//   async () => /asdf/,
// ])
/**
 * Runs an array of deferreds in parallel, with an optional concurrency limit.
 *
 * @template A - The type of the resolved promise values.
 * @param deferreds - The array of deferreds to execute.
 * @param limit - The maximum number of concurrent promises.
 * @returns A wrapper promise that resolves with an array of results.
 */
export const parallel = async (deferreds, limit = deferreds.length) => {
    if (isUndefined(limit) ||
        limit >= deferreds.length ||
        deferreds.length === 0) {
        const mapped = deferreds.map((fn) => fn());
        const result = Promise.all(mapped);
        return result;
    }
    const jobs = [];
    const executeConcurrent = async (index) => {
        if (index >= deferreds.length) {
            await waitForNextJob(jobs);
            return;
        }
        jobs.push({
            index,
            promise: createJobRunner(index, deferreds),
            resolved: false,
            result: undefined,
        });
        const waiting = jobs.filter(jobIsNotResolved);
        if (waiting.length >= limit) {
            await waitForNextJob(jobs);
        }
        await executeConcurrent(index + 1);
    };
    await executeConcurrent(0);
    const results = getResults(jobs);
    return results;
};
