import type { RefObject } from 'react'

/**
 * Returns the vertical position of the given element relative to the document.
 *
 * @param elementRef - A reference object pointing to an HTML element
 * @returns The vertical position (in pixels) of the element from the top of the document.
 */
export const getElementRefPosition = (elementRef: RefObject<Element>): number =>
  Math.ceil(
    Math.max(1, elementRef.current?.getBoundingClientRect().y ?? 1) +
      window.scrollY,
  )
