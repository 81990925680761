/**
 * Determines if the given value is `undefined`.
 *
 * @template A - The type of the value being checked.
 * @param a - The value to check.
 * @returns `true` if the value is `undefined`, otherwise `false`.
 */
export const isUndefined = (a) => a === undefined;
/**
 * Determines if the given value is not `undefined`.
 *
 * @template A - The type of the value being checked.
 * @param a - The value to check.
 * @returns `true` if the value is not `undefined`, otherwise `false`.
 */
export const isNotUndefined = (a) => a !== undefined;
