'use client'

import { useSignals } from '@preact/signals-react/runtime'
import ExternalLink from '@susu/headless-commerce/components/ExternalLink/ExternalLink'
import { useCountry } from '@susu/headless-commerce/contexts/country/client'
import { useHeader } from '@susu/headless-commerce/contexts/header/client'
import { useLocale } from '@susu/headless-commerce/contexts/locale/client'
import { usePageType } from '@susu/headless-commerce/hooks/usePageType'
import type { HeaderAccountClickProperties } from '@susu/headless-commerce/libs/avo/avo'
import { URLData } from '@susu/headless-commerce/utils/tracking/segment'
import { trackEvent } from '@susu/headless-commerce/utils/tracking/tracking'
import type { ReactNode } from 'react'
import { useCallback } from 'react'

export type ClientAccountButtonProps = {
  children?: ReactNode
}

export default function ClientAccountButton({
  children,
}: ClientAccountButtonProps) {
  useSignals()
  const pageType = usePageType()
  const country = useCountry()
  const locale = useLocale()
  const { headerTrackingValue, segmentEventLabel } = useHeader()
  const { url, locationId } = URLData()

  const handleClick = useCallback(async () => {
    await trackEvent({
      ga: {
        eventCategory: 'Global_Interactions',
        eventAction: 'Account_Header_Click',
        eventLabel: headerTrackingValue,
      },
      segment: {
        event: 'headerAccountClick',
        enrichProps: {
          locale,
          country,
        },
        properties: {
          pageType: pageType as HeaderAccountClickProperties['pageType'],
          eventLabel: segmentEventLabel,
          eventCategory: 'global_interactions',
          eventLocation: 'header',
          url,
          locationId,
        },
      },
    })
  }, [
    country,
    headerTrackingValue,
    locale,
    locationId,
    pageType,
    segmentEventLabel,
    url,
  ])

  return (
    <ExternalLink
      href={`/${locale}/account`}
      onClick={handleClick}
      automationId="header-account"
    >
      {children}
    </ExternalLink>
  )
}
