import { signal } from '@preact/signals-react'
import type { EventProperties } from '@segment/analytics-next'
import { createPromiseFromSignal } from '@susu/headless-commerce/utils/signal'
import log from '@susu/log'

import { analytics } from './segment'

export const loaded = signal<boolean | undefined>(undefined)
export const inspectorPromise = createPromiseFromSignal(loaded)

inspectorPromise.then((value) => {
  log.debug({
    method: 'libs/segment/avoDestination:customerPromise:then',
    value,
  })
})

export const segmentDestination = {
  make: () => {
    loaded.value = true
    document.dispatchEvent(new Event('avo_inspector_initialized'))
  },
  logEvent: (eventName: string, eventProperties: EventProperties) => {
    analytics.track(eventName, eventProperties)
  },
  setUserProperties: (userId: string, userProperties: object) => {
    analytics.identify(userId, userProperties)
  },
  identify: (userId: string) => {
    analytics.identify(userId)
  },
  unidentify: () => {
    analytics.identify(null)
  },
  logPage: (pageName: string, eventProperties: EventProperties) => {
    const properties = Object.assign(eventProperties, { pageName: pageName })

    analytics.page(properties)
  },
  revenue: (amount: number, eventProperties: EventProperties) => {
    const name = 'Purchase Complete'
    const properties = Object.assign(eventProperties, { revenue: amount })

    analytics.track(name, properties)
  },
  // The following methods are used for group analytics and are not required. Learn more about group analytics [here](/data-design/groups)
  setGroupProperties(
    groupType: string,
    groupId: string,
    groupProperties: object,
  ) {
    analytics.group(groupId, groupProperties)
  },
  addCurrentUserToGroup: (groupType: string, groupId: string) => {
    analytics.group(groupId)
  },
  logEventWithGroups: (
    eventName: string,
    eventProperties: EventProperties,
    groupTypesToGroupIds: object,
  ) => {
    eventName
    eventProperties
    groupTypesToGroupIds
  },
}
