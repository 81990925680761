import { isValidLocale } from './localeUtils'

export const localeRegex = /^(.+)-(.+)$/

export const pathnameWithoutSlash = (pathname: string): string =>
  pathname.replace(/\/$/, '')

export const pathnameLocalePrefix = (pathname: string): string =>
  pathname.substring(1, 6)

export const pathnameWithoutLocalePrefix = (pathname: string): string =>
  pathname.substring(6)

export const pathnameStartsWithLocale = (pathname: string): boolean =>
  pathname.startsWith('/') && localeRegex.test(pathnameLocalePrefix(pathname))

export const pathnameStartsWithSupportedLocale = (pathname: string): boolean =>
  pathnameStartsWithLocale(pathname) &&
  isValidLocale(pathnameLocalePrefix(pathname))

export const pathnameEndsWithSlash = (pathname: string): boolean =>
  pathname.endsWith('/')

export const pathnameStartsWithSlash = (pathname: string): boolean =>
  pathname.startsWith('/')

export const concatenatePaths = (...paths: string[]): string =>
  paths.reduce((acc, path) =>
    pathnameEndsWithSlash(acc)
      ? pathnameStartsWithSlash(path)
        ? `${acc}${path.slice(1)}`
        : `${acc}${path}`
      : pathnameStartsWithSlash(path)
        ? `${acc}${path}`
        : `${acc}/${path}`,
  )

export const searchParamsString = (searchParams: URLSearchParams): string =>
  searchParams.size ? `?${searchParams.toString()}` : ''
