'use client'

import { signal } from '@preact/signals-react'
import { baseURL } from '@susu/headless-commerce/config/config'
import type { CountryConfiguration } from '@susu/headless-commerce/types/CountryConfiguration'
import type { Customer } from '@susu/headless-commerce/types/Customer'
import { createPromiseFromSignal } from '@susu/headless-commerce/utils/signal'
import log from '@susu/log'

export const customer = signal<Customer | undefined>(undefined)

export const customerPromise = createPromiseFromSignal(customer)

customerPromise.then((value) => {
  log.debug({
    method: 'contexts/customer:customerPromise:then',
    value,
  })
})

export const setCustomer = async (country: CountryConfiguration) => {
  const url = `${baseURL()}/api/customer/sfccCustomer`

  log.debug({
    method: 'contexts/customer:setCustomer',
    url,
    country,
  })

  const request = await fetch(url, {
    headers: {
      'x-site-id': country.siteID,
      'X-Headless': 'true',
      'X-Url': window.document.location.toString(),
    },
  })

  const result = (await request.json()) as Customer

  log.debug({
    method: 'contexts/customer:setCustomer:result',
    result,
  })

  customer.value = result
}
