export const IDENTITY_KEY_KNOWN = 'known_identity'
export const IDENTITY_KEY_ANONYMOUS = 'anonymous_identity'
export const IDENTITY_VALUE = 'set'
export const GA_COOKIE_NAME = '_ga'
export const GA_COOKIE_ID = process.env.NEXT_PUBLIC_TRACKING_GA_COOKIE
export const FBP_COOKIE_NAME = '_fbp'
export const OPTANON_COOKIE_NAME = 'OptanonConsent'
export const OPTANON_DEFAULT_CONSENT = 'C0001:1,C0003:0,C0004:0,C0002:0'

export const EVENT_SEGMENT_LOADED = 'Segment Loaded'
export const EVENT_PAGE_LOADED = 'Page Loaded'
