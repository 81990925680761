import type { AnchorHTMLAttributes, ReactNode } from 'react'
import { forwardRef } from 'react'

export type ExternalLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  children?: ReactNode
  automationId: string
  opensInNewTab?: boolean
  ref?: React.Ref<HTMLAnchorElement>
}

const ExternalLink = forwardRef<HTMLAnchorElement, ExternalLinkProps>(
  ({ children, automationId, opensInNewTab, ...props }, ref) => {
    return (
      <a
        {...props}
        data-testid={`link_${automationId}`}
        ref={ref}
        target={opensInNewTab ? '_blank' : undefined}
        rel={opensInNewTab ? 'noopener noreferrer' : undefined}
      >
        {children}
      </a>
    )
  },
)

ExternalLink.displayName = 'ExternalLink'

export default ExternalLink
