import type { Dispatch, RefObject, SetStateAction } from 'react'
import { createContext, useContext } from 'react'

import { CMSHeaderType } from './types'

export type SegmentEvent = 'transparent' | 'white'
export type LogoColor = 'dark' | 'light'
export type HeaderContextType = {
  getHeaderInitialClass: (headerType: CMSHeaderType) => string
  headerInitialized: boolean
  headerTrackingValue: string
  headerType: CMSHeaderType
  isHeaderTransparent: boolean
  isHeaderVisible: boolean
  logoColor: LogoColor
  menuOpen: boolean
  segmentEventLabel: SegmentEvent
  setHeaderRef: (ref: RefObject<HTMLElement>) => void
  setHeaderValues: (type: CMSHeaderType) => void
  setMenuOpen: Dispatch<SetStateAction<boolean>>
  variation: 'dark' | 'light'
}

export const HeaderContext = createContext<HeaderContextType>({
  getHeaderInitialClass: () => '',
  headerInitialized: false,
  headerTrackingValue: 'transparent',
  headerType: CMSHeaderType.HEADER_IS_NOT_TRANSPARENT,
  isHeaderTransparent: false,
  isHeaderVisible: true,
  logoColor: 'light',
  menuOpen: false,
  segmentEventLabel: 'transparent',
  setHeaderRef: () => {},
  setHeaderValues: () => {},
  setMenuOpen: () => false,
  variation: 'dark',
})

export const useHeader = () => {
  return useContext(HeaderContext)
}
