'use client'

import ExternalLink from '@susu/headless-commerce/components/ExternalLink/ExternalLink'
import type { PopularSearchQuery } from '@susu/headless-commerce/gql/generated/getPopularSearchPhrases.operation'
import { highlightText } from '@susu/headless-commerce/utils/highlightText'
import { useI18N } from '@susu/i18n/client'
import styles from './Search.module.scss'

export type PopularSearchPhrasesProps = {
  inputValue: string
  popularSearchPhrases: PopularSearchQuery
}

export const PopularSearchPhrases = ({
  inputValue,
  popularSearchPhrases,
}: PopularSearchPhrasesProps) => {
  const { t } = useI18N()
  const suggestionBlockClass = styles['search__sugestion-block']

  return (
    <div className={suggestionBlockClass}>
      <div
        className={`brow-header-regular ${styles.search__subtitle}`}
        data-testid="heading_search_popular-searches"
      >
        {t('navigation.popular_searches')}
      </div>
      <div>
        {popularSearchPhrases.getPopularSearchPhrases?.map(
          (suggestion: string, i: number) => {
            return (
              <ExternalLink
                className={styles.search__link}
                key={`${suggestion}-${i + 1}`}
                href={`search?q=${suggestion}`}
                automationId={`search-popular_${i + 1}`}
              >
                <div className={styles.search__result}>
                  {highlightText(suggestion, inputValue)}
                </div>
              </ExternalLink>
            )
          },
        )}
      </div>
    </div>
  )
}
