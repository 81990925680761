'use client'

import ExternalLink from '@susu/headless-commerce/components/ExternalLink/ExternalLink'
import { useCountry } from '@susu/headless-commerce/contexts/country/client'
import type { SearchSuggestionsQuery } from '@susu/headless-commerce/gql/generated/searchSuggestions.operation'
import { highlightText } from '@susu/headless-commerce/utils/highlightText'
import { useI18N } from '@susu/i18n/client'
import styles from './Search.module.scss'

export type SucceededProps = {
  inputValue: string
  searchResult: SearchSuggestionsQuery
}

export const ClientSucceeded = ({
  inputValue,
  searchResult,
}: SucceededProps) => {
  const country = useCountry()
  const { t } = useI18N()
  const suggestionBlockClass = styles['search__sugestion-block']

  return (
    <>
      {Boolean(searchResult?.searchSuggestions?.suggestedPhrases?.length) && (
        <div className={suggestionBlockClass}>
          <div
            className={`brow-header-regular ${styles.search__subtitle}`}
            data-testid="heading_search_suggested-phrases"
          >
            {t('navigation.do_you_mean')}
          </div>
          <div>
            {searchResult?.searchSuggestions?.suggestedPhrases?.map(
              (phrase: string, i: number) => {
                return (
                  <ExternalLink
                    className={styles.search__link}
                    key={`${phrase}-${i + 1}`}
                    href={`search?q=${phrase}`}
                    automationId={`search-suggestion_${i + 1}`}
                  >
                    <div className={`${styles.search__result}`}>
                      {highlightText(phrase, inputValue)}
                    </div>
                  </ExternalLink>
                )
              },
            )}
          </div>
        </div>
      )}
      {Boolean(searchResult?.searchSuggestions?.productSuggestions?.length) && (
        <div className={suggestionBlockClass}>
          <div
            className={`brow-header-regular ${styles.search__subtitle}`}
            data-testid="heading_search_suggested-products"
          >
            {t('navigation.products')}
          </div>
          <div>
            {searchResult?.searchSuggestions?.productSuggestions?.map(
              (product) => {
                return (
                  <ExternalLink
                    className={styles.search__link}
                    key={product.id}
                    href={product.url ?? ''}
                    automationId={`search-product_${product.id}`}
                  >
                    <div className={`${styles.search__result}`}>
                      {highlightText(product.name, inputValue)}
                    </div>
                  </ExternalLink>
                )
              },
            )}
          </div>
        </div>
      )}
      {Boolean(
        searchResult?.searchSuggestions?.categorySuggestions?.length,
      ) && (
        <div className={suggestionBlockClass}>
          <div
            className={`brow-header-regular ${styles.search__subtitle}`}
            data-testid="heading_search_suggested-categories"
          >
            {t('navigation.categories')}
          </div>
          <div>
            {searchResult?.searchSuggestions?.categorySuggestions?.map(
              (category) => {
                return (
                  <ExternalLink
                    className={styles.search__link}
                    key={category.id}
                    href={category.url ?? ''}
                    automationId={`search-category_${category.id}`}
                  >
                    <div className={`${styles.search__result}`}>
                      {highlightText(
                        t('navigation.category_result', {
                          category: category.name,
                          parentCategory: String(category.parentCategoryName),
                        }),
                        inputValue,
                      )}
                    </div>
                  </ExternalLink>
                )
              },
            )}
          </div>
        </div>
      )}
    </>
  )
}
