'use client'

import { signal } from '@preact/signals-react'
import type { Locale } from '@susu/headless-commerce/config/locale'
import { GetCartByCustomerDocument } from '@susu/headless-commerce/gql/generated/getCartByCustomer.documentNode'
import type {
  GetCartByCustomerQuery,
  GetCartByCustomerQueryVariables,
} from '@susu/headless-commerce/gql/generated/getCartByCustomer.operation'
import type {
  Cart,
  SiteId,
} from '@susu/headless-commerce/gql/generated/graphql'
import type { ProductsCart } from '@susu/headless-commerce/libs/avo/avo'
import type { CountryConfiguration } from '@susu/headless-commerce/types/CountryConfiguration'
import { urqlClient } from '@susu/headless-commerce/utils/ClientUrqlProvider'
import { convertLocaleToCommerceGraphQLFormat } from '@susu/headless-commerce/utils/localeUtils'
import type { SSMProduct } from '@susu/headless-commerce/utils/tracking/GA'
import { toSSMProduct } from '@susu/headless-commerce/utils/tracking/GA'
import { ssmToProductCart } from '@susu/headless-commerce/utils/tracking/segment'
import { isUndefined } from '@susu/undefined'

import { customer } from '../customer'

export const cart = signal<Cart | undefined>(undefined)

export const setCart = async (
  locale: Locale,
  country: CountryConfiguration,
) => {
  if (isUndefined(urqlClient.value)) {
    throw new Error('urqlClient is not defined')
  }
  if (isUndefined(customer.value)) {
    throw new Error('customer is not defined')
  }

  const query = await urqlClient.value.query<
    GetCartByCustomerQuery,
    GetCartByCustomerQueryVariables
  >(
    GetCartByCustomerDocument,
    {
      customerId: String(customer.value.customerId),
      siteInfo: {
        currency: country.ecommerce.currencyCode,
        locale: convertLocaleToCommerceGraphQLFormat(locale),
        siteId: country.siteID as SiteId,
      },
    },
    {
      clientName: 'commerce',
    },
  )

  cart.value = query.data?.getCartByCustomer as Cart
}

export const cartSSMProducts = signal<Array<SSMProduct> | undefined>(undefined)

export const setCartSSMProducts = async (country: CountryConfiguration) => {
  if (isUndefined(cart.value)) {
    throw new Error('cart is undefined')
  }

  const items = [
    ...(cart.value?.lineItems ?? []),
    ...(cart.value?.giftCertificateItems ?? []),
  ]

  cartSSMProducts.value = items.map((item) =>
    toSSMProduct(
      item as Record<string, unknown>,
      country.ecommerce.currencyCode,
      country.siteID,
    ),
  )
}

export const cartSegmentProducts = signal<Array<ProductsCart> | undefined>(
  undefined,
)

export const setCartSegmentproducts = async (country: CountryConfiguration) => {
  if (isUndefined(cart.value)) {
    throw new Error('cart is undefined')
  }

  cartSegmentProducts.value = (cart.value?.lineItems ?? [])
    .filter((item) => {
      return (
        (item?.masterProductId === null && item.size === 'onesize') ||
        !(
          (item?.masterProductId as string).includes('CM-') ||
          (item?.masterProductId as string).includes('DYO')
        )
      )
    })
    .map((item) =>
      toSSMProduct(
        item as Record<string, unknown>,
        country.ecommerce.currencyCode,
        country.siteID,
      ),
    )
    .map((item) => ssmToProductCart(item, country))
}
