'use client'

import Icon from '@susu/headless-commerce/components/Icon/Icon'
import { useCountry } from '@susu/headless-commerce/contexts/country/client'
import { useI18N } from '@susu/i18n/client'
import classNames from 'classnames'
import type { ChangeEventHandler, MouseEventHandler } from 'react'
import { useCallback } from 'react'
import styles from './Search.module.scss'

export type ClientInputProps = {
  inputValue: string
  isEmpty: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
  onClose: MouseEventHandler<HTMLButtonElement>
  onSubmit: () => void
}

export const ClientInput = ({
  inputValue,
  isEmpty,
  onChange,
  onClose,
  onSubmit,
}: ClientInputProps) => {
  const country = useCountry()
  const { t } = useI18N()
  const searchInputClasses = classNames(
    styles.search__input,
    'js-header-search-input',
  )

  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key !== 'Enter') {
        return
      }

      event.preventDefault()
      event.stopPropagation()
      onSubmit()
    },
    [onSubmit],
  )

  return (
    <div className={classNames(styles['search-wrapper'])}>
      <div className={searchInputClasses} data-testid="input_menu_search">
        <input
          className="title-03-light"
          value={inputValue}
          type="search"
          placeholder={t('navigation.search_placeholder')}
          onChange={onChange}
          onKeyUp={handleKeyUp}
          onSubmit={onSubmit}
        />
        {!isEmpty && (
          <button
            type="button"
            className={styles.search__clear}
            onClick={onClose}
            data-testid="button_search_clear"
          >
            <span className="sr-only">Close</span>
            <Icon icon="close" aria-label="Clear search" />
          </button>
        )}
      </div>
    </div>
  )
}
