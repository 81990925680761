'use client'

import type { Dispatch, ReactNode, RefObject, SetStateAction } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'

import type { LogoColor, SegmentEvent } from './client'
import { HeaderContext } from './client'
import { CMSHeaderType } from './types'

type HeaderProviderProps = {
  headerType: CMSHeaderType
  children?: ReactNode
}

const _distance = 60
let _lastPosition = 0
let _headerHeight: number
let _headerType: CMSHeaderType
let headerTrackingValue = 'transparent'
let isTopOfPage = true

const positionHeader = ({
  setIsHeaderVisible,
  isHeaderTransparent,
  setIsHeaderTransparent,
}: {
  setIsHeaderVisible: Dispatch<SetStateAction<boolean>>
  setIsHeaderTransparent: Dispatch<SetStateAction<boolean>>
  isHeaderTransparent: boolean
}) => {
  const canScroll = window?.innerHeight < document?.documentElement.scrollHeight
  const pageYOffset = window?.pageYOffset
  let isVisible: boolean
  let isTransparent = isHeaderTransparent
  headerTrackingValue = _headerType

  if (canScroll) {
    // Is on scroll top
    isTopOfPage = pageYOffset <= _headerHeight + _distance
    if (isTopOfPage) {
      isVisible = true
      isTransparent = true
      headerTrackingValue = 'transparent'
    } else {
      isTransparent = false
      headerTrackingValue = 'white'
    }

    // Make room for measuring
    if (Math.abs(_lastPosition - pageYOffset) <= _distance) {
      return
    }

    // If scrolling up
    isVisible = pageYOffset < _lastPosition
  } else {
    isVisible = true
  }

  _lastPosition = pageYOffset

  // Checking if pageYOffset is negative, since there is issue with iPhone's overscroll when scrolling further top than top of the page.
  setIsHeaderVisible(pageYOffset < 0 ? true : isVisible)
  setIsHeaderTransparent(isTransparent)
}

export const HeaderProvider = ({
  headerType,
  children,
}: HeaderProviderProps) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [isHeaderVisible, setIsHeaderVisible] = useState<boolean>(true)
  const [isHeaderTransparent, setIsHeaderTransparent] = useState<boolean>(true)
  const [logoColor, setLogoColor] = useState<LogoColor>('dark')
  const [headerRef, setHeaderRef] = useState<RefObject<HTMLElement> | null>(
    null,
  )
  const [headerInitialized, setHeaderInitialized] = useState<boolean>(false)
  const [variation, setVariation] = useState<LogoColor>(
    headerType === CMSHeaderType.HEADER_IS_NOT_TRANSPARENT ||
      headerType === CMSHeaderType.HEADER_IS_TRANSPARENT_BLACK
      ? 'dark'
      : 'light',
  )

  const setPosition = useCallback(() => {
    positionHeader({
      setIsHeaderVisible,
      setIsHeaderTransparent,
      isHeaderTransparent,
    })
  }, [isHeaderTransparent])

  const segmentEventLabel = useMemo<SegmentEvent>(
    () => (isHeaderTransparent ? 'transparent' : 'white'),
    [isHeaderTransparent],
  )

  // Function to get the initial class for the header.
  const getHeaderInitialClass = useCallback((headerType: CMSHeaderType) => {
    switch (headerType) {
      case CMSHeaderType.HEADER_IS_TRANSPARENT:
        return 'header-initial--transparent'
      case CMSHeaderType.HEADER_IS_NOT_TRANSPARENT:
        return 'header-initial--non-transparent'
      case CMSHeaderType.HEADER_IS_TRANSPARENT_BLACK:
        return 'header-initial--transparent-black'
      default:
        return 'header-initial--transparent'
    }
  }, [])

  // Effect setting initial logo color based on header type.
  const handleScroll = useCallback(() => {
    if (isHeaderTransparent) {
      setVariation(logoColor)
    } else {
      setVariation('dark')
    }
  }, [isHeaderTransparent, logoColor])

  // Effect for initial header loading state.
  useEffect(() => {
    document.addEventListener('scroll', () => {
      setHeaderInitialized(true)
    })

    return () => {
      document.removeEventListener('scroll', () => {
        setHeaderInitialized(true)
      })
    }
  }, [])

  // Effect for handling logo color
  useEffect(() => {
    document.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  // Effect for showing/hiding header
  useEffect(() => {
    if (!headerRef?.current) {
      return
    }

    _headerHeight = headerRef.current.getBoundingClientRect().height
    setPosition()
    window?.addEventListener('scroll', setPosition)

    return () => window?.removeEventListener('scroll', setPosition)
  }, [headerRef, setPosition])

  const setHeaderValues = useCallback((headerType: CMSHeaderType) => {
    _headerType = headerType
    initializeHeader(setLogoColor, setIsHeaderTransparent)
  }, [])

  return (
    <HeaderContext.Provider
      value={useMemo(
        () => ({
          getHeaderInitialClass,
          headerInitialized,
          headerTrackingValue,
          headerType: _headerType,
          isHeaderTransparent,
          isHeaderVisible,
          logoColor,
          menuOpen,
          segmentEventLabel,
          setHeaderRef,
          setHeaderValues,
          setMenuOpen,
          variation,
        }),
        [
          getHeaderInitialClass,
          headerInitialized,
          isHeaderTransparent,
          isHeaderVisible,
          logoColor,
          menuOpen,
          segmentEventLabel,
          setHeaderValues,
          variation,
        ],
      )}
    >
      {children}
    </HeaderContext.Provider>
  )
}

/**
 * Function used only for initializing header on page load.
 * @param setLogoColor - setting logo color
 */
function initializeHeader(
  setLogoColor: Dispatch<SetStateAction<LogoColor>>,
  setIsHeaderTransparent: (value: boolean) => void,
) {
  if (_headerType === CMSHeaderType.HEADER_IS_NOT_TRANSPARENT || !isTopOfPage) {
    setLogoColor('dark')
    setIsHeaderTransparent(false)
    headerTrackingValue = 'white'
  } else if (_headerType === CMSHeaderType.HEADER_IS_TRANSPARENT) {
    setLogoColor('light')
    setIsHeaderTransparent(true)
    headerTrackingValue = 'transparent'
  } else if (_headerType === CMSHeaderType.HEADER_IS_TRANSPARENT_BLACK) {
    setIsHeaderTransparent(true)
    setLogoColor('dark')
  }
}
